import axios from 'axios';
import type { IStacApiSearch } from 'datacosmos/types/stac-types';
import { StacItem } from 'datacosmos/types/stac-types';
import { set } from 'lodash';
import { IMAGE_CATALOG_API } from 'env';
import { search } from '_api/stac/service';
import type { SearchResponse, STACItem } from '_api/stac/types';
import type { Polygon } from 'geojson';
import moment from 'moment';

const SERVICE_URL = IMAGE_CATALOG_API;

export interface IFilter {
  satellites: string[];
  dateFrom?: Date;
  dateTo?: Date;
  minHours?: Date;
  maxHours?: Date;
  minSZA?: number;
  maxSZA?: number;
  minOZA?: number;
  maxOZA?: number;
  minGSD?: number;
  maxGSD?: number;
  minSunGlint?: number;
  maxSunGlint?: number;
  maxCloudCoverage?: number;
  processingLevel: string[];
  sourceFilter: string[];
  seasonsFilter: string[];
  assetType: string[];
  imageBandFilter: string[];
  resolutionFilter: string[];
  sensorFilter: string[];
  platformTypeFilter: string[];
  productType?: string[];
  collectionTypeFilter?: string[];
  qaStatusFilter?: string[];
  sessionIdFilter?: string;
  itemIdFilter?: string;
}

/**
 * Create a new copy of the query object with all parameters as the original set
 * and additionally the parameter at queryPath set as value, if addWhen is truth-y
 * @param query the original query to (maybe) have a new parameter added
 * @param addIf only add the new parameter if this is true
 * @param queryPath the full path of the new parameter to add (a.b.c for query['a']['b']['c'])
 * @param value provides the value to set for the new parameter (e.g. query['a']['b']['c'] = value)
 * @returns a new copy of the query with the new parameter set, if appropriate
 */
export const addToQueryIf = (
  query: Object,
  addIf: boolean,
  queryPath: string,
  value: () => void
): Object => {
  const newQuery = { ...query };
  if (addIf) {
    return set(newQuery, queryPath, value());
  }
  return newQuery;
};

export const makeQuery = (filters: IFilter) => {
  let query = {};

  query = addToQueryIf(
    query,
    Boolean(filters.satellites?.length),
    'sat:platform_international_designator',
    () => ({ in: filters.satellites })
  );
  query = addToQueryIf(
    query,
    Boolean(filters.productType?.length),
    'opencosmos:product_type',
    () => ({ in: filters.productType })
  );
  query = addToQueryIf(query, Boolean(filters.dateFrom), 'datetime.gte', () =>
    filters.dateFrom?.toISOString()
  );
  query = addToQueryIf(query, Boolean(filters.dateTo), 'datetime.lte', () =>
    moment(filters.dateTo)?.utc().endOf('day')?.toISOString()
  );
  query = addToQueryIf(
    query,
    filters.maxSZA !== undefined,
    'view:sun_elevation.gte',
    () => filters.maxSZA && 90 - filters.maxSZA
  );
  query = addToQueryIf(
    query,
    filters.minSZA !== undefined && filters.minSZA !== null,
    'view:sun_elevation.lte',
    () => 90 - (filters.minSZA ?? 0)
  );
  query = addToQueryIf(
    query,
    filters.maxOZA !== undefined,
    'view:incidence_angle.lte',
    () => filters.maxOZA
  );
  query = addToQueryIf(
    query,
    filters.minOZA !== undefined,
    'view:incidence_angle.gte',
    () => filters.minOZA
  );
  query = addToQueryIf(
    query,
    Boolean(filters.maxGSD),
    'gsd.lte',
    () => filters.maxGSD
  );
  query = addToQueryIf(
    query,
    Boolean(filters.minGSD),
    'gsd.gte',
    () => filters.minGSD
  );
  query = addToQueryIf(
    query,
    filters.minSunGlint !== undefined,
    'opencosmos:sun_glint.gte',
    () => filters.minSunGlint
  );
  query = addToQueryIf(
    query,
    filters.maxSunGlint !== undefined,
    'opencosmos:sun_glint.lte',
    () => filters.maxSunGlint
  );
  query = addToQueryIf(
    query,
    filters.maxCloudCoverage !== undefined,
    'eo:cloud_cover.lte',
    () => filters.maxCloudCoverage
  );
  query = addToQueryIf(
    query,
    Boolean(filters.sourceFilter?.length),
    'opencosmos:source',
    () => ({ in: filters.sourceFilter })
  );
  query = addToQueryIf(
    query,
    Boolean(filters.seasonsFilter?.length),
    'opencosmos:season',
    () => ({ in: filters.seasonsFilter })
  );
  query = addToQueryIf(
    query,
    Boolean(filters.processingLevel?.length),
    'processing:level',
    () => ({ in: filters.processingLevel })
  );

  query = addToQueryIf(
    query,
    Boolean(filters.resolutionFilter?.length),
    'opencosmos:resolution',
    () => ({ in: filters.resolutionFilter })
  );
  query = addToQueryIf(query, Boolean(filters.maxHours), 'time.lte', () =>
    moment(filters.maxHours).format('H:mm')
  );
  query = addToQueryIf(query, Boolean(filters.minHours), 'time.gte', () =>
    moment(filters.minHours).format('H:mm')
  );

  query = addToQueryIf(
    query,
    Boolean(filters.sensorFilter?.length),
    'opencosmos:sensor_type',
    () => ({ in: filters.sensorFilter })
  );

  query = addToQueryIf(
    query,
    Boolean(filters.platformTypeFilter?.length),
    'opencosmos:platform_type',
    () => ({ in: filters.platformTypeFilter })
  );

  query = addToQueryIf(
    query,
    Boolean(filters.collectionTypeFilter?.length),
    'opencosmos:collection_type',
    () => ({ in: filters.collectionTypeFilter })
  );

  query = addToQueryIf(
    query,
    Boolean(filters.qaStatusFilter?.length),
    'opencosmos:qa:rejected',
    () => ({ in: [true] })
  );
  query = addToQueryIf(
    query,
    Boolean(filters.sessionIdFilter?.length),
    'opencosmos:session_id',
    () => ({ in: [filters.sessionIdFilter] })
  );
  return query;
};

export default (token: string | undefined) => {
  /**
   * returns the images from the DataCosmos STAC catalog that match the filters
   * @param filters filter object that can include time or geographical constraints
   * @param limit number of results to be provided
   * @param cursor cursor to be used for pagination, this is the 'next' attribute of the previous search
   * @returns STAC results
   */
  const fetchImages = async (
    scenarioId: string,
    filters: IFilter,
    cursor?: string,
    limit?: number,
    intersects?: GeoJSON.Polygon[],
    collections?: string[] | undefined,
    ids?: string[] | undefined
  ) => {
    const query = makeQuery(filters);

    const data = (
      await search({
        params: {
          cursor,
        },
        body: {
          query,
          project: scenarioId,
          limit,
          intersects: intersects && firstPolygonOf(intersects),
          collections,
          ids: filters.itemIdFilter ? [filters.itemIdFilter] : ids,
        },
        abortable: false,
      })
    ).data;
    return data ? fromAPIResponse(data) : null;
  };

  /**
   * Finds the first polygon in the given list, if any
   * @param ps possibly empty list of polygons
   * @returns first polygon, if any, else undefined
   */
  const firstPolygonOf = (ps: Polygon[]): Polygon | undefined => {
    return ps && ps.length > 0 ? ps[0] : undefined;
  };

  /**
   * Converts the API search response type into a domain type
   * @param r response to a catalogue search in the API
   * @returns domain equivalent catalogue search response
   */
  const fromAPIResponse = (r: SearchResponse): IStacApiSearch => {
    return {
      features: r.features.map((f: STACItem) => fromAPIFeature(f)),
      type: r.type,
      numberMatched: r.numberMatched ?? r.context?.matched,
      numberReturned: r.numberReturned ?? r.context?.matched,
      links: r.links,
    };
  };

  /**
   * Converts the API search response STAC item/feature type into a domain type
   * @param f API search response STAC item/feature type
   * @returns domain STAC item/feature type
   */
  const fromAPIFeature = (f: STACItem): StacItem => {
    return new StacItem({
      id: f.id,
      collection: f.collection,
      stac_version: f.stac_version ?? '',
      stac_extensions: f.stac_extensions ?? [],
      bbox: f.bbox,
      type: f.type,
      links: f.links,
      assets: f.assets,
      geometry: f.geometry,
      properties: f.properties,
    });
  };

  const fetchCollection = async (id: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = await axios.get(`${SERVICE_URL}/collections/${id}`, {
      headers: {
        Authorization: `Bearer ${token ?? ''}`,
        'Content-Type': 'application/json',
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data;
  };

  const fetchItem = async (id: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = await axios.get(
      `${SERVICE_URL}/search?ids=${encodeURIComponent(id)}`,
      {
        headers: {
          Authorization: `Bearer ${token ?? ''}`,
          'Content-Type': 'application/json',
        },
      }
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    return data?.features && fromAPIResponse(data).features[0];
  };

  return {
    fetchImages,
    fetchItem,
    fetchCollection,
  };
};
