import { Spinner, Icon } from 'opencosmos-ui';
import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import ROUTES from 'routeConstants';
import { Navbar } from 'ui/Navbar';
import { useLocalisation } from 'utils/hooks/useLocalisation';

export interface IDefaultResourceTypeLayoutProps {
  children?: ReactNode;
  loading: boolean;
  leftTitle?: JSX.Element;
  middleTitle?: JSX.Element;
}

const DefaultResourceTypeLayout = (props: IDefaultResourceTypeLayoutProps) => {
  const { children, loading, leftTitle, middleTitle } = props;

  const { translate } = useLocalisation();

  return (
    <div className="bg-neutral dark:bg-neutral-dark w-full min-h-screen">
      <Navbar
        left={
          leftTitle ?? (
            <Link
              to={ROUTES.DATA_CATALOG}
              style={{ lineHeight: '0px' }}
              className="flex gap-2 items-center"
            >
              <Icon icon="ChevronLeft" />
              <div>{translate('datacosmos.resources.common.backToMain')}</div>
            </Link>
          )
        }
        showLogo={false}
        middle={middleTitle}
        bgClassName="mission-page-header"
        hideThemesInUserMenu={true}
      />
      <div className="container max-w-3xl mx-auto mt-4">
        {loading ? <Spinner /> : children}
      </div>
    </div>
  );
};

export default DefaultResourceTypeLayout;
