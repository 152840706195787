import React from 'react';
import type { IconName } from '@blueprintjs/core';
import { Classes } from '@blueprintjs/core';
import FolderNodeTree from './FolderTreeNode';
import FolderHeader from './FolderHeader';
import type { OrderByType } from './index';
import FolderEditableText from './FolderEditableText';
import type { EntryType } from '../../../../../../constants/fileExplorer/constants';
import s from './index.module.scss';
import useLocalStorage from 'utils/hooks/useLocalStorage';

export interface IFolderTreeItem {
  name: string | undefined;
  icon: IconName | undefined;
  type: EntryType;
  lastModified?: string;
  path?: string;
  aliasName?: string;
  dragType?: string;
  acceptedDropType?: string;
  size?: number;
  checksum?: number;
  md5?: string;
  handleItemClick?: (fileName: string) => void;
  handleItemPreview?: (fileName: string) => void;
  handleItemDownloadClick?: (fileName: string) => void;
  handleDropItem?: (fromPath: string, toPath: string) => void;
  handleCopyItemLink?: (fileName: string) => void;
}

interface IProps {
  items: IFolderTreeItem[];
  headerNames: string[];
  sortedBy: string;
  orderBy: OrderByType;
  handleSortByChange: (name: string) => void;
  handlePropertiesDialogOpen: (node: IFolderTreeItem) => void;
  handleDeleteItemClick: (name: string, path: string) => void;
  handleCopyFilePath: (fileName: string) => void;
  sorter: (arr: IFolderTreeItem[]) => IFolderTreeItem[];
  handleUploadClick: (file: unknown) => void;
  showCreateFolderWindow: boolean;
  simplifiedExplorer?: boolean;
}

const FolderTree = (props: IProps) => {
  const [hiddenColumns, setHiddenColumns] = useLocalStorage<
    string[] | undefined
  >('rti-storage-hidden-fields', undefined);

  const toggleColumnHiddenStatus = (columnName: string) => {
    if (getIsColumnHidden(columnName)) {
      setHiddenColumns(
        hiddenColumns?.filter((column) => column !== columnName)
      );
      return;
    }
    setHiddenColumns([...(hiddenColumns ?? []), columnName]);
  };

  const getIsColumnHidden = (columnName: string) => {
    return (hiddenColumns ?? [])
      .map((n) => n.toLowerCase())
      .includes(columnName.toLowerCase());
  };

  return (
    <div className={[Classes.TREE, s.folderTreeWrapper].join(' ')}>
      <FolderHeader
        rowNames={props.headerNames}
        sortedBy={props.sortedBy}
        orderBy={props.orderBy}
        handleSortByChange={props.handleSortByChange}
        toggleColumnHiddenStatus={toggleColumnHiddenStatus}
        getIsColumnHidden={getIsColumnHidden}
      />
      <FolderEditableText
        handleUploadClick={props.handleUploadClick}
        showCreateFolderWindow={props.showCreateFolderWindow}
      />
      <ul className={[Classes.TREE_NODE_LIST, Classes.TREE_ROOT].join(' ')}>
        {props.sorter(props.items).map((node) => (
          <FolderNodeTree
            key={node.path}
            content={node}
            handleItemClick={node.handleItemClick}
            handleItemPreview={node.handleItemPreview}
            handleDropItem={node.handleDropItem}
            handleItemDownloadClick={node.handleItemDownloadClick}
            handleCopyFilePath={props.handleCopyFilePath}
            handleDeleteItemClick={props.handleDeleteItemClick}
            //@ts-expect-error
            handlePropertiesDialogOpen={props.handlePropertiesDialogOpen}
            simplifiedExplorer={props.simplifiedExplorer}
            getIsColumnHidden={getIsColumnHidden}
            handleCopyItemLink={node.handleCopyItemLink}
          />
        ))}
      </ul>
    </div>
  );
};
export default FolderTree;
