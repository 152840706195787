import { ColumnLayout } from 'ui/ColumnLayout/ColumnLayout';
import { Column } from 'ui/ColumnLayout/Column';
import { Header } from 'ui/Header';
import { ItemTabContainer } from 'ui/ItemContainer';
import { Text } from 'ui/Text';
import type { useTaskingOverviewData } from './useTaskingOverviewData';
import { Loader } from 'ui/Loader';
import {
  Button,
  DateRangePicker,
  DialogTrigger,
  Icon,
  ListBoxItem,
  parseDate,
  Popover,
  Select,
  Tooltip,
} from 'opencosmos-ui';
import { Dialog } from 'react-aria-components';
import type { RequestType } from '_api/tasking/types';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import PaginatedTaskingRequestsList from './PaginatedTaskingRequestsList';
import { isNil } from 'lodash';
import moment from 'moment';
import ListFilter from './filters/ListFilter';
import { useEffect, useRef, useState } from 'react';

const TaskingOverviewScreen = ({
  taskingOverviewData,
}: {
  taskingOverviewData: ReturnType<typeof useTaskingOverviewData>;
}) => {
  const {
    classifiedRequests,
    selectedRequest,
    handleTaskingRequestClick,
    loading,
    filter,
    handleMissionIdFilterClick,
    handleOrganisationIdFilterClick,
    handleTypeFilterClick,
    handleProjectIdFilterClick,
    allTaskingRequestsMetadata,
    getRequestsByStatus,
    clearAllFilters,
    handleCreationDateFilterClick,
    handleAcquisitionDateFilterClick,
  } = taskingOverviewData;

  const { translate } = useLocalisation();

  const [isOpen, setIsOpen] = useState(false);
  const [popoverElement, setPopoverElement] = useState<HTMLElement>();
  const ref = useRef<HTMLElement>(null);

  // Workaround to force rerender when the popover is open,
  // So that the portal container is set correctly
  useEffect(() => {
    if (isOpen && ref.current) {
      setPopoverElement(ref.current);
    }
  }, [isOpen]);

  return (
    <>
      <ItemTabContainer
        fullWidth={true}
        className="dark:bg-header-dark bg-header"
      >
        <Header margin={{ left: { value: 0 }, right: { value: 'auto' } }}>
          Requests
        </Header>

        <DialogTrigger onOpenChange={setIsOpen}>
          <Button data-testid="open-filters" size="md" icon="Filter">
            {translate('datacosmos.buttons.filters')}
          </Button>
          <Popover
            className={'color-surface p-3 border'}
            placement="bottom right"
            ref={ref}
          >
            <Dialog className={'flex flex-col gap-3 w-80'}>
              <div>
                <Text padding={0}>Date of creation</Text>
                <DateRangePicker
                  granularity="day"
                  showClearButton={Boolean(filter.fromDate && filter.toDate)}
                  handleClearPress={() => {
                    if (filter.fromDate && filter.toDate) {
                      handleCreationDateFilterClick([
                        filter.fromDate,
                        filter.toDate,
                      ]);
                    }
                  }}
                  onChange={(range) => {
                    handleCreationDateFilterClick([
                      new Date(range?.start.toString() ?? ''),
                      moment(new Date(range?.end.toString() ?? ''))
                        .utc()
                        .endOf('day')
                        .toDate(),
                    ]);
                  }}
                  value={
                    !isNil(filter.fromDate) && !isNil(filter.toDate)
                      ? {
                          start: parseDate(
                            moment.utc(filter.fromDate).format('YYYY-MM-DD')
                          ),
                          end: parseDate(
                            moment.utc(filter.toDate).format('YYYY-MM-DD')
                          ),
                        }
                      : null
                  }
                />
              </div>

              <div>
                <Text padding={0}>Acquisition date</Text>
                <DateRangePicker
                  granularity="day"
                  showClearButton={Boolean(
                    filter.acquisitionStartDate && filter.acquisitionEndDate
                  )}
                  handleClearPress={() => {
                    if (
                      filter.acquisitionStartDate &&
                      filter.acquisitionEndDate
                    ) {
                      handleAcquisitionDateFilterClick([
                        filter.acquisitionStartDate,
                        filter.acquisitionEndDate,
                      ]);
                    }
                  }}
                  onChange={(range) => {
                    handleAcquisitionDateFilterClick([
                      new Date(range?.start.toString() ?? ''),
                      moment(new Date(range?.end.toString() ?? ''))
                        .utc()
                        .endOf('day')
                        .toDate(),
                    ]);
                  }}
                  value={
                    !isNil(filter.acquisitionStartDate) &&
                    !isNil(filter.acquisitionEndDate)
                      ? {
                          start: parseDate(
                            moment
                              .utc(filter.acquisitionStartDate)
                              .format('YYYY-MM-DD')
                          ),
                          end: parseDate(
                            moment
                              .utc(filter.acquisitionEndDate)
                              .format('YYYY-MM-DD')
                          ),
                        }
                      : null
                  }
                />
              </div>

              <Select
                label={translate('datacosmos.tasking.new.type')}
                selectedKey={filter?.type ?? 'ALL'}
                placeholder={translate(
                  'datacosmos.tasking.existing.filters.placeholder.type'
                )}
                name="type"
                testid="select-type"
                onSelectionChange={(val) => {
                  handleTypeFilterClick(val as string);
                }}
                fill
              >
                <ListBoxItem id={'ALL'} key={'ALL'}>
                  {translate('datacosmos.tasking.existing.filters.all')}
                </ListBoxItem>
                {['MANUAL', 'AUTOMATED'].map((type) => (
                  <ListBoxItem
                    id={type}
                    key={type}
                    data-testid={`type-option-${type}`}
                  >
                    {translate(
                      `datacosmos.tasking.existing.requestType.${
                        type as RequestType
                      }`
                    )}
                  </ListBoxItem>
                ))}
              </Select>

              <ListFilter
                label={translate('datacosmos.catalogAndItems.project')}
                selectedKey={filter?.projectId ?? 'ALL'}
                onSelectionChange={(val) => {
                  handleProjectIdFilterClick(val as string);
                }}
                items={filter?.availableProjects ?? []}
                testid="select-project"
                itemTestIdPrefix="project-option"
                containerElement={popoverElement}
              />

              <ListFilter
                label={translate('datacosmos.tasking.existing.filters.mission')}
                selectedKey={filter?.missionId ?? 'ALL'}
                onSelectionChange={(val) => {
                  handleMissionIdFilterClick(val as string);
                }}
                items={filter?.availableMissionIds ?? []}
                testid="select-mission"
                itemTestIdPrefix="missionId-option"
                containerElement={popoverElement}
                optionsGender="female"
              />

              <ListFilter
                label={translate('datacosmos.resources.organisation')}
                selectedKey={filter?.organisationId ?? 'ALL'}
                onSelectionChange={(val) => {
                  handleOrganisationIdFilterClick(val as string);
                }}
                items={filter?.availableOrganisations ?? []}
                testid="select-organisation"
                itemTestIdPrefix="organisationId-option"
                containerElement={popoverElement}
                optionsGender="female"
              />

              <Button
                onPress={() => {
                  clearAllFilters();
                }}
                fill
                intent="warning"
                icon="cross"
                iconPlacement="left"
                isMinimal
                size="sm"
                className={'my-1'}
              >
                {translate('datacosmos.filters.clearAll')}
              </Button>
            </Dialog>
          </Popover>
        </DialogTrigger>
      </ItemTabContainer>

      {loading ? (
        <Loader />
      ) : (
        <ColumnLayout
          cols={7}
          colRatio={[1, 1, 1, 1, 1, 1, 1]}
          horizontalGap={3}
        >
          <Column key={1} style={{ overflowY: 'auto', position: 'relative' }}>
            <div className="flex items-center justify-between sticky top-0 bg-surface dark:bg-surface-dark z-10">
              <div className="flex items-center">
                <Text>
                  {translate(
                    'datacosmos.tasking.existing.requestStatus.PENDING.title'
                  )}
                </Text>
                <Tooltip
                  content={translate(
                    'datacosmos.tasking.existing.requestStatus.PENDING.description'
                  )}
                >
                  <Icon icon="Info" />
                </Tooltip>
              </div>
              {allTaskingRequestsMetadata?.PENDING && (
                <Text>
                  {`Total : ${allTaskingRequestsMetadata?.PENDING?.total_results}`}
                </Text>
              )}
            </div>

            <PaginatedTaskingRequestsList
              hasMore={Boolean(allTaskingRequestsMetadata?.PENDING?.remaining)}
              data-testid="pending-column"
              classifiedRequests={classifiedRequests?.pending}
              handleTaskingRequestClick={handleTaskingRequestClick}
              getRequestsByStatus={getRequestsByStatus}
              status="PENDING"
              cursor={allTaskingRequestsMetadata?.PENDING?.cursor}
              selectedRequest={selectedRequest}
            />
          </Column>

          <Column key={2} style={{ overflowY: 'auto', position: 'relative' }}>
            <div className="flex items-center justify-between sticky top-0 bg-surface dark:bg-surface-dark z-10">
              <div className="flex items-center">
                <Text>
                  {translate(
                    'datacosmos.tasking.existing.requestStatus.PREPARED.title'
                  )}
                </Text>
                <Tooltip
                  content={translate(
                    'datacosmos.tasking.existing.requestStatus.PREPARED.description'
                  )}
                >
                  <Icon icon="Info" />
                </Tooltip>
              </div>

              {allTaskingRequestsMetadata?.PREPARED && (
                <Text>
                  {`Total : ${allTaskingRequestsMetadata?.PREPARED?.total_results}`}
                </Text>
              )}
            </div>

            <PaginatedTaskingRequestsList
              hasMore={Boolean(allTaskingRequestsMetadata?.PREPARED?.remaining)}
              classifiedRequests={classifiedRequests?.prepared}
              handleTaskingRequestClick={handleTaskingRequestClick}
              data-testid="prepared-column"
              getRequestsByStatus={getRequestsByStatus}
              status="PREPARED"
              cursor={allTaskingRequestsMetadata?.PREPARED?.cursor}
              selectedRequest={selectedRequest}
            />
          </Column>

          <Column key={3} style={{ overflowY: 'auto', position: 'relative' }}>
            <div className="flex items-center justify-between sticky top-0 bg-surface dark:bg-surface-dark z-10">
              <div className="flex items-center">
                <Text>
                  {translate(
                    'datacosmos.tasking.existing.requestStatus.ACQUIRING.title'
                  )}
                </Text>
                <Tooltip
                  content={translate(
                    'datacosmos.tasking.existing.requestStatus.ACQUIRING.description'
                  )}
                >
                  <Icon icon="Info" />
                </Tooltip>
              </div>
              {allTaskingRequestsMetadata?.ACQUIRING && (
                <Text>
                  {`Total : ${allTaskingRequestsMetadata?.ACQUIRING?.total_results}`}
                </Text>
              )}
            </div>
            <PaginatedTaskingRequestsList
              hasMore={Boolean(
                allTaskingRequestsMetadata?.ACQUIRING?.remaining
              )}
              classifiedRequests={classifiedRequests?.acquiring}
              data-testid="acquiring-column"
              handleTaskingRequestClick={handleTaskingRequestClick}
              getRequestsByStatus={getRequestsByStatus}
              status="ACQUIRING"
              cursor={allTaskingRequestsMetadata?.ACQUIRING?.cursor}
              selectedRequest={selectedRequest}
            />
          </Column>

          <Column key={4} style={{ overflowY: 'auto', position: 'relative' }}>
            <div className="flex items-center justify-between sticky top-0 bg-surface dark:bg-surface-dark z-10">
              <div className="flex items-center">
                <Text>
                  {translate(
                    'datacosmos.tasking.existing.requestStatus.FULFILLED.title'
                  )}
                </Text>
                <Tooltip
                  content={translate(
                    'datacosmos.tasking.existing.requestStatus.FULFILLED.description'
                  )}
                >
                  <Icon icon="Info" />
                </Tooltip>
              </div>
              {allTaskingRequestsMetadata?.FULFILLED && (
                <Text>
                  {`Total : ${allTaskingRequestsMetadata?.FULFILLED?.total_results}`}
                </Text>
              )}
            </div>
            <PaginatedTaskingRequestsList
              hasMore={Boolean(
                allTaskingRequestsMetadata?.FULFILLED?.remaining
              )}
              classifiedRequests={classifiedRequests?.fulfilled}
              data-testid="fulfilled-column"
              handleTaskingRequestClick={handleTaskingRequestClick}
              getRequestsByStatus={getRequestsByStatus}
              status="FULFILLED"
              cursor={allTaskingRequestsMetadata?.FULFILLED?.cursor}
              selectedRequest={selectedRequest}
            />
          </Column>

          <Column key={5} style={{ overflowY: 'auto', position: 'relative' }}>
            <div className="flex items-center justify-between sticky top-0 bg-surface dark:bg-surface-dark z-10">
              <div className="flex items-center">
                <Text>
                  {translate(
                    'datacosmos.tasking.existing.requestStatus.PARTIALLY_FULFILLED.title'
                  )}
                </Text>
                <Tooltip
                  content={translate(
                    'datacosmos.tasking.existing.requestStatus.PARTIALLY_FULFILLED.description'
                  )}
                >
                  <Icon icon="Info" />
                </Tooltip>
              </div>

              {allTaskingRequestsMetadata?.PARTIALLY_FULFILLED && (
                <Text>
                  {`Total : ${allTaskingRequestsMetadata?.PARTIALLY_FULFILLED?.total_results}`}
                </Text>
              )}
            </div>
            <PaginatedTaskingRequestsList
              hasMore={Boolean(
                allTaskingRequestsMetadata?.PARTIALLY_FULFILLED?.remaining
              )}
              classifiedRequests={classifiedRequests?.partiallyFulfilled}
              data-testid="partially-fulfilled-column"
              handleTaskingRequestClick={handleTaskingRequestClick}
              getRequestsByStatus={getRequestsByStatus}
              status="PARTIALLY_FULFILLED"
              cursor={allTaskingRequestsMetadata?.PARTIALLY_FULFILLED?.cursor}
              selectedRequest={selectedRequest}
            />
          </Column>

          <Column key={6} style={{ overflowY: 'auto', position: 'relative' }}>
            <div className="flex items-center justify-between sticky top-0 bg-surface dark:bg-surface-dark z-10">
              <div className="flex items-center">
                <Text>
                  {translate(
                    'datacosmos.tasking.existing.requestStatus.FAILED.title'
                  )}
                </Text>
                <Tooltip
                  content={translate(
                    'datacosmos.tasking.existing.requestStatus.FAILED.description'
                  )}
                >
                  <Icon icon="Info" />
                </Tooltip>
              </div>

              {allTaskingRequestsMetadata?.FAILED && (
                <Text>
                  {`Total : ${allTaskingRequestsMetadata?.FAILED?.total_results}`}
                </Text>
              )}
            </div>
            <PaginatedTaskingRequestsList
              hasMore={Boolean(allTaskingRequestsMetadata?.FAILED?.remaining)}
              classifiedRequests={classifiedRequests?.failed}
              data-testid="failed-column"
              handleTaskingRequestClick={handleTaskingRequestClick}
              getRequestsByStatus={getRequestsByStatus}
              status="FAILED"
              cursor={allTaskingRequestsMetadata?.FAILED?.cursor}
              selectedRequest={selectedRequest}
            />
          </Column>

          <Column key={7} style={{ overflowY: 'auto' }}>
            <div className="flex items-center justify-between sticky top-0 bg-surface dark:bg-surface-dark z-10">
              <div className="flex items-center">
                <Text>
                  {translate(
                    'datacosmos.tasking.existing.requestStatus.CANCELLED.title'
                  )}
                </Text>
                <Tooltip
                  content={translate(
                    'datacosmos.tasking.existing.requestStatus.CANCELLED.description'
                  )}
                >
                  <Icon icon="Info" />
                </Tooltip>
              </div>

              {allTaskingRequestsMetadata?.CANCELLED && (
                <Text>
                  {`Total : ${allTaskingRequestsMetadata?.CANCELLED?.total_results}`}
                </Text>
              )}
            </div>
            <PaginatedTaskingRequestsList
              hasMore={Boolean(
                allTaskingRequestsMetadata?.CANCELLED?.remaining
              )}
              classifiedRequests={classifiedRequests?.cancelled}
              data-testid="cancelled-column"
              handleTaskingRequestClick={handleTaskingRequestClick}
              getRequestsByStatus={getRequestsByStatus}
              status="CANCELLED"
              cursor={allTaskingRequestsMetadata?.CANCELLED?.cursor}
              selectedRequest={selectedRequest}
            />
          </Column>
        </ColumnLayout>
      )}
    </>
  );
};

export default TaskingOverviewScreen;
