import type { ProviderView } from 'datacosmos/stores/ViewsProvider';
import React, { useState } from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Dialog, Input } from 'opencosmos-ui';

interface IProps {
  view: ProviderView | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSave: (name: string, description: string, viewId?: string) => void;
  overwrite?: true;
}

const SaveViewDialog = ({
  isOpen,
  setIsOpen,
  handleSave,
  overwrite,
  view,
}: IProps) => {
  const [name, setName] = useState<string>(view?.name ?? '');
  const [description, setDescription] = useState<string>(
    view?.description ?? ''
  );

  const { translate } = useLocalisation();
  return (
    <Dialog
      isOpen={isOpen}
      buttons={[
        {
          text: translate(
            `datacosmos.buttons.${overwrite ? 'overwrite' : 'save'}`
          ),
          shown: true,
          onPress: () => {
            if (view?.id) {
              handleSave(view.name, view.description, view.id);
            } else {
              handleSave(name, description);
            }

            setIsOpen(false);
          },
          isDisabled: !overwrite && (!name || !description),
          keepDialogOpenOnPress: true,
        },
      ]}
      onClose={() => {
        setIsOpen(false);
        setName('');
        setDescription('');
      }}
      style={{
        width: '600px',
      }}
      title={
        overwrite
          ? translate('datacosmos.saveViewDialog.overwrite', {
              name: view?.name ?? 'unknown',
            })
          : translate('datacosmos.saveViewDialog.save')
      }
    >
      <div className={'dark:bg-surface-dark dark:text-item-dark-contrast'}>
        {!overwrite && (
          <div
            className={
              'flex flex-col gap-4 my-1 dark:bg-surface-dark dark:text-item-dark-contrast'
            }
          >
            <Input
              type="text"
              label={{
                text: translate('datacosmos.saveViewDialog.inputs.name'),
                position: 'top',
              }}
              name="name"
              id="name"
              placeholder={translate(
                'datacosmos.saveViewDialog.inputs.namePlaceholder'
              )}
              autoComplete="off"
              onChange={(e) => setName(e.target.value)}
              className=" bg-surface dark:bg-surface-dark dark:text-item-dark-contrast"
            />

            <Input
              name="description"
              label={{
                text: translate('datacosmos.saveViewDialog.inputs.description'),
                position: 'top',
              }}
              id="description"
              placeholder={translate(
                'datacosmos.saveViewDialog.inputs.descriptionPlaceholder'
              )}
              type="textarea"
              onChange={(e) => setDescription(e.target.value)}
              className=" bg-surface dark:bg-surface-dark dark:text-item-dark-contrast"
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default SaveViewDialog;
