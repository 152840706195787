import { useMemo, useState } from 'react';
import { Button, NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ActivityDetailsCard } from 'pages/ops/Scheduling/components/DetailCards/ActivityDetailsCard';
import s from './index.module.scss';
import { RequestDetailsCard } from 'pages/ops/Scheduling/components/DetailCards/RequestDetailsCard';
import { ActivitiesMap } from 'pages/ops/Scheduling/components/ActivitiesMap';
import { useRouteMatch } from 'react-router';
import FullscreenMap from '../ActivitiesMap/FullscreenMap';
import { useActivities } from '../../context/ActivitiesProvider';
import { Tooltip } from 'opencosmos-ui';

export const ScheduleActivityDetails = () => {
  const {
    activities,
    requests,
    refetchActivities,
    updatedActivitiesMap,
    updateActivity,
    batchSubmitUpdateActivities,
    isActivityEditable,
    failureReasonSchema,
    resetStatusReason,
    shouldDisableActivityUpdate,
    isActivityMapActivityInFailedState,
  } = useActivities();

  const urlMatch = useRouteMatch<{ activity?: string }>({
    path: '/ops/mission/:mission/schedule/activity/:activity',
    exact: false,
  });

  const selectedActivity = useMemo(() => {
    return activities?.find(({ id }) => id === urlMatch?.params.activity);
  }, [activities, urlMatch?.params.activity]);

  const request = requests.find(
    (r) => r.id === selectedActivity?.tasking_request_ids[0]
  );

  const [isMapOpen, setIsMapOpen] = useState<boolean>(false);

  if (!selectedActivity) {
    return (
      <NonIdealState
        icon={IconNames.HELP}
        title="No activity selected"
        description="Select an activity to view it here"
      />
    );
  }

  if (!request) {
    return (
      <NonIdealState
        icon={IconNames.ERROR}
        title="An error happened"
        description={'Could not find the request for this activity'}
      />
    );
  }

  return (
    <div className={s.container} data-testid="activity-details">
      {request && (
        <ActivitiesMap
          request={request}
          activity={selectedActivity}
          mapElements={
            <Tooltip
              isDisabled={isActivityEditable(selectedActivity)}
              content={
                'Failed, expired, canceled and scheduled activities cannot be edited'
              }
            >
              <Button
                icon={IconNames.EDIT}
                onClick={() => setIsMapOpen(isMapOpen ? false : true)}
                disabled={!isActivityEditable(selectedActivity)}
              />
            </Tooltip>
          }
          aspectRatio="16/9"
          autoHeight
        />
      )}
      <ActivityDetailsCard
        activity={selectedActivity}
        updatedActivitiesMap={updatedActivitiesMap}
        updateActivity={updateActivity}
        batchSubmitUpdateActivities={batchSubmitUpdateActivities}
        failureReasonProperties={failureReasonSchema}
        resetStatusReason={resetStatusReason}
        shouldDisableUpdateBtn={shouldDisableActivityUpdate}
        isActivityMapActivityInFailedState={isActivityMapActivityInFailedState}
      />
      {request && (
        <RequestDetailsCard
          request={request}
          totalActivities={request.activities.length}
          refetchData={refetchActivities}
        />
      )}

      <FullscreenMap
        activities={activities?.filter((a) =>
          request?.activities.some((act) => a.id === act.id)
        )}
        request={request}
        isMapOpen={isMapOpen}
        setIsMapOpen={setIsMapOpen}
        activityToModify={selectedActivity}
        refetchActivities={refetchActivities}
      />
    </div>
  );
};
