import { useEffect, useRef, useState } from 'react';
import { SearchInput, Icon, Button, Spinner, Dialog } from 'opencosmos-ui';
import { filter, pipe, sortBy } from 'lodash/fp';

export type DialogItem = {
  name: string;
  id: string;
};

export interface IItem {
  id: string | number;
}

export type DialogListRenderer<T extends IItem> = (
  item: T,
  selectItem: () => void
) => JSX.Element | null;

export type DialogItemDetailsRenderer<T extends IItem> = (
  item: T,
  onClickHandler: (item: T) => void
) => React.ReactNode | null;

interface DatacosmosSelectDialogProps<T extends IItem> {
  items: T[];
  listRenderer: DialogListRenderer<T>;
  listItemClickHandler: (item: T) => void;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  handleAddItemClick?: () => void;
  handleViewAllClick?: () => void;
  addItemTitle?: string;
  viewAllTitle?: string;
  title: string;
  isCloseButtonShown?: boolean;
  canOutsideClickClose?: boolean;
  canEscapeKeyClose?: boolean;
  handleItemDetailsRender: DialogItemDetailsRenderer<T>;
  selectedItem: T | undefined;
  setSelectedItem: (item: T | undefined) => void;
  sortListBy: keyof T;
  filterListBy?: keyof T;
  isContentLoading: boolean;
}

const DatacosmosSelectDialog = <T extends IItem>({
  title,
  items,
  listRenderer,
  listItemClickHandler,
  isOpen,
  setIsOpen,
  handleAddItemClick,
  handleItemDetailsRender,
  selectedItem,
  setSelectedItem,
  sortListBy,
  filterListBy,
  addItemTitle,
  isContentLoading,
  handleViewAllClick,
  viewAllTitle,
}: DatacosmosSelectDialogProps<T>) => {
  const [filterValue, setFilterValue] = useState<string>('');
  const searchInputRef = useRef<HTMLInputElement>(null);
  // Workaround for focusing the search input when the App Tour is displayed
  // on the background (and keeps stealing focus)
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    searchInputRef.current?.focus();
  }, [items]);
  const filteredItems: T[] = pipe(
    filterValue && filterListBy
      ? filter((item: T) =>
          (item[filterListBy] as string)
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        )
      : (arr: T[]) => arr,
    sortBy((item: T) => (item[sortListBy] as string).toLowerCase())
  )(items);
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      buttons={[]}
      title={title}
      hideCancelButton
      disableFocusManagement
    >
      <div
        className="grid w-full"
        style={{
          gridTemplateColumns: selectedItem ? '2fr 2fr' : '2fr',
        }}
      >
        <div
          className={'flex flex-col items-center h-[70vh]'}
          data-testid="datacosmos-select-modal"
        >
          <div
            className="flex flex-col gap-2 w-4/5 overflow-auto scrollbar-light dark:scrollbar-dark"
            data-testid="modal-items-container"
          >
            {filterListBy && (
              <SearchInput
                fullWidth
                autoFocus
                ref={searchInputRef}
                onChange={setFilterValue}
                searchButtonRenderer={() => <Icon icon="MagnifyingGlass" />}
                data-testid="modal-items-search"
              />
            )}
            {isContentLoading ? (
              <div className="flex justify-center items-center">
                <Spinner />
              </div>
            ) : (
              filteredItems.map((item) => (
                <div
                  className={
                    'hover:bg-item-selected hover:text-accent-900 dark:hover:bg-item-dark-selected dark:hover:text-item-dark-hover cursor-pointer'
                  }
                  data-testid="modal-item"
                  key={item.id}
                >
                  {listRenderer(item, () => setSelectedItem(item))}
                </div>
              ))
            )}
          </div>
          <div className={'mt-4 flex flex-col gap-2'}>
            {handleAddItemClick && (
              <Button
                className="flex items-center p-1"
                onPress={handleAddItemClick}
                isMinimal
                isTransparent
                data-testid="modal-add-item-button"
              >
                {addItemTitle ? addItemTitle : 'Add item'}
                <Icon icon={'Plus'} />
              </Button>
            )}
            {handleViewAllClick && Boolean(filteredItems?.length) && (
              <Button
                className={`flex items-center justify-center gap-1 w-full`}
                onPress={handleViewAllClick}
                isMinimal
                isTransparent
              >
                {viewAllTitle}
                <Icon icon={'ChevronRight'} />
              </Button>
            )}
          </div>
        </div>
        {selectedItem && (
          <div>
            {handleItemDetailsRender(selectedItem, listItemClickHandler)}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default DatacosmosSelectDialog;
