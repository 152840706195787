import { Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { ProviderView } from 'datacosmos/stores/ViewsProvider';
import { useViews } from 'datacosmos/stores/ViewsProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import type {
  DialogListRenderer,
  DialogItemDetailsRenderer,
} from 'datacosmos/components/DatacosmosSelectDialog/DatacosmosSelectDialog';
import DatacosmosSelectDialog from 'datacosmos/components/DatacosmosSelectDialog/DatacosmosSelectDialog';
import DeleteViewDialog from './DeleteViewDialog';
import SaveViewDialog from './SaveViewDialog';
import {
  generateGifWithImages,
  isLayerInBounds,
  screenshot,
  screenshotLinkToUsableImage,
  screenshotMimeToExtension,
  vectorMimeTypes,
} from 'utils/screenshot';
import type { MimeType as ScMimeType } from 'utils/screenshot';
import { useViewMode } from 'datacosmos/utils/hooks/useViewMode';
import classNames from 'classnames';
import type { Scenario } from '_api/scenarios/types';
import moment from 'moment';
import { DATETIME_FORMAT } from 'constants/datetime';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { Popover2 } from '@blueprintjs/popover2';
import PrimaryButton from '_molecules/Button/PrimaryButton';
import placeholderImage from 'datacosmos/assets/catalog/img-placeholder.svg';
import OptionsDialog from '_organisms/OptionsDialog/OptionsDialog';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import GeoPDFDetails from '../GeoPDFDetailsModal';
import { uploadFileToProject } from '_api/geopdf/service';
import {
  convertLayersToFeatureCollection,
  downloadGeojsonAsKml,
  downloadGeojsonAsKmz,
  downloadGeojsonAsShp,
  downloadAOIasGeojson,
} from 'datacosmos/download/geojson';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import { Button } from 'opencosmos-ui';
import {
  areAllUploadedFilesGeoReferenced,
  isENVIPartiallyUploaded,
  isShpPartiallyUploaded,
} from 'utils/fileUpload';
import { OutlineLayer } from 'datacosmos/entities/outlineLayer';
import { toaster } from 'toaster';
import LocationDetailsDialog from '../Header/UploadFileToProject/LocationDetailsDialog';
interface IProps {
  currentScenario?: Scenario;
  fetchCurrentProjectItems: () => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getMapContainer = (mapRef: React.MutableRefObject<any>) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return mapRef.current?.getContainer
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      (mapRef.current.getContainer() as HTMLDivElement)
    : null;
};

const Views = ({ currentScenario, fetchCurrentProjectItems }: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState<boolean>(false);
  const [isSavingView, setIsSavingView] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const uploadFileRef = useRef<HTMLInputElement>(null);

  const [isChangeFormatDialogOpen, setIsChangeFormatDialogOpen] =
    useState<boolean>(false);

  const { translate } = useLocalisation();

  const { sendInfo } = useAnalytics();

  const [isOverwriteDialogOpen, setIsOverwriteDialogOpen] =
    useState<boolean>(false);

  const {
    handleViewOverwrite,
    handleViewSave,
    isSaveEnabled,
    views,
    currentView,
    setCurrentView,
    handleViewDelete,
    initUntitledView,
    handleViewChange,
    loading,
    savedMime,
    setSavedMime,
    isIncludeWatermark,
    includeThumbnails,
  } = useViews();

  const { isMinimal } = useViewMode();

  const [viewToDelete, setViewToDelete] = useState<ProviderView | undefined>(
    currentView
  );

  const history = useHistory();
  const location = useLocation();

  const { mapRef, getMapBounds } = useMap();

  const { layers, replaceLayer } = useMapLayers();

  const [selectedView, setSelectedView] = useState<ProviderView>();

  const [viewScreenshot, setViewScreenshot] = useState<string | undefined>(
    placeholderImage
  );

  const downloadFileName = `open_cosmos_screenshot_${new Date().toISOString()}`;

  const [isGeoPdfDetailsOpen, setIsGeoPdfDetailsOpen] =
    useState<boolean>(false);
  // Cache fetcehd screenshots to avoid refetching
  const [cachedScreenshots, setCachedScreenshots] = useState<
    { screenshot: string | undefined; viewId: string }[]
  >([]);

  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);

  const [isDownloadingFile, setIsDownloadingFile] = useState<boolean>(false);
  const [isLocationDetailsOpen, setIsLocationDetailsOpen] =
    useState<boolean>(false);

  const { hasPermission: canWriteToProject } = useCheckPermissions({
    permissions: {
      type: 'datacosmos_scenario',
      actionScope: 'data:scenario:write',
      id: String(currentScenario?.id),
    },
  });

  useEffect(() => {
    if (!selectedView) {
      return;
    }
    const foundSc = cachedScreenshots.find(
      (sc) => sc.viewId === selectedView.id
    );
    if (foundSc) {
      setViewScreenshot(foundSc.screenshot);
      return;
    }

    screenshotLinkToUsableImage(selectedView.screenshot_link)
      .then((res) => {
        setViewScreenshot(res);
        setCachedScreenshots([
          ...cachedScreenshots,
          { screenshot: res, viewId: selectedView.id },
        ]);
      })
      .catch(() => {});
  }, [cachedScreenshots, selectedView]);

  const geoJsonFromLayers = useMemo(() => {
    return convertLayersToFeatureCollection(layers);
  }, [layers]);

  const downloadVectorScreenshot = useCallback(
    async (mimeFormat: string) => {
      switch (mimeFormat) {
        case 'GeoJSON':
        case 'file/geojson':
          setSavedMime('file/geojson');
          downloadAOIasGeojson(geoJsonFromLayers, downloadFileName);
          setIsDownloadingFile(false);
          break;
        case 'KML':
        case 'application/vnd.google-earth.kml+xml':
          setSavedMime('application/vnd.google-earth.kml+xml');
          downloadGeojsonAsKml(
            geoJsonFromLayers,
            downloadFileName,
            isIncludeWatermark
          );
          setIsDownloadingFile(false);

          break;
        case 'KMZ':
        case 'application/vnd.google-earth.kmz':
          setSavedMime('application/vnd.google-earth.kmz');
          await downloadGeojsonAsKmz(
            geoJsonFromLayers,
            downloadFileName,
            isIncludeWatermark,
            includeThumbnails
          );
          setIsDownloadingFile(false);

          break;
        case 'SHP':
        case 'application/vnd.shp':
          setSavedMime('application/vnd.shp');
          void downloadGeojsonAsShp(geoJsonFromLayers, downloadFileName);
          setIsDownloadingFile(false);

          break;
        default:
          break;
      }
    },
    [
      setSavedMime,
      geoJsonFromLayers,
      downloadFileName,
      isIncludeWatermark,
      includeThumbnails,
    ]
  );

  const downloadScreenshot = useCallback(
    async (mimeType?: ScMimeType) => {
      setIsDownloadingFile(true);
      const container = getMapContainer(mapRef);

      if (!container) {
        setIsDownloadingFile(false);
        return;
      }

      const mime = mimeType ?? savedMime;
      setSavedMime(mime);

      if (mimeType === 'application/pdf') {
        setIsGeoPdfDetailsOpen(true);
        setIsDownloadingFile(false);
        return;
      }

      if (vectorMimeTypes.includes(mime)) {
        await downloadVectorScreenshot(mime);
        return;
      }

      void screenshot(container, {
        mimeType: mime,
        ignore: 'zoom-controls',
        includeWaterMark: isIncludeWatermark,
      }).then((base64Image) => {
        const downloadLink = document.createElement('a');
        downloadLink.download = `${downloadFileName}${screenshotMimeToExtension(
          mime
        )}`;
        downloadLink.href = base64Image;
        downloadLink.dataset.downloadurl = [
          mime,
          downloadLink.download,
          downloadLink.href,
        ].join(':');

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        downloadLink.remove();
        setIsDownloadingFile(false);
      });
    },
    [
      mapRef,
      savedMime,
      setSavedMime,
      isIncludeWatermark,
      downloadFileName,
      downloadVectorScreenshot,
      setIsDownloadingFile,
    ]
  );

  const videoLayers = useMemo(() => {
    return layers.filter((layer) => !(layer instanceof OutlineLayer));
  }, [layers]);

  const downloadLayersAsVideo = useCallback(async () => {
    setIsDownloadingFile(true);
    const mapBounds = getMapBounds();

    if (!mapBounds) {
      setIsDownloadingFile(false);
      return;
    }
    const reversedArray = videoLayers.reverse();
    reversedArray.forEach((l) => {
      replaceLayer(
        l.cloneWithOptions({
          visible: false,
        })
      );
    });

    const images: string[] = [];
    let toasterShown: boolean = false;
    for (let i = 0; i < reversedArray?.length; i++) {
      const layerInIteration = reversedArray[i];
      const isInBounds = isLayerInBounds(layerInIteration, mapBounds);

      if (!isInBounds) {
        if (!toasterShown) {
          toaster.show({
            message: translate('datacosmos.optionsDialog.animatedGifWarning'),
            intent: 'warning',
            timeout: 4000,
          });
          toasterShown = true;
        }
        continue;
      }

      replaceLayer(
        reversedArray[i].cloneWithOptions({
          visible: true,
        })
      );

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const result = await screenshot(mapRef?.current?.getContainer(), {
        mimeType: 'image/png',
        ignore: 'zoom-controls',
        scale: 10,
      });

      images.push(result);
    }

    const width = mapRef?.current?.getContainer().clientWidth;
    const height = mapRef?.current?.getContainer()?.clientHeight;
    generateGifWithImages(images, downloadFileName, width, height);
    setIsDownloadingFile(false);
  }, [
    getMapBounds,
    videoLayers,
    mapRef,
    downloadFileName,
    replaceLayer,
    translate,
  ]);

  const mimeDialogOptions = useMemo(
    () => [
      {
        optionType: 'Raster',
        formats: [
          {
            name: 'PNG',
            onClick: async () => {
              setSavedMime('image/png');
              await downloadScreenshot('image/png');
            },
          },
          {
            name: 'JPEG',
            onClick: async () => {
              setSavedMime('image/jpeg');
              await downloadScreenshot('image/jpeg');
            },
          },
          {
            name: 'GIF',
            onClick: async () => {
              setSavedMime('image/gif');
              await downloadScreenshot('image/gif');
            },
          },
          {
            name: 'TIFF',
            onClick: async () => {
              setSavedMime('image/tiff');
              await downloadScreenshot('image/tiff');
            },
          },
          {
            name: 'GeoPDF',
            onClick: async () => {
              setSavedMime('application/pdf');
              await downloadScreenshot('application/pdf');
            },
          },
          {
            name: 'Video',
            onClick: async () => {
              setSavedMime('video/gif');
              await downloadLayersAsVideo();
            },
          },
        ],
      },
      {
        optionType: 'Vector',
        formats: [
          {
            name: 'GeoJSON',
          },
          {
            name: 'KML',
          },
          {
            name: 'KMZ',
          },
          {
            name: 'SHP',
          },
        ],
      },
    ],
    [downloadLayersAsVideo, downloadScreenshot, setSavedMime]
  );

  const renderViews: DialogListRenderer<ProviderView> = (item, select) => {
    if (loading) {
      return <Spinner />;
    }
    return (
      <div
        style={{
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        onClick={() => {
          select();
          setViewToDelete(item);
        }}
      >
        <span>{item.name}</span>
      </div>
    );
  };

  const viewDetailsRenderer: DialogItemDetailsRenderer<ProviderView> = (
    view,
    open
  ) => {
    return (
      <div className="flex flex-col grow h-full px-2 gap-4">
        <div className="flex flex-col gap-4">
          <h2 className="p-0 m-0">{view.name}</h2>

          <span>{view.description}</span>

          <img
            src={viewScreenshot}
            alt="View screenshot"
            className="max-h-52 h-full"
          />

          {/* TODO: Implement when available */}
          <span>
            {translate('datacosmos.viewsDialog.sharedWith', { with: 'n/a' })}
          </span>

          <span data-testid="datacosmos-view-panel-created-at">
            {translate('datacosmos.viewsDialog.createdAt', {
              at: moment(view.created_at).format(DATETIME_FORMAT),
            })}
          </span>
          <span data-testid="datacosmos-view-panel-updated-at">
            {translate('datacosmos.viewsDialog.lastUpdated', {
              at: moment(view.updated_at).format(DATETIME_FORMAT),
            })}
          </span>
        </div>

        <div className="flex items-center justify-evenly gap-2">
          <Button
            isMinimal
            iconPlacement="left"
            icon="Folder"
            onPress={() => {
              open(view);
              setSelectedView(undefined);
              sendInfo({
                type: ` Opened a view: ${view.id} - ${view.name} `,
                action: 'Click',
                item: 'View',
                module: 'DataCosmos',
                additionalParams: {
                  view: view,
                },
              });
            }}
          >
            {translate('datacosmos.viewsDialog.openView')}
          </Button>

          <Button
            isMinimal
            icon="Share"
            iconPlacement="left"
            onPress={() => {
              history.push(`/resource/view/${view.id}`);
            }}
          >
            {translate('datacosmos.viewsDialog.shareView')}
          </Button>

          <Button
            isMinimal
            icon="Trash"
            iconPlacement="left"
            onPress={() => {
              setIsDeleteDialogOpen(true);
            }}
          >
            {translate('datacosmos.viewsDialog.deleteView')}
          </Button>
        </div>
      </div>
    );
  };

  const handleViewOpen = (view: ProviderView) => {
    sendInfo({
      type: `View select: ${view.id} - ${view.name} `,
      action: 'Click',
      item: 'View',
      module: 'DataCosmos',
      additionalParams: {
        project: view,
      },
    });
    setCurrentView(view);
    handleViewChange(view);
    setIsOpen(false);
    history.push(`${location.pathname}?view=${view.id}`);
  };

  const returnToBlankView = () => {
    sendInfo({
      type: 'Free edit mode select',
      action: 'Click',
      item: 'Free edit mode',
      module: 'DataCosmos',
    });
    initUntitledView();
    setIsOpen(false);
    history.push(`${location.pathname}`);
  };

  const resetUploadFileRef = () => {
    if (uploadFileRef.current?.value) {
      uploadFileRef.current.value = '';
    }
    setIsLocationDetailsOpen(false);
  };

  const rasterFormat =
    savedMime === 'video/gif'
      ? '.gif (Animated)'
      : screenshotMimeToExtension(savedMime);

  const handleUploadFileToProject = async (
    uploadedFiles: FileList,
    location?: string
  ) => {
    const formData = new FormData();
    for (const file of uploadedFiles) {
      formData.append('files', file);
    }
    if (location) {
      formData.set('location', location);
    }
    setIsUploadingFile(true);
    const { success } = await uploadFileToProject({
      params: { projectId: currentScenario?.id ?? '' },
      body: formData,
    });
    if (success) {
      await fetchCurrentProjectItems();
    }
    setIsUploadingFile(false);
    resetUploadFileRef();
  };

  const handleUploadFileToProjectWithLocation = async (coords: string) => {
    const files = uploadFileRef.current?.files;
    if (!files) {
      return;
    }
    await handleUploadFileToProject(files, coords);
  };

  return (
    <div
      style={{
        marginLeft: '5px',
        display: 'flex',
        gap: '0.7rem',
        alignItems: 'center',
      }}
    >
      <span
        className={classNames('text-base cursor-pointer', {
          'text-item-contrast dark:text-item-contrast cursor-default':
            isMinimal,
        })}
        data-testid="open-views-modal-button"
        onClick={() => {
          if (isMinimal) return;
          sendInfo({
            type: 'View selection open',
            action: 'Click',
            item: 'View',
            module: 'DataCosmos',
          });
          setIsOpen(true);
        }}
      >
        |{' '}
        {currentView?.id
          ? currentView.name
          : translate('datacosmos.header.freeEditingMode')}
      </span>
      {isSaveEnabled && !isMinimal && (
        <Popover2
          position="bottom"
          interactionKind="hover"
          content={
            <div className="p-2 flex items-center gap-2 dark:bg-surface-dark dark:text-item-dark-contrast ">
              {translate('datacosmos.tooltips.header.saveView')}
            </div>
          }
        >
          <Button
            icon={IconNames.FLOPPY_DISK}
            className="bg-transparent dark:bg-transparent"
            isMinimal
            size="base"
            onPress={() => {
              sendInfo({
                type: 'View saved',
                action: 'Click',
                item: 'View',
                module: 'DataCosmos',
              });
              currentView?.id
                ? setIsOverwriteDialogOpen(true)
                : setIsSaveDialogOpen(true);
            }}
            loading={isSavingView}
            iconClassName="!stroke-none"
          />
        </Popover2>
      )}

      {isDownloadingFile ? (
        <Spinner size={18} />
      ) : (
        <Popover2
          interactionKind="hover"
          position="bottom"
          disabled={layers.length === 0}
          content={
            <div className="p-2 flex items-center gap-2 dark:bg-surface-dark dark:text-item-dark-contrast ">
              <span>
                {translate('datacosmos.tooltips.header.downloadScreenshot', {
                  format:
                    vectorMimeTypes.includes(savedMime) &&
                    savedMime.includes('.')
                      ? savedMime?.substring(savedMime.lastIndexOf('.'))
                      : rasterFormat,
                })}
              </span>

              <PrimaryButton
                text={translate('datacosmos.optionsDialog.changeFormat')}
                onPress={() => setIsChangeFormatDialogOpen(true)}
              />
            </div>
          }
        >
          <Button
            icon={IconNames.CAMERA}
            className="bg-transparent dark:bg-transparent"
            isMinimal
            size="base"
            onPress={async () => {
              if (layers.length === 0) {
                return;
              }
              sendInfo({
                type: 'Screenshot taken',
                action: 'Click',
                item: 'Screenshot',
                module: 'DataCosmos',
                additionalParams: {
                  format: screenshotMimeToExtension(savedMime),
                },
              });
              if (savedMime === 'application/pdf') {
                setIsGeoPdfDetailsOpen(true);
                return;
              }
              if (savedMime === 'video/gif') {
                void downloadLayersAsVideo();
                return;
              }
              await downloadScreenshot();
            }}
            isDisabled={layers.length === 0}
            iconClassName="!stroke-none"
          />
        </Popover2>
      )}
      <Popover2
        interactionKind="hover"
        position="bottom"
        content={
          <div className="p-2 flex items-center gap-2 dark:bg-surface-dark dark:text-item-dark-contrast ">
            <span>
              {canWriteToProject
                ? translate('datacosmos.tooltips.header.uploadFile')
                : translate('datacosmos.tooltips.header.noUploadPermissions')}
            </span>
          </div>
        }
      >
        {isUploadingFile ? (
          <Spinner size={18} />
        ) : (
          <Button
            icon="Upload"
            className="bg-transparent dark:bg-transparent"
            isMinimal
            size="base"
            onPress={() => {
              uploadFileRef.current?.click();
            }}
            isDisabled={!canWriteToProject}
          />
        )}
      </Popover2>
      <DatacosmosSelectDialog<ProviderView>
        items={views.filter(
          (v) => currentScenario && v.project === currentScenario.id
        )}
        title={translate('datacosmos.viewsDialog.views')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        listRenderer={renderViews}
        listItemClickHandler={handleViewOpen}
        handleAddItemClick={returnToBlankView}
        viewAllTitle={translate('datacosmos.viewsDialog.showAllViews')}
        handleViewAllClick={() => history.push(`/data/views/`)}
        addItemTitle={translate(
          'datacosmos.viewsDialog.returnToFreeEditingMode'
        )}
        handleItemDetailsRender={viewDetailsRenderer}
        sortListBy="name"
        selectedItem={selectedView}
        setSelectedItem={(view) => {
          setSelectedView(view);
          sendInfo({
            type: `Selected a view: ${view?.id ?? 'no id'} - ${
              view?.name ?? 'no name'
            } `,
            action: 'Click',
            item: 'View',
            module: 'DataCosmos',
            additionalParams: {
              view: view,
            },
          });
        }}
        isContentLoading={loading}
      />
      <SaveViewDialog
        view={currentView}
        isOpen={isSaveDialogOpen}
        setIsOpen={setIsSaveDialogOpen}
        handleSave={async (name, desc) => {
          setIsSavingView(true);
          await handleViewSave(name, desc);
          setIsSavingView(false);
          sendInfo({
            type: `Save new view: ${name}`,
            action: 'Click',
            item: 'View',
            module: 'DataCosmos',
            additionalParams: {
              viewName: name,
              viewDescription: desc,
            },
          });
        }}
      />
      <SaveViewDialog
        view={currentView}
        isOpen={isOverwriteDialogOpen}
        setIsOpen={setIsOverwriteDialogOpen}
        handleSave={async (name, desc, id) => {
          setIsSavingView(true);
          if (id) await handleViewOverwrite(name, desc, id);
          setIsSavingView(false);
          setSelectedView(undefined);
          sendInfo({
            type: `Overwrite existing view; overwritten view ${
              id ?? 'unknown'
            } with view ${name} `,
            action: 'Click',
            item: 'View',
            module: 'DataCosmos',
            additionalParams: {
              viewName: name,
              viewDescription: desc,
            },
          });
        }}
        overwrite
      />
      <DeleteViewDialog
        view={viewToDelete}
        deleteHandler={async (viewId) => {
          await handleViewDelete(viewId);
          setSelectedView(undefined);
        }}
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
      />
      <OptionsDialog
        options={mimeDialogOptions}
        isOpen={isChangeFormatDialogOpen}
        setIsOpen={setIsChangeFormatDialogOpen}
        title={translate('datacosmos.optionsDialog.changeFormat')}
        showOptionsUnderTabs
        onClickHandler={async (formatName) => {
          setIsDownloadingFile(true);
          await downloadVectorScreenshot(formatName);
        }}
      />
      <input
        onChange={async (e) => {
          const uploadedFiles = e.target.files;

          if (!uploadedFiles?.length) {
            resetUploadFileRef();
            return;
          }

          if (!areAllUploadedFilesGeoReferenced(uploadedFiles)) {
            setIsLocationDetailsOpen(true);
            return;
          }

          if (isENVIPartiallyUploaded(uploadedFiles)) {
            resetUploadFileRef();
            return;
          }

          if (isShpPartiallyUploaded(uploadedFiles)) {
            resetUploadFileRef();
            return;
          }

          await handleUploadFileToProject(uploadedFiles);
        }}
        ref={uploadFileRef}
        multiple
        type={'file'}
        style={{ display: 'none' }}
      />
      <GeoPDFDetails
        isOpen={isGeoPdfDetailsOpen}
        setIsOpen={setIsGeoPdfDetailsOpen}
      />
      <LocationDetailsDialog
        isOpen={isLocationDetailsOpen}
        setIsOpen={setIsLocationDetailsOpen}
        handleUploadWithLocation={(coords) =>
          handleUploadFileToProjectWithLocation(coords)
        }
        resetUploadFileRef={resetUploadFileRef}
        isUploading={isUploadingFile}
      />
    </div>
  );
};

export default Views;
