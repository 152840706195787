import { kml as toGeoJsonKml } from '@tmcw/togeojson';
import type { Geometry } from 'geojson';
import isClockwise from '@turf/boolean-clockwise';
import {
  GeoPackageAPI,
  setSqljsWasmLocateFile,
  type BoundingBox,
} from '@ngageoint/geopackage';

// For the gpkg loading to work, loading a SQLite webasembly is required
import sqlWasm from '@ngageoint/geopackage/dist/sql-wasm.wasm?url';
import geojsonValidator from './geojsonValidator';

import * as shapefile from 'shapefile';

// This sets the location of the SQLite wasm for geopackage library to use
setSqljsWasmLocateFile(() => sqlWasm);

type ConverterOptions = {
  disableValidation?: boolean;
};

const reverseCoordinates = (coordsDataGeometry: GeoJSON.Feature) => {
  let coords: number[][] = (
    (coordsDataGeometry as GeoJSON.Feature).geometry as GeoJSON.Geometry & {
      coordinates: number[][][];
    }
  ).coordinates[0];
  if (isClockwise(coords)) {
    coords = coords.reverse();
  }
};

const convertCoordinatesToAntiClockwiseDir = (
  geo: GeoJSON.FeatureCollection | GeoJSON.Feature
) => {
  if (geo.type === 'FeatureCollection') {
    for (const item of geo.features) {
      reverseCoordinates(item);
    }
  } else {
    reverseCoordinates(geo);
  }
  return geo as GeoJSON.FeatureCollection;
};

/**
 * Parses .kml files into geojson feature collection object and validates parsed object
 * @param file FileReader result as ArrayBuffer or string
 * @param options Converter options
 * @returns GeoJSON feature collection object
 * @throws Invalid .kml file error
 * @throws Invalid geojson object error
 */
export const kmlToGeoJson = (
  file: string | ArrayBuffer | null,
  options?: ConverterOptions
): GeoJSON.FeatureCollection<Geometry | null> => {
  const parser = new DOMParser();
  let kml: Document;

  try {
    kml = parser.parseFromString(file as string, 'text/xml');
  } catch (error) {
    throw new Error('Invalid .kml file');
  }

  const geo = toGeoJsonKml(kml) as GeoJSON.FeatureCollection;
  const validatedGeo = convertCoordinatesToAntiClockwiseDir(geo);
  if (!options?.disableValidation) {
    const validator = geojsonValidator(validatedGeo);
    validator.check();
  }
  return validatedGeo;
};

/**
 * Parses .gpkg files into geojson feature collection object and validates parsed object
 * @param file FileReader result as ArrayBuffer or string
 * @param options Converter options
 * @returns GeoJSON feature collection object
 * @throws Invalid geojson object error
 */
export const gpkgToGeoJson = async (
  file: string | ArrayBuffer | null,
  options?: ConverterOptions
): Promise<GeoJSON.FeatureCollection<Geometry | null>> => {
  const uint8 = new Uint8Array(file as ArrayBuffer);
  const gpkg = await GeoPackageAPI.open(uint8);

  const geo = gpkg
    .getFeatureTables()
    .map((tableName) => {
      // This currently works only for gpkg files containing GeoJSON features
      return gpkg.queryForGeoJSONFeaturesInTable(
        tableName,
        undefined as unknown as BoundingBox
      );
    })
    .flat();

  const featCol: GeoJSON.FeatureCollection = {
    features: geo,
    type: 'FeatureCollection',
  };

  const validatedGeo = convertCoordinatesToAntiClockwiseDir(featCol);
  if (!options?.disableValidation) {
    const validator = geojsonValidator(validatedGeo);
    validator.check();
  }
  return validatedGeo;
};

/**
 * Parses .geojson files into geojson feature collection object and validates parsed object
 * @param file FileReader result as ArrayBuffer or string
 * @param options Converter options
 * @returns GeoJSON feature collection object
 * @throws Invalid geojson object error
 */
export const geojsonToGeoJson = (
  file: string | ArrayBuffer | null,
  options?: ConverterOptions
) => {
  const geoJson = JSON.parse(file as string) as GeoJSON.FeatureCollection;
  const validatedGeo = convertCoordinatesToAntiClockwiseDir(geoJson);
  if (!options?.disableValidation) {
    const validator = geojsonValidator(validatedGeo);
    validator.check();
  }
  return validatedGeo;
};

/**
 * Parses .shp files into geojson feature collection object and validates parsed object
 * @param shp FileReader result as ArrayBuffer or string
 * @param options Converter options
 * @returns GeoJSON feature collection object
 * @throws Invalid .shp file error
 */
export const shapefileToGeoJson = async (
  shp: string | ArrayBuffer | null,
  options?: ConverterOptions
) => {
  if (!shp) {
    throw new Error('Invalid .shp file');
  }

  const geoJson = await shapefile.read(shp);
  const validatedGeo = convertCoordinatesToAntiClockwiseDir(geoJson);
  if (!options?.disableValidation) {
    const validator = geojsonValidator(validatedGeo);
    validator.check();
  }
  return validatedGeo;
};
