import { useMemo, useState } from 'react';
import UserListItem from './UserListItem';
import type {
  IRole,
  IUserWithPermissions,
} from 'services/api/portal/administration/api/types';
import { SearchInput, Spinner } from 'opencosmos-ui';
import { debounce } from 'lodash';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  availableRoles: IRole[];
  users: IUserWithPermissions[] | undefined;
  assignRole: (userId: string, roleIds: number[]) => void;
  deleteRoleAssignments: (
    userId: string,
    assignmentIds: number[],
    showDeletedMessage?: boolean
  ) => void;
  setUserSearchQuery: (query?: string) => void;
  userSearchQuery?: string;
  isFetching: boolean;
}

const AdministrationUsers = (props: IProps) => {
  const [showHiddenRoles, setShowHiddenRoles] = useState(false);

  const filteredRoles = useMemo(
    () =>
      props.availableRoles.filter((r) => showHiddenRoles || r.userAssignable),
    [props.availableRoles, showHiddenRoles]
  );

  const { translate } = useLocalisation();

  const debouncedOnChange = debounce((value: string | undefined): void => {
    props.setUserSearchQuery(value);
  }, 100);

  return (
    <div className="">
      <div className="w-full flex flex-col mb-6">
        <div className="flex justify-between mb-2">
          <div className="text-base">{translate('userPage.users')}</div>
          <label className="flex items-center">
            <input
              type="checkbox"
              className="mr-2"
              checked={showHiddenRoles}
              onChange={() => setShowHiddenRoles((p) => !p)}
            />
            <span>{translate('userPage.showHiddenRoles')}</span>
          </label>
        </div>

        <SearchInput
          fullWidth={true}
          onClear={() => {
            debouncedOnChange(undefined);
          }}
          onChange={(val) => {
            debouncedOnChange(val);
          }}
          value={props.userSearchQuery}
          placeholder={translate('userPage.searchPlaceholder')}
        />
      </div>
      <ul>
        {props.isFetching ? (
          <Spinner />
        ) : (
          props.users?.map((user) => (
            <UserListItem
              key={user.id}
              user={user}
              assignRole={props.assignRole}
              deleteRoleAssignments={props.deleteRoleAssignments}
              availableRoles={filteredRoles}
            />
          ))
        )}
      </ul>
    </div>
  );
};

export default AdministrationUsers;
