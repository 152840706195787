import { getAllTaskingRequests } from '_api/tasking/service';
import { useQuery } from '_api/useQuery';
import type {
  RequestType,
  TaskingRequest,
  PaginationMetadata,
  RequestStatus,
} from '_api/tasking/service';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useComposedState } from 'utils/hooks/useComposedState';
import type { User } from '_api/users/types';
import { useHistory } from 'react-router';
import moment from 'moment';
import { useAuth } from 'services/auth/AuthWrapper';
import { getScenarios } from '_api/scenarios/service';
import { useTaskingPermissions } from 'datacosmos/components/Tasking/RequestViewing/useTaskingPermissions';
import type { SatelliteId } from '_api/satellites/types';
import { getAOICoverageData } from 'datacosmos/utils/taskingRequests';
import { getSubject } from '_api/subjects/service';

interface AvailableOrganisation {
  id: number;
  name: string;
}
interface AvailableProject {
  id: string;
  name: string;
}
interface AvailableMission {
  id: SatelliteId;
  name: string;
}

export type ClassifiedRequests = {
  pending: TaskingRequest[];
  prepared: TaskingRequest[];
  acquiring: TaskingRequest[];
  fulfilled: TaskingRequest[];
  partiallyFulfilled: TaskingRequest[];
  failed: TaskingRequest[];
  cancelled: TaskingRequest[];
};

export type OverviewFilters = {
  missionId: string | undefined;
  availableMissionIds: AvailableMission[];
  fromDate: Date | undefined;
  toDate: Date | undefined;
  acquisitionStartDate: Date | undefined;
  acquisitionEndDate: Date | undefined;
  organisationId: string | undefined;
  availableOrganisations: AvailableOrganisation[];
  type: RequestType | undefined;
  projectId: string | undefined;
  availableProjects: AvailableProject[];
};

type RequestsMetaData = {
  [R in RequestStatus]?: PaginationMetadata | undefined;
};

const REQUEST_URL_PARAM_KEY = 'request';

export const useTaskingOverviewData = () => {
  const [selectedRequest, setSelectedRequest] = useState<TaskingRequest>();
  const history = useHistory();

  const [isAddActivityModalOpen, setIsAddActivityModalOpen] =
    useState<boolean>(false);

  const [isCancelRequestModalOpen, setIsCancelRequestModalOpen] =
    useState<boolean>(false);

  const [allTaskingRequestsMetadata, setAllTaskingRequestsMetadata] = useState<
    RequestsMetaData | undefined
  >(undefined);

  const [allTaskingRequests, setAllTaskingRequests] = useState<
    TaskingRequest[] | undefined
  >(undefined);

  const [shouldClearAllDateSegments, setShouldClearAllDateSegments] =
    useState<boolean>(false);

  const [isFiltersRequestLoading, setIsFiltersRequestLoading] =
    useState<boolean>(false);

  const { userOrganisations } = useAuth();
  const { taskingSatellites } = useTaskingPermissions();

  const { data: userScenarios } = useQuery(getScenarios, { initialData: [] });

  const [filter, setFilter, setFilterState] = useComposedState<OverviewFilters>(
    {
      type: undefined,
      fromDate: undefined,
      toDate: undefined,
      acquisitionStartDate: undefined,
      acquisitionEndDate: undefined,
      missionId: undefined,
      organisationId: undefined,
      projectId: undefined,
      availableMissionIds: [],
      availableOrganisations: [],
      availableProjects: [],
    }
  );

  const getRequestsByStatus = useCallback(
    async (status: RequestStatus, cursor?: string) => {
      const { data: requestsbyStatus } = await getAllTaskingRequests({
        params: {
          status: status,
          cursor: cursor,
          filters: filter,
        },
      });
      setAllTaskingRequests([
        ...(allTaskingRequests ?? []),
        ...(requestsbyStatus?.data ?? []),
      ]);

      setAllTaskingRequestsMetadata({
        ...allTaskingRequestsMetadata,
        [status]: requestsbyStatus?.meta,
      });
    },
    [allTaskingRequests, allTaskingRequestsMetadata]
  );

  const areDatesIncorrect = useCallback(
    (fromDate: Date | undefined, toDate: Date | undefined) => {
      if (fromDate && toDate) {
        if (fromDate > toDate) {
          return true;
        }
      }
      return false;
    },
    []
  );

  const {
    data: pendingRequests,
    loading: pendingLoading,
    refetch: pendingRefetch,
  } = useQuery(getAllTaskingRequests, {
    initialData: undefined,
    params: {
      status: 'PENDING',
    },
  });

  const {
    data: preparedRequests,
    loading: preparedLoading,
    refetch: preparedRefetch,
  } = useQuery(getAllTaskingRequests, {
    initialData: undefined,
    params: {
      status: 'PREPARED',
    },
  });

  const {
    data: acquiringRequests,
    loading: acquiringLoading,
    refetch: acquiringRefetch,
  } = useQuery(getAllTaskingRequests, {
    initialData: undefined,
    params: {
      status: 'ACQUIRING',
    },
  });

  const {
    data: fulfilledRequests,
    loading: fulfilledLoading,
    refetch: fulfilledRefetch,
  } = useQuery(getAllTaskingRequests, {
    initialData: undefined,
    params: {
      status: 'FULFILLED',
    },
  });

  const {
    data: partiallyFulfilledRequests,
    loading: partiallyFulfilledLoading,
    refetch: partiallyFulfilledRefetch,
  } = useQuery(getAllTaskingRequests, {
    initialData: undefined,
    params: {
      status: 'PARTIALLY_FULFILLED',
    },
  });

  const {
    data: failedRequests,
    loading: failedLoading,
    refetch: failedRefetch,
  } = useQuery(getAllTaskingRequests, {
    initialData: undefined,
    params: {
      status: 'FAILED',
    },
  });

  const {
    data: cancelledRequests,
    loading: cancelledLoading,
    refetch: cancelledRefetch,
  } = useQuery(getAllTaskingRequests, {
    initialData: undefined,
    params: {
      status: 'CANCELLED',
    },
  });

  const isQueryLoading =
    pendingLoading ||
    preparedLoading ||
    acquiringLoading ||
    fulfilledLoading ||
    partiallyFulfilledLoading ||
    failedLoading ||
    cancelledLoading;

  const getUserQuery = useQuery(getSubject, {
    params: selectedRequest
      ? { subject: selectedRequest.created_by }
      : undefined,
    skip: !selectedRequest,
    initialData: {} as User,
  });

  const clearAllFilters = () => {
    setFilterState((prev) => ({
      ...prev,
      fromDate: undefined,
      toDate: undefined,
      acquisitionStartDate: undefined,
      acquisitionEndDate: undefined,
      organisationId: undefined,
      missionId: undefined,
      type: undefined,
      projectId: undefined,
    }));
    void refetchAllTaskingRequests();
  };

  const refetchAllTaskingRequests = useCallback(async () => {
    setShouldClearAllDateSegments(true);
    await pendingRefetch();
    await preparedRefetch();
    await acquiringRefetch();
    await fulfilledRefetch();
    await partiallyFulfilledRefetch();
    await failedRefetch();
    await cancelledRefetch();
    setShouldClearAllDateSegments(false);
  }, [
    acquiringRefetch,
    cancelledRefetch,
    failedRefetch,
    fulfilledRefetch,
    partiallyFulfilledRefetch,
    pendingRefetch,
    preparedRefetch,
  ]);

  const fetchRequestsByFilter = useCallback(
    async (filter: OverviewFilters) => {
      if (areDatesIncorrect(filter.fromDate, filter.toDate)) {
        return;
      }

      if (
        areDatesIncorrect(
          filter.acquisitionStartDate,
          filter.acquisitionEndDate
        )
      ) {
        return;
      }
      const statuses: RequestStatus[] = [
        'PENDING',
        'PREPARED',
        'ACQUIRING',
        'PARTIALLY_FULFILLED',
        'FULFILLED',
        'FAILED',
        'CANCELLED',
      ];

      setIsFiltersRequestLoading(true);
      const tempAllRequests: TaskingRequest[] = [];
      const tempAllMetaData: RequestsMetaData = {};

      await Promise.all(
        statuses.map(async (status) => {
          const { data: requestData } = await getAllTaskingRequests({
            params: {
              status: status,
              filters: filter,
            },
          });

          tempAllRequests.push(...(requestData?.data || []));
          tempAllMetaData[status] = requestData?.meta;
          return {
            data: requestData?.data || [],
            metadata: requestData?.meta,
          };
        })
      );

      setAllTaskingRequests(tempAllRequests);
      setAllTaskingRequestsMetadata(tempAllMetaData);
      setIsFiltersRequestLoading(false);
    },
    [areDatesIncorrect, getAllTaskingRequests]
  );

  useEffect(() => {
    const mergedTaskingRequests = [
      ...(pendingRequests?.data ?? []),
      ...(preparedRequests?.data ?? []),
      ...(acquiringRequests?.data ?? []),
      ...(fulfilledRequests?.data ?? []),
      ...(partiallyFulfilledRequests?.data ?? []),
      ...(failedRequests?.data ?? []),
      ...(cancelledRequests?.data ?? []),
    ];
    setAllTaskingRequests(mergedTaskingRequests);
    const mergedTaskingRequestsMetadata = {
      PENDING: pendingRequests?.meta,
      PREPARED: preparedRequests?.meta,
      ACQUIRING: acquiringRequests?.meta,
      FULFILLED: fulfilledRequests?.meta,
      PARTIALLY_FULFILLED: partiallyFulfilledRequests?.meta,
      FAILED: failedRequests?.meta,
      CANCELLED: cancelledRequests?.meta,
    };

    setAllTaskingRequestsMetadata(mergedTaskingRequestsMetadata);
  }, [
    pendingRequests,
    preparedRequests,
    acquiringRequests,
    fulfilledRequests,
    partiallyFulfilledRequests,
    failedRequests,
    cancelledRequests,
  ]);

  const selectRequestByItsURLId = useCallback(() => {
    if (isQueryLoading) return;
    if (isFiltersRequestLoading) return;
    if (allTaskingRequests?.length === 0) return;

    const urlReqId = new URLSearchParams(history.location.search).get(
      REQUEST_URL_PARAM_KEY
    );

    if (!urlReqId) return;

    setSelectedRequest(allTaskingRequests?.find((req) => req.id === urlReqId));
  }, [
    history.location.search,
    allTaskingRequests,
    isQueryLoading,
    isFiltersRequestLoading,
  ]);

  const addTaskingRequestIdToURL = useCallback(
    (reqId: string) => {
      history.replace(
        `${history.location.pathname}?${REQUEST_URL_PARAM_KEY}=${reqId}`
      );
    },
    [history]
  );

  const removeTaskingRequestIdFromURL = useCallback(() => {
    history.replace(history.location.pathname);
  }, [history]);

  const handleTaskingRequestClick = useCallback(
    (req: TaskingRequest) => {
      const wasDeselected = selectedRequest?.id === req.id;

      if (wasDeselected) {
        removeTaskingRequestIdFromURL();
        setSelectedRequest(undefined);
        return;
      }

      addTaskingRequestIdToURL(req.id);
      setSelectedRequest(req);
    },
    [
      addTaskingRequestIdToURL,
      removeTaskingRequestIdFromURL,
      selectedRequest?.id,
    ]
  );

  const classifiedRequests = useMemo(
    () =>
      (allTaskingRequests! as TaskingRequest[])?.reduce(
        (acc, request) => {
          if (request.status === 'ACQUIRING')
            acc = { ...acc, acquiring: [...acc.acquiring, request] };
          if (request.status === 'CANCELLED')
            acc = { ...acc, cancelled: [...acc.cancelled, request] };
          if (request.status === 'PREPARED')
            acc = { ...acc, prepared: [...acc.prepared, request] };
          if (request.status === 'FAILED')
            acc = { ...acc, failed: [...acc.failed, request] };
          if (request.status === 'PENDING')
            acc = { ...acc, pending: [...acc.pending, request] };
          if (request.status === 'FULFILLED')
            acc = { ...acc, fulfilled: [...acc.fulfilled, request] };
          if (request.status === 'PARTIALLY_FULFILLED')
            acc = {
              ...acc,
              partiallyFulfilled: [...acc.partiallyFulfilled, request],
            };

          return acc;
        },
        {
          acquiring: [],
          cancelled: [],
          prepared: [],
          failed: [],
          pending: [],
          fulfilled: [],
          partiallyFulfilled: [],
        } as ClassifiedRequests
      ),
    [allTaskingRequests]
  );

  //Todo: use this after select supports multiple selection
  // const handleOrganisationIdFilterClick = useCallback(
  //   (organisationId: string) => {
  //     if (filter.organisationIds.includes(organisationId)) {
  //       setFilter.organisationIds(
  //         filter.organisationIds.filter((id) => id !== organisationId)
  //       );
  //     } else {
  //       setFilter.organisationIds([...filter.organisationIds, organisationId]);
  //     }
  //   },
  //   [filter.organisationIds, setFilter]
  // );

  const handleOrganisationIdFilterClick = useCallback(
    (organisationId: string) => {
      const filters: OverviewFilters = {
        ...filter,
        organisationId:
          filter.organisationId !== organisationId ? organisationId : undefined,
      };
      fetchRequestsByFilter(filters);
      if (filter.organisationId === organisationId) {
        setFilter.organisationId(undefined);
      } else {
        setFilter.organisationId(organisationId);
      }
    },
    [filter.organisationId, setFilter]
  );

  const handleCreationDateFilterClick = useCallback(
    (creationDate: [Date, Date]) => {
      const fromCreationDate = creationDate[0];
      const toCreationDate = creationDate[1];

      if (filter.fromDate === fromCreationDate) {
        setFilter.fromDate(undefined);
      } else {
        setFilter.fromDate(fromCreationDate);
      }
      if (filter.toDate === toCreationDate) {
        setFilter.toDate(undefined);
      } else {
        setFilter.toDate(toCreationDate);
      }
      const filters: OverviewFilters = {
        ...filter,
        fromDate:
          fromCreationDate && filter.fromDate !== fromCreationDate
            ? fromCreationDate
            : undefined,
        toDate:
          toCreationDate && filter.toDate !== toCreationDate
            ? toCreationDate
            : undefined,
      };
      fetchRequestsByFilter(filters);
    },
    [filter.fromDate, filter.toDate, setFilter]
  );

  const handleAcquisitionDateFilterClick = useCallback(
    (acqDate: [Date, Date]) => {
      const fromAcqDate = acqDate[0];
      const toAcqDate = acqDate[1];
      if (filter.acquisitionStartDate === fromAcqDate) {
        setFilter.acquisitionStartDate(undefined);
      } else {
        setFilter.acquisitionStartDate(fromAcqDate);
      }

      if (filter.acquisitionEndDate === toAcqDate) {
        setFilter.acquisitionEndDate(undefined);
      } else {
        setFilter.acquisitionEndDate(toAcqDate);
      }

      const filters: OverviewFilters = {
        ...filter,
        acquisitionStartDate:
          fromAcqDate && filter.acquisitionStartDate !== fromAcqDate
            ? fromAcqDate
            : undefined,
        acquisitionEndDate:
          toAcqDate && filter.acquisitionEndDate !== toAcqDate
            ? toAcqDate
            : undefined,
      };

      fetchRequestsByFilter(filters);
    },
    [filter.acquisitionStartDate, filter.acquisitionEndDate, setFilter]
  );

  //Todo: use this after select supports multiple selection
  // const handleMissionIdFilterClick = useCallback(
  //   (missionId: string) => {
  //     if (filter.missionIds.includes(missionId)) {
  //       setFilter.missionIds(
  //         filter.missionIds.filter((id) => id !== missionId)
  //       );
  //     } else {
  //       setFilter.missionIds([...filter.missionIds, missionId]);
  //     }
  //   },
  //   [filter.missionIds, setFilter]
  // );

  const handleMissionIdFilterClick = useCallback(
    (missionId: string) => {
      const filters: OverviewFilters = {
        ...filter,
        missionId: filter.missionId !== missionId ? missionId : undefined,
      };
      fetchRequestsByFilter(filters);
      if (filter.missionId === missionId) {
        setFilter.missionId(undefined);
      } else {
        setFilter.missionId(missionId);
      }
    },
    [filter.missionId, setFilter]
  );

  const handleTypeFilterClick = useCallback(
    (type: string | undefined) => {
      const filters: OverviewFilters = {
        ...filter,
        type: filter.type !== type ? (type as RequestType) : undefined,
      };
      fetchRequestsByFilter(filters);
      if (filter.type === type) {
        setFilter.type(undefined);
      } else {
        setFilter.type(type as RequestType);
      }
    },
    [filter.type, setFilter]
  );

  const handleProjectIdFilterClick = useCallback(
    (projectId: string) => {
      const filters: OverviewFilters = {
        ...filter,
        projectId: filter.projectId !== projectId ? projectId : undefined,
      };
      fetchRequestsByFilter(filters);
      if (filter.projectId === projectId) {
        setFilter.projectId(undefined);
      } else {
        setFilter.projectId(projectId);
      }
    },
    [filter.projectId, setFilter]
  );

  useEffect(() => {
    selectRequestByItsURLId();
    if (
      !taskingSatellites?.data?.length &&
      !userOrganisations?.length &&
      !userScenarios?.length
    ) {
      return;
    }
    setFilterState((prev) => ({
      ...prev,
      availableMissionIds: [
        ...new Map(
          taskingSatellites?.data.map((r) => [
            r.mission_id,
            { id: r.mission_id, name: r.name },
          ])
        ).values(),
      ],
      availableOrganisations: [
        ...new Map(
          userOrganisations.map((r) => [r.id, { id: r.id, name: r.name }])
        ).values(),
      ],
      availableProjects: [
        ...new Map(
          userScenarios.map((r) => [r.id, { id: r.id, name: r.title }])
        ).values(),
      ],
    }));
  }, [
    selectRequestByItsURLId,
    setFilterState,
    taskingSatellites?.data,
    userOrganisations,
    userScenarios,
  ]);

  const disableAddActivity = useMemo(() => {
    if (selectedRequest?.constraints) {
      const endDate = selectedRequest.constraints.find(
        (aqDate) => aqDate.type === 'ACQUISITION_DATE'
      )?.max;

      return endDate
        ? moment(new Date()).add(48, 'hours').startOf('day').toDate() >
            moment.unix(endDate as number).toDate()
        : true;
    }
    return false;
  }, [selectedRequest]);

  const disableCancelRequest = useMemo(() => {
    return !selectedRequest?.is_cancellable;
  }, [selectedRequest]);

  const selectedRequestSwathParameters = useMemo(() => {
    if (!selectedRequest) {
      return;
    }

    if (!selectedRequest.activities.length) {
      return;
    }

    return getAOICoverageData(selectedRequest);
  }, [selectedRequest]);

  return {
    classifiedRequests,
    loading: isFiltersRequestLoading || isQueryLoading,
    userLoading: getUserQuery.loading,
    handleTaskingRequestClick,
    selectedRequest,
    setFilter,
    filter,
    handleMissionIdFilterClick,
    handleOrganisationIdFilterClick,
    handleTypeFilterClick,
    handleProjectIdFilterClick,
    customer: getUserQuery.data,
    isAddActivityModalOpen,
    setIsAddActivityModalOpen,
    refetchTaskingRequests: refetchAllTaskingRequests,
    disableAddActivity,
    isCancelRequestModalOpen,
    setIsCancelRequestModalOpen,
    disableCancelRequest,
    allTaskingRequestsMetadata,
    getRequestsByStatus,
    selectedRequestSwathParameters,
    shouldClearAllDateSegments,
    clearAllFilters,
    setShouldClearAllDateSegments,
    fetchRequestsByFilter,
    handleCreationDateFilterClick,
    handleAcquisitionDateFilterClick,
  };
};
