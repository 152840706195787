import circle from '@turf/circle';
import { convertToEPSG4326 } from 'datacosmos/utils/coordinates/coordinateConverter';
import {
  Dialog,
  Icon,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Tooltip,
} from 'opencosmos-ui';
import { useEffect, useState } from 'react';
import { toaster } from 'toaster';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import CSVForm, { type CSVOptions, initialCsvOptions } from './CSVForm';
import type { Geometry } from 'geojson';
import { parseCsvString } from './utils';

type CSVDialogProps = {
  files: { fileName: string; content: string }[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (files: GeoJSON.FeatureCollection<Geometry>[]) => void;
};

const initialAllOptions = (files: { fileName: string; content: string }[]) =>
  files.map((f) => ({
    fileName: f.fileName,
    options: initialCsvOptions,
  }));

// Normalize number to use dot as decimal separator
const normalizeNumber = (value: string) => {
  if (!value) return value;
  return value.replace(',', '.');
};

const CSVDialog = ({ files, isOpen, onClose, onSubmit }: CSVDialogProps) => {
  const { translate } = useLocalisation();
  const [allOptions, setAllOptions] = useState(initialAllOptions(files));

  useEffect(() => {
    setAllOptions(initialAllOptions(files));
  }, [files]);

  const handleClose = () => {
    onClose();
    setAllOptions(initialAllOptions(files));
  };

  const processFile = (fileContent: string[][], fileOptions: CSVOptions) => {
    const { latColumn, lonColumn, sizeColumn, radius, hasHeader } = fileOptions;
    const circleRows = hasHeader ? fileContent.slice(1) : fileContent;
    let circles = [];
    try {
      circles = circleRows.map((row) => {
        let lat = parseFloat(normalizeNumber(row[latColumn! - 1]));
        let lon = parseFloat(normalizeNumber(row[lonColumn! - 1]));
        if (fileOptions.crs.code !== 'EPSG:4326') {
          [lon, lat] = convertToEPSG4326([{ lat, lng: lon }], fileOptions.crs);
        }
        const size =
          sizeColumn && sizeColumn !== 'FIXED'
            ? parseFloat(normalizeNumber(row[sizeColumn - 1]))
            : radius;
        return circle([lon, lat], Number(size), { units: 'kilometers' });
      });
    } catch (e) {
      toaster.show({
        message: translate('datacosmos.uploadRegion.csv.processError'),
        icon: 'error',
        intent: 'danger',
      });
      return;
    }
    const geoJson: GeoJSON.FeatureCollection<Geometry> = {
      type: 'FeatureCollection',
      features: circles,
    };
    onSubmit([geoJson]);
  };

  const handleSubmit = () => {
    files.map((file) => {
      const { fileName, content } = file;
      const fileOptions = allOptions.find(
        (f) => f.fileName === fileName
      )?.options;
      if (!fileOptions) return;
      const fileContent = parseCsvString(content, fileOptions.delimiter);
      processFile(fileContent, fileOptions);
    });
  };

  const isIncompleteOptions = (options: CSVOptions) => {
    return (
      !options.latColumn ||
      !options.lonColumn ||
      !(options.sizeColumn ?? options.radius)
    );
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={translate('datacosmos.uploadRegion.csv.title')}
      buttons={[
        {
          text: translate('datacosmos.uploadRegion.csv.import'),
          onPress: () => {
            handleSubmit();
            handleClose();
          },
          shown: true,
          icon: 'Upload',
          intent: 'primary',
          isDisabled: allOptions.some(
            (o) =>
              !o.options.latColumn ||
              !o.options.lonColumn ||
              !(o.options.sizeColumn ?? o.options.radius)
          ),
        },
      ]}
    >
      <>
        <span className="flex mb-4">
          {translate('datacosmos.uploadRegion.csv.description')}
        </span>
        {allOptions.length > 1 ? (
          <Tabs className="gap-2">
            <TabList className="border-b-2">
              {allOptions.map((o, i) => (
                <Tab key={o.fileName} id={`${o.fileName}-${i}`}>
                  <span>{o.fileName}</span>
                  {isIncompleteOptions(o.options) && (
                    <Tooltip
                      content={translate(
                        'datacosmos.uploadRegion.csv.missingOptions'
                      )}
                    >
                      <Icon
                        icon="warning-sign"
                        className="fill-warning !stroke-none"
                      />
                    </Tooltip>
                  )}
                </Tab>
              ))}
            </TabList>
            {allOptions.map(({ options, fileName }, i) => {
              return (
                <TabPanel key={fileName} id={`${fileName}-${i}`}>
                  <CSVForm
                    options={options}
                    onOptionsChange={(newOptions: CSVOptions) => {
                      setAllOptions((prev) =>
                        prev.map((o) =>
                          o.fileName === fileName
                            ? { fileName: o.fileName, options: newOptions }
                            : o
                        )
                      );
                    }}
                    content={
                      files.find((f) => f.fileName === fileName)?.content ?? ''
                    }
                  />
                </TabPanel>
              );
            })}
          </Tabs>
        ) : (
          <CSVForm
            options={allOptions[0]?.options}
            onOptionsChange={(newOptions: CSVOptions) =>
              setAllOptions([
                { fileName: allOptions[0].fileName, options: newOptions },
              ])
            }
            content={files[0]?.content}
          />
        )}
      </>
    </Dialog>
  );
};

export default CSVDialog;
