import type { IScenarioApiSearch } from 'datacosmos/types/stac-types';
import { StacItem } from 'datacosmos/types/stac-types';
import type { IFilter } from 'datacosmos/services/imageCatalogApi';
import { makeQuery } from 'datacosmos/services/imageCatalogApi';
import type { SearchScenariosData } from '_api/scenarios/service';
import { searchScenarios } from '_api/scenarios/service';
import type { STACItem } from '_api/stac/types';

export class APIError extends Error {
  status: number | undefined;
  constructor(status: number | undefined, messages: string[]) {
    super(messages.join('; '));
    this.status = status;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Provides access to the scenario service API
 */
export class ScenarioApi {
  token: string | undefined;

  constructor(token: string | undefined) {
    this.token = token;
  }

  async fetchImages(
    scenarioId: string,
    filters: IFilter,
    cursor?: string,
    limit?: number,
    intersects?: GeoJSON.Polygon[],
    collections?: string[] | undefined,
    ids?: string[] | undefined
  ) {
    const query = makeQuery(filters);

    let requestBody;

    if (intersects && intersects.length > 0) {
      requestBody = {
        query,
        limit,
        intersects: intersects[0],
        collections,
        ids: filters.itemIdFilter ? [filters.itemIdFilter] : ids,
      };
    } else {
      requestBody = {
        query,
        limit,
        collections,
        ids: filters.itemIdFilter ? [filters.itemIdFilter] : ids,
      };
    }

    const response = await searchScenarios({
      params: {
        scenarioId,
        cursor,
      },
      body: requestBody,
      abortable: false,
    });

    if (response.success) {
      return response.data ? fromAPIResponse(response.data) : undefined;
    }
    throw new APIError(response.status, response.errors);
  }
}

const fromAPIFeature = (f: STACItem): StacItem => {
  return new StacItem({
    id: f.id,
    collection: f.collection,
    stac_version: f.stac_version ?? '',
    stac_extensions: f.stac_extensions ?? [],
    bbox: f.bbox,
    type: f.type,
    links: f.links,
    assets: f.assets,
    geometry: f.geometry,
    properties: f.properties,
  });
};

const fromAPIResponse = (r: SearchScenariosData): IScenarioApiSearch => {
  return {
    features: r.features.map((f: STACItem) => fromAPIFeature(f)),
    type: r.type,
    numberMatched: r.numberMatched ?? r.context?.matched,
    numberReturned: r.numberReturned ?? r.context?.returned,
    links: r.links,
  };
};
