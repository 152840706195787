import { useEffect } from 'react';
import { useMap } from 'datacosmos/stores/MapProvider';
import { useViewMode } from 'datacosmos/utils/hooks/useViewMode';
import BottomBar from 'datacosmos/components/BottomBar/BottomBar';
import 'leaflet';
import 'leaflet-draw/dist/leaflet.draw';
import { zIndexValues } from 'opencosmos-ui';

type MapProps = {
  leftMenuOpen: boolean;
  rightMenuOpen: boolean;
  isMinimapShown: boolean;
};

export const Map = ({
  leftMenuOpen,
  rightMenuOpen,
  isMinimapShown,
}: MapProps) => {
  const { isMinimal } = useViewMode();
  const map = useMap();
  const { initialiseMap } = map;

  useEffect(() => {
    initialiseMap();
  }, [initialiseMap]);

  const closedSize = isMinimal ? '0px' : '60px';

  return (
    <div
      id="datacosmos-map"
      ref={map.mapContainerRef}
      data-testid="datacosmos-map"
      style={{
        position: 'fixed',
        left: leftMenuOpen ? '445px' : closedSize,
        right: rightMenuOpen ? '445px' : closedSize,
      }}
    >
      <div
        className="absolute bottom-0 w-full"
        style={{ zIndex: zIndexValues.footer }}
      >
        <div>
          <BottomBar map={map} isMinimapExpanded={isMinimapShown} />
        </div>
      </div>
    </div>
  );
};
