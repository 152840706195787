import type { Key } from 'react-aria-components';
import { ComboBox, ComboBoxItem } from 'opencosmos-ui';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface ListFilterProps {
  label: string;
  selectedKey: string;
  onSelectionChange: (key: Key | null) => void;
  items: { id: string | number; name: string }[];
  testid: string;
  itemTestIdPrefix: string;
  containerElement?: HTMLElement;
  optionsGender?: 'male' | 'female' | 'nonbinary';
}

const ListFilter = ({
  label,
  selectedKey,
  onSelectionChange,
  items,
  testid,
  itemTestIdPrefix,
  containerElement,
  optionsGender,
}: ListFilterProps) => {
  const { translate } = useLocalisation();
  return (
    <ComboBox
      label={label}
      selectedKey={selectedKey}
      onSelectionChange={(val) => val && onSelectionChange(val)}
      items={items}
      testid={testid}
      fill
      popoverProps={{
        UNSTABLE_portalContainer: containerElement as Element,
      }}
    >
      <ComboBoxItem id={'ALL'} key={'ALL'}>
        {translate('datacosmos.tasking.existing.filters.all', {
          context: optionsGender,
        })}
      </ComboBoxItem>
      {items
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ id, name }) => (
          <ComboBoxItem
            id={id}
            key={id}
            data-testid={`${itemTestIdPrefix}-${id}`}
          >
            {name}
          </ComboBoxItem>
        ))}
    </ComboBox>
  );
};

export default ListFilter;
