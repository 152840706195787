import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import type { IApplication } from 'datacosmos/types/applications';
import React, { useEffect, useMemo } from 'react';
import SidebarHeader from '_organisms/SidebarHeader/SidebarHeader';

interface IProps {
  app: IApplication;
}

const InstalledAppIndex = ({ app }: IProps) => {
  const {
    setSelectedInstalledApp,
    applicationList,
    installedApps,
    setShouldAutoOpen,
  } = useApplicationCatalog();

  const installedApp = useMemo(
    () => applicationList.find((a) => a.id === app.id),
    [applicationList, app.id]
  );

  const appIndex = useMemo(
    () => installedApps.findIndex((a) => a.id === app.id),
    [app.id, installedApps]
  );

  useEffect(() => {
    // This makes uninstalling apps from their own menu less annoying by automatically switching to the previous one
    // in the array, except when uninstalling the last one remaining, in which case it would close the sidemenu
    if (!installedApps.find((a) => a.id === app.id)) {
      setSelectedInstalledApp(
        installedApps.length !== 0
          ? installedApps[appIndex > 0 ? appIndex - 1 : appIndex]
          : undefined
      );
    }
  }, [app.id, appIndex, installedApps, setSelectedInstalledApp]);

  useEffect(() => {
    setShouldAutoOpen(true);
    return () => {
      setShouldAutoOpen(false);
    };
  }, [installedApp, setShouldAutoOpen]);
  return (
    <div>
      <SidebarHeader
        title={app.name}
        rightIcon={{
          iconName: 'ChevronRightDouble',
          onIconClick: () => setSelectedInstalledApp(undefined),
        }}
      />

      <div
        id="datacosmos-applications-sidebar-body"
        className={'overflow-y-auto'}
      >
        {installedApp?.renderer(installedApp)}
      </div>
    </div>
  );
};

export default InstalledAppIndex;
