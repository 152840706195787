import type { GroupedNavItems } from 'opencosmos-ui';

/**
 * getSelectedNavItem is a helper function that returns the selected navigation item key based on the current url
 * @param navItems provided navigation items to search for the selected item
 * @param url url that has the key of the selected item
 * @returns the key of the selected item if found, undefined otherwise
 */
export const getSelectedNavItem = (navItems: GroupedNavItems, url: string) => {
  return Object.values(navItems)
    .map((ni) => ni?.items.map((i) => i.key))
    .find((ks) => ks?.some((k) => url.includes(k)))
    ?.find((k) => url.includes(k));
};
