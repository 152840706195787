import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import type {
  TaskingRequest,
  TaskingRequestConstraint,
  TaskingSatellite,
} from '_api/tasking/service';
import {
  postSwathSearch,
  patchTaskingRequest,
  getTaskingRequest,
} from '_api/tasking/service';
import {
  getRegionOfInterestCoverageArea,
  getRegionOfInterestCoveragePercent,
} from 'datacosmos/utils/geojson';
import { SwathControl } from '_organisms/SwathControl/SwathControl';
import { useSwathControlData } from 'datacosmos/utils/hooks/useSwathControlData';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import type {
  AoiLayersDisplayed,
  RemoveSwathEdits,
  SwathLayersDisplayed,
  ToggleDisplayActivitySwathOnMap,
  ToggleDisplayAoIOnMap,
  UpdateSwathLayer,
} from './useTaskingRequestList';
import type { ICheckPermissions } from 'services/auth/useAuthorisation';
import { useHistory, useRouteMatch } from 'react-router';
import {
  Button,
  Icon,
  Menu,
  MenuTrigger,
  Popover,
  MenuItem,
  Dialog,
  Tooltip,
} from 'opencosmos-ui';
import type { IconName } from 'opencosmos-ui';
import useCheckPermissions from 'utils/hooks/useCheckPermissions';
import AdvancedParametersInfo from './AdvancedParametersInfo';
import type { Activity } from '_api/activities/types';
import {
  dateAsDisplayFormat,
  jsDateToMomentObj,
  momentObjToUnixMs,
} from 'utils/common/dateUtils';
import classNames from 'classnames';

type IProps = {
  activity: Activity;
  request: TaskingRequest;
  toggleDisplayAoIOnMap: ToggleDisplayAoIOnMap;
  toggleDisplayActivitySwathOnMap: ToggleDisplayActivitySwathOnMap;
  shownAoiLayer?: AoiLayersDisplayed[0];
  shownActivitySwathsLayer?: SwathLayersDisplayed[0];
  updateSwathLayer: UpdateSwathLayer;
  removeSwathEdits: RemoveSwathEdits;
  refetchRequests: () => Promise<void>;
  taskingSatellites?: TaskingSatellite[];
};

const getDuration = (activity: Activity) => {
  const diff = moment.utc(moment(activity.end_date).diff(activity.start_date));

  if (diff.minutes() > 0) {
    return moment
      .utc(moment(activity.end_date).diff(activity.start_date))
      .format('mm[m] ss[s]');
  }

  return moment
    .utc(moment(activity.end_date).diff(activity.start_date))
    .format('ss[s]');
};

const ActivityCard = ({
  activity,
  request,
  toggleDisplayAoIOnMap,
  toggleDisplayActivitySwathOnMap,
  shownAoiLayer,
  shownActivitySwathsLayer,
  updateSwathLayer,
  removeSwathEdits,
  refetchRequests,
  taskingSatellites,
}: IProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isUpdatingActivity, setIsUpdatingActivity] = useState<boolean>(false);
  const [
    isOpenCancelActivityConfirmation,
    setIsOpenCancelActivityConfirmation,
  ] = useState<boolean>(false);

  const { hasPermission: isAllowedToEditTaskingRequest } = useCheckPermissions({
    permissions: [
      {
        id: activity.mission_id,
        type: 'mission',
        actionScope:
          'data:tasking:request:update:activities:parameters:platform:roll_angle',
      },
      {
        id: activity.mission_id,
        type: 'mission',
        actionScope: 'data:tasking:request:update:activities:start_date',
      },
      {
        id: activity.mission_id,
        type: 'mission',
        actionScope: 'data:tasking:request:update:activities:end_date',
      },
    ] as ICheckPermissions[],
  });

  const {
    debouncedSetSwathData,
    swathControlData,
    resetSwathDataToDefault,
    setSwathControlData,
  } = useSwathControlData({
    area:
      request && activity.parameters.physical?.latest
        ? getRegionOfInterestCoverageArea(
            [activity.parameters.physical.latest.geojson],
            [request.region]
          )
        : 0,
    coverage:
      request && activity.parameters.physical?.latest
        ? getRegionOfInterestCoveragePercent(
            [activity.parameters.physical.latest.geojson],
            [request.region]
          )
        : 0,
    duration: {
      end: moment.utc(activity.end_date).toDate(),
      start: moment.utc(activity.start_date).toDate(),
    },
    rotation: activity.parameters?.platform?.roll_angle ?? 0,
    sza: activity.parameters.physical?.latest?.midpoint.sza_deg,
    oza: activity.parameters.physical?.latest?.midpoint.oza_deg,
    parameters: activity.parameters,
  });

  const isSwathShown = typeof shownActivitySwathsLayer !== 'undefined';
  const isAoIShown = typeof shownAoiLayer !== 'undefined';

  const { translate } = useLocalisation();
  const history = useHistory();

  const isInPast = useMemo<boolean>(() => {
    return moment(moment.now()).diff(activity.start_date) >= 0;
  }, [activity.start_date]);

  const urlMatch = useRouteMatch<{ projectId: string }>({
    path: '/data/project/:projectId',
    exact: false,
  });

  const projectId = urlMatch?.params.projectId;

  const disableEditButton =
    activity.status !== 'PENDING' ||
    isInPast ||
    !isAllowedToEditTaskingRequest.reduce((prev, current) => prev && current) ||
    request?.type === 'AUTOMATED';

  const disableCancelButton =
    !activity.is_cancellable ||
    (activity.status !== 'PENDING' && activity.status !== 'APPROVED');

  const showCloudCoverage =
    activity.parameters?.metrics?.weather_forecast?.forecast_cloud_cover_pc !==
      undefined &&
    activity.status !== 'COMPLETED' &&
    activity.status !== 'FAILED' &&
    activity.status !== 'EXPIRED' &&
    activity.status !== 'CANCELLED';

  const activityStacParameter = activity.parameters?.stac?.find(
    (a) =>
      a.processing_level ===
        request?.instruments?.find((r) => r.mission_id === activity.mission_id)
          ?.parameters?.ground_processing?.requested_processing_level &&
      !a.collection_id?.includes('--qa')
  );

  const enableSceneButton = Boolean(activityStacParameter);

  const [isOpen, setOpen] = useState(false);
  const [detailsPopoverContent, setDetailsPopoverContent] = useState<
    Activity | undefined
  >(undefined);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  const toggleDisplayActivityDetails = (clickedActivity: Activity) => {
    setDetailsPopoverContent(clickedActivity);
    setOpen(true);
  };

  interface IItemProps {
    text: string;
    icon?: IconName;
  }

  const TaskingOptionsItem = ({ text, icon }: IItemProps) => {
    return (
      <div className="flex gap-2 items-center">
        {icon && <Icon icon={icon} />}
        <span className="whitespace-nowrap">{text}</span>
      </div>
    );
  };

  const duration = useMemo(() => {
    return [
      momentObjToUnixMs(jsDateToMomentObj(swathControlData?.duration.start)),
      momentObjToUnixMs(jsDateToMomentObj(swathControlData?.duration.end)),
    ];
  }, [swathControlData?.duration.start, swathControlData?.duration.end]);

  const durationConstraint = useMemo(() => {
    let acquistionDuration: TaskingRequestConstraint = {
      min: Number(swathControlData?.duration.start),
      max: Number(swathControlData?.duration.end),
      type: 'ACQUISITION_DURATION',
    };
    taskingSatellites?.map((sat) => {
      if (sat.mission_id === activity.mission_id) {
        sat.payloads.map((payload) => {
          if (payload.name === activity.parameters.imager?.name) {
            payload.constraints.map((constraint) => {
              if (constraint.type === 'ACQUISITION_DURATION') {
                acquistionDuration = constraint;
              }
            });
          }
        });
      }
    });
    return acquistionDuration;
  }, [
    activity.mission_id,
    activity.parameters.imager?.name,
    swathControlData?.duration.start,
    swathControlData?.duration.end,
    taskingSatellites,
  ]);

  const isActivityDurationWithinLimits = useMemo(() => {
    const oppDurationInUTC = moment.utc(
      moment.unix(duration[1] / 1000).diff(moment.unix(duration[0] / 1000))
    );
    const oppDuration =
      Number(moment(oppDurationInUTC).minutes() * 60) +
      Number(moment(oppDurationInUTC).seconds());

    return durationConstraint.max
      ? oppDuration > durationConstraint.max
      : false;
  }, [duration, durationConstraint]);

  const durationLimitTooltipText = `The swath duration is not within operational limits, must be less than ${durationConstraint.max}s`;

  const taskingOptionsContent = (
    <Menu
      disabledKeys={[
        !enableSceneButton ? 'scene' : undefined,
        disableEditButton ? 'edit' : undefined,
        disableCancelButton ? 'cancel' : undefined,
      ].filter((a): a is string => typeof a !== 'undefined')}
      onAction={(action) => {
        switch (action) {
          case 'edit':
            if (!isAoIShown) {
              toggleDisplayAoIOnMap(request);
            }

            if (!isSwathShown) {
              toggleDisplayActivitySwathOnMap(activity);
            }
            setIsEditing(true);
            return;
          case 'details':
            toggleDisplayActivityDetails(activity);
            return;
          case 'swath':
            toggleDisplayActivitySwathOnMap(activity);
            return;
          case 'cancel':
            setIsOpenCancelActivityConfirmation(true);
            return;
        }
      }}
    >
      <MenuItem id={'details'}>
        <TaskingOptionsItem text={'Open details'} />
      </MenuItem>
      <MenuItem id={'edit'}>
        <TaskingOptionsItem text={'Edit'} icon={'Pencil'} />
      </MenuItem>
      <MenuItem
        id={'scene'}
        target="_blank"
        className={classNames({
          'pointer-events-none': !enableSceneButton,
        })}
        href={`/data/project/${projectId}/catalog?view-item=${activityStacParameter?.collection_id}%2F${activityStacParameter?.item_id}`}
      >
        <TaskingOptionsItem text={'Go to scene'} icon={'Image'} />
      </MenuItem>
      <MenuItem id={'swath'} data-testid="request-add-single-swath">
        <TaskingOptionsItem
          text={isSwathShown ? 'Hide swath' : 'Display swath'}
          icon={'SwathMultiple'}
        />
      </MenuItem>
      <MenuItem id={'cancel'}>
        <TaskingOptionsItem text={'Cancel'} />
      </MenuItem>
    </Menu>
  );

  return (
    <div className="bg-item dark:bg-item-dark flex text-xs items-center p-1 gap-1">
      <div className="flex flex-1 flex-col gap-2">
        <div ref={triggerRef} className="flex flex-col gap-2">
          <div className="flex items-center gap-12 border-b border-b-item-contrast-inactive dark:border-b-item-dark-contrast-inactive pb-2">
            <div className="flex flex-col gap-1">
              <TaskingOptionsItem
                text={dateAsDisplayFormat(activity.start_date)}
                icon={'Calendar'}
              />
              <Tooltip
                content={translate(
                  'datacosmos.tooltips.tasking.existing.activities.satellite'
                )}
              >
                <TaskingOptionsItem
                  text={`${activity.mission_name}`}
                  icon={'Satellite'}
                />
              </Tooltip>
            </div>

            <div className="flex flex-col gap-1">
              <Tooltip
                content={translate(
                  'datacosmos.tooltips.tasking.existing.activities.duration'
                )}
              >
                <TaskingOptionsItem
                  text={getDuration(activity)}
                  icon={'Clock'}
                />
              </Tooltip>
              <Tooltip
                content={translate(
                  'datacosmos.tooltips.tasking.existing.activities.imager'
                )}
              >
                <TaskingOptionsItem
                  text={`${activity.parameters?.imager?.name}`}
                  icon={'SensorType'}
                />
              </Tooltip>
            </div>
          </div>

          <div className="flex items-center justify-between text-[10px] gap-2">
            <div className="flex items-center gap-1">
              <Tooltip
                content={translate(
                  'datacosmos.tooltips.tasking.existing.activities.aoiCoverage'
                )}
              >
                <span className="flex items-center gap-2">
                  <Icon icon="Coverage" />
                  {request && activity.parameters.physical?.latest
                    ? getRegionOfInterestCoveragePercent(
                        [activity.parameters.physical?.latest.geojson],
                        [request.region]
                      )
                    : '- %'}
                  %
                </span>
              </Tooltip>
              <Tooltip
                content={translate(
                  'datacosmos.tooltips.tasking.existing.activities.oza'
                )}
              >
                <span className="flex items-center gap-1">
                  <Icon icon="Angle" />
                  {swathControlData.oza?.toFixed(2) ??
                    activity.parameters.physical?.latest?.midpoint?.oza_deg?.toFixed(
                      2
                    )}
                  °
                </span>
              </Tooltip>
              <Tooltip
                content={translate(
                  'datacosmos.tooltips.tasking.existing.activities.sza'
                )}
              >
                <span className="flex items-center gap-1">
                  <Icon icon="SunAngle" />
                  {swathControlData.sza?.toFixed(2) ??
                    activity.parameters.physical?.latest?.midpoint?.sza_deg?.toFixed(
                      2
                    )}
                  °
                </span>
              </Tooltip>
              <Tooltip
                content={translate(
                  'datacosmos.tooltips.tasking.existing.activities.rollAngle'
                )}
              >
                <span className="flex items-center gap-1">
                  <Icon icon="SatelliteRollAngle" />
                  {swathControlData?.rotation?.toFixed(2)}°
                </span>
              </Tooltip>

              <Tooltip
                content={translate(
                  'datacosmos.tooltips.tasking.existing.activities.cloudCoverage'
                )}
              >
                <span className="flex items-center gap-1">
                  <Icon icon="Cloud" />
                  {showCloudCoverage &&
                  activity.parameters?.metrics?.weather_forecast
                    ?.forecast_cloud_cover_pc !== undefined
                    ? `${activity.parameters?.metrics?.weather_forecast?.forecast_cloud_cover_pc}%`
                    : 'N/A'}
                </span>
              </Tooltip>
            </div>
            <Tooltip
              content={translate(
                `datacosmos.tasking.existing.activityStatus.${activity.status}.description`
              )}
            >
              <div className="flex items-center gap-1">
                {translate(
                  `datacosmos.tasking.existing.activityStatus.${activity.status}.title`
                )}
                <Icon icon="Info" size={12} />
              </div>
            </Tooltip>
          </div>
        </div>

        {isEditing && (
          <div className="mt-4" onClick={(e) => e.stopPropagation()}>
            <SwathControl
              opportunity={request}
              swath={activity}
              setSwathControlData={(data) => {
                debouncedSetSwathData(data, async () => {
                  if (!request) {
                    return;
                  }
                  if (!projectId) {
                    return;
                  }

                  const updated = await postSwathSearch({
                    body: {
                      area_of_interest: {
                        name: request.region_name,
                        geojson: {
                          geometry: request.region.geometry,
                          properties: {},
                          type: request.region.type,
                        },
                      },
                      instrument: {
                        mission_id: activity.mission_id,
                        sensor_id: activity.parameters?.imager?.name ?? '',
                      },
                      roll_angle: data.rotation,
                      start: data.duration.start.toISOString(),
                      stop: data.duration.end.toISOString(),
                      project_id: projectId,
                    },
                  });

                  if (!updated.data) return;

                  updateSwathLayer(activity, updated.data);

                  // Set oza, sza additionally
                  setSwathControlData({
                    ...data,
                    oza: updated.data?.midpoint.oza_deg,
                    sza: updated.data?.midpoint.sza_deg,
                  });
                });
              }}
              swathControlData={swathControlData}
            />

            <div className="flex w-full justify-between gap-2">
              <Button
                className="w-full !bg-item-contrast-surface"
                onPress={() => {
                  // Disabled the check here because I have no idea why this would be a problem
                  // considering the function has an explicitly defined void return type

                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  resetSwathDataToDefault();
                  if (isAoIShown) {
                    toggleDisplayAoIOnMap(request);
                  }

                  if (isSwathShown) {
                    toggleDisplayActivitySwathOnMap(activity);
                  }
                  setIsEditing(false);
                }}
              >
                {translate('datacosmos.buttons.cancel')}
              </Button>

              <Tooltip
                content={durationLimitTooltipText}
                isDisabled={!isActivityDurationWithinLimits}
              >
                <Button
                  loading={isUpdatingActivity}
                  isDisabled={
                    isUpdatingActivity || isActivityDurationWithinLimits
                  }
                  className="w-full !bg-item-contrast-surface"
                  onPress={async () => {
                    setIsUpdatingActivity(true);
                    const updatedParameters = {
                      ...swathControlData?.parameters,
                    };
                    delete updatedParameters?.physical;
                    const { success } = await patchTaskingRequest({
                      body: {
                        activities: [
                          {
                            id: activity.id,
                            end_date:
                              swathControlData.duration.end.toISOString(),
                            start_date:
                              swathControlData.duration.start.toISOString(),
                            priority:
                              swathControlData?.priority?.priority_level,
                            parameters: updatedParameters,
                          },
                        ],
                      },
                      params: {
                        requestId: request.id,
                      },
                    });

                    if (!success) {
                      setIsUpdatingActivity(false);
                      setIsEditing(false);
                      return;
                    }
                    const { data: updatedRequest } = await getTaskingRequest({
                      params: {
                        requestId: request.id,
                      },
                    });
                    if (updatedRequest) {
                      const matchedActivity = updatedRequest.activities.find(
                        (a) => a.id === activity.id
                      ) as Activity;
                      removeSwathEdits(matchedActivity);
                      setIsUpdatingActivity(false);
                      setIsEditing(false);
                    }
                    await refetchRequests();
                  }}
                >
                  {translate('datacosmos.buttons.update')}
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
      {isEditing ? null : (
        <MenuTrigger>
          <Button
            icon="ThreePointsVertical"
            aria-label="Menu"
            data-testid="tasking-options-menu"
            isMinimal
            isTransparent
            style={{
              height: triggerRef?.current?.clientHeight,
              maxHeight: 'unset',
              padding: 0,
            }}
            ref={buttonRef}
          />
          <Popover placement="right top">{taskingOptionsContent}</Popover>
        </MenuTrigger>
      )}

      <AdvancedParametersInfo
        isOpen={isOpen}
        onOpenChange={() => {
          setOpen(!isOpen);
        }}
        triggerRef={buttonRef}
        popoverContent={
          detailsPopoverContent?.parameters as unknown as {
            [key: string]: unknown;
          }
        }
      />

      <ConfirmCancelActivity
        isOpen={isOpenCancelActivityConfirmation}
        setIsOpen={setIsOpenCancelActivityConfirmation}
        request={request}
        activity={activity}
        refetchData={refetchRequests}
      />
    </div>
  );
};

type CancelActivityProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  request: TaskingRequest;
  activity: Activity;
  refetchData?: () => Promise<void>;
};

const ConfirmCancelActivity = ({
  isOpen,
  setIsOpen,
  request,
  activity,
  refetchData,
}: CancelActivityProps) => {
  const { translate } = useLocalisation();

  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);

  const cancelActivity = async (requestId: string, activityId: string) => {
    if (!requestId) {
      return;
    }
    setIsUpdatingStatus(true);
    const data = await patchTaskingRequest({
      params: { requestId: requestId },
      body: { activities: [{ id: activityId, status: 'CANCELLED' }] },
    });
    setIsUpdatingStatus(false);
    return data;
  };
  return (
    <Dialog
      isOpen={isOpen}
      hideCancelButton
      onClose={() => setIsOpen(false)}
      title={translate('datacosmos.cancelRequestDialog.title')}
      buttons={[
        {
          text: translate('datacosmos.buttons.confirm'),
          shown: true,
          showLoadingIndicator: isUpdatingStatus,
          onPress: async () => {
            const activityData = await cancelActivity(request.id, activity.id);
            setIsOpen(false);
            if (activityData?.success && refetchData) {
              await refetchData();
            }
          },
        },
        {
          text: translate('datacosmos.buttons.cancel'),
          shown: true,
          onPress: () => setIsOpen(false),
        },
      ]}
    >
      <div className="flex flex-col gap-2">
        <h4>
          {translate('datacosmos.cancelActivityDialog.areYouSure', {
            date: moment(activity.start_date).format('YYYY MMM DD - HH:mm:ss'),
            duration: getDuration(activity),
          })}
        </h4>

        <div className="flex items-center gap-2 bg-accent-200 dark:bg-accent-dark my-2 p-1 text-xs dark:text-item-contrast">
          <Icon icon="Info" />
          <span>
            {translate('datacosmos.cancelActivityDialog.note', {
              price: activity.price,
            })}
          </span>
        </div>
      </div>
    </Dialog>
  );
};

export default ActivityCard;
