import { type Layer } from 'datacosmos/entities/layer';
import { useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { TaskingPanel } from 'datacosmos/components/Tasking/helpers';
import classNames from 'classnames';
import { SwathLayer } from 'datacosmos/entities/SwathLayer';
import type { CommonOpportunity } from '_api/tasking/helpers';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { zIndexValues, Button } from 'opencosmos-ui';
import { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';

type TaskingLegendProps = {
  showOnRoute: string;
  rightMenuOpen: boolean;
  layers: Layer[];
};

export const TaskingLegend = ({
  showOnRoute,
  rightMenuOpen,
  layers,
}: TaskingLegendProps) => {
  const selectedRouteMatch = useRouteMatch(showOnRoute);
  const matchesRoute = selectedRouteMatch?.isExact;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { replaceLayer } = useMapLayers();

  const { translate } = useLocalisation();

  const ogSwaths = useMemo(
    () =>
      layers
        .filter((l) => l instanceof SwathLayer || l instanceof GeoJSONLayer)
        .filter((l) => l.getName().includes('-target')) as
        | SwathLayer<CommonOpportunity>[]
        | undefined,
    [layers]
  );

  if (!matchesRoute) {
    return <div></div>;
  }

  if (location.pathname.includes('ops')) {
    return (
      <div
        style={{ zIndex: 1000 }}
        className={classNames(
          'dark:bg-surface-dark bg-surface dark:text-item-dark-contrast',
          'p-2 flex flex-col h-fit w-fit min-w-[220px] absolute right-[5rem] top-[60px]'
        )}
      >
        <div className="grid grid-cols-[0.5fr_2fr_0.5fr] items-center">
          <div className="w-[25px] h-[25px] my-[5px] bg-[#ffcc5580] border-2 border-[#ffcc55] " />
          <span>{translate('datacosmos.tasking.legend.currentSwath')}</span>
        </div>

        <div className="grid grid-cols-[0.5fr_2fr_0.5fr] items-center">
          <div
            className={
              'w-[25px] h-[25px] my-[5px] bg-[#80808080] border-2 border-[#59595980]'
            }
          />
          <span>{translate('datacosmos.tasking.legend.target')}</span>
          <Button
            icon={
              ogSwaths?.every((og) => og.options.visible) && ogSwaths.length > 0
                ? 'eye-open'
                : 'eye-off'
            }
            isMinimal
            isTransparent
            onPress={() => {
              if (ogSwaths?.every((og) => og.options.visible)) {
                ogSwaths.map((og) =>
                  replaceLayer(og.cloneWithOptions({ visible: false }))
                );
              } else {
                ogSwaths?.map((og) =>
                  replaceLayer(og.cloneWithOptions({ visible: true }))
                );
              }
            }}
          />
        </div>

        <div className="grid grid-cols-[0.5fr_2fr_0.5fr] items-center">
          <div
            className={
              'w-[25px] h-[25px] my-[5px] bg-[#0000ff80] border-2 border-[#0000ff]'
            }
          />
          <span>{translate('datacosmos.tasking.legend.aoi')}</span>
        </div>
      </div>
    );
  }

  if (queryParams.get('panel') === TaskingPanel.New) {
    return (
      <div
        className={classNames(
          'absolute right-20 top-[60px] bg-[#ffffffb3] w-[220px] m-[10px] px-[10px] py-[5px] z-[2]',
          'dark:bg-surface-dark p-2 dark:text-item-dark-contrast'
        )}
        style={{ right: rightMenuOpen ? '450px' : '5rem' }}
      >
        <div className={'grid grid-cols-[0.5fr_2fr] items-center'}>
          <div
            className={
              'w-[25px] h-[25px] my-[5px] bg-[#e4695e80] border-2 border-[#e4695e]'
            }
          />
          <span>{translate('datacosmos.tasking.legend.fieldOfRegard')}</span>
        </div>

        <div className={'grid grid-cols-[0.5fr_2fr] items-center'}>
          <div className="w-[25px] h-[25px] my-[5px] bg-[#ffcc5580] border-2 border-[#ffcc55] " />
          <span>{translate('datacosmos.tasking.legend.currentSwath')}</span>
        </div>

        <div className={'grid grid-cols-[0.5fr_2fr] items-center'}>
          <div
            className={
              'w-[25px] h-[25px] my-[5px] bg-[#5abe9680] border-2 border-[#5abe96] '
            }
          />
          <span>{translate('datacosmos.tasking.legend.confirmedSwath')}</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'dark:bg-surface-dark bg-surface dark:text-item-dark-contrast',
        'p-2 flex flex-col h-fit w-fit min-w-[220px] absolute right-[5rem] top-[60px]',
        `z-[${zIndexValues.default}]`
      )}
      style={{ right: rightMenuOpen ? '550px' : '5rem' }}
    >
      <div className="grid grid-cols-[0.5fr_2fr_0.5fr] items-center">
        <div
          className={
            'w-[25px] h-[25px] my-[5px] bg-[#e4695e80] border-2 border-[#e4695e]'
          }
        />
        <span>{translate('datacosmos.tasking.legend.failed')}</span>
      </div>

      <div className="grid grid-cols-[0.5fr_2fr_0.5fr] items-center">
        <div
          className={
            'w-[25px] h-[25px] my-[5px] bg-[#55a7ff80] border-2 border-[#55a7ff]'
          }
        />
        <span>{translate('datacosmos.tasking.legend.planned')}</span>
      </div>

      <div className="grid grid-cols-[0.5fr_2fr_0.5fr] items-center">
        <div
          className={
            'w-[25px] h-[25px] my-[5px] bg-[#5abe9680] border-2 border-[#5abe96] '
          }
        />
        <span>{translate('datacosmos.tasking.legend.succeeded')}</span>
      </div>
    </div>
  );
};
