import { Column } from 'ui/ColumnLayout/Column';
import { Header } from 'ui/Header';
import { ItemTabContainer } from 'ui/ItemContainer';
import { ActivitiesMap } from 'pages/ops/Scheduling/components/ActivitiesMap';
import { Text } from 'ui/Text';
import {
  CompositeDetailsItem,
  DetailsItem,
  TabularDetailsItem,
} from 'pages/ops/shared/components/DetailsItem';
import { RowLayout } from 'ui/RowLayout/RowLayout';
import { Row } from 'ui/RowLayout/Row';
import { toFormattedDate, toFormattedTime } from 'utils/common/dateUtils';
import type { useTaskingOverviewData } from './useTaskingOverviewData';
import { NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import findSatelliteNameByMissionId from 'utils/findSatelliteNameByMissionId';
import Textarea from '_atoms/Textarea/Textarea';
import { Accordion, Button, Icon, Tooltip, Spinner } from 'opencosmos-ui';
import type { GenericDetailsItem } from '_api/tasking/types';
import { CancelRequest } from '_organisms/CancelRequestPopup/CancelRequest';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import ActivityInfo from 'pages/shared/components/Tasking/ActivityInfo';
import { Link } from 'react-router-dom';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQueries } from '@tanstack/react-query';
import type { Activity } from '_api/activities/service';
import { getActivity } from '_api/activities/service';
import { useCallback } from 'react';
import RequestAOICoverage from 'datacosmos/components/Tasking/RequestViewing/RequestAOICoverage';

const TaskingOverviewScreen = ({
  taskingOverviewData,
}: {
  taskingOverviewData: ReturnType<typeof useTaskingOverviewData>;
}) => {
  const {
    selectedRequest,
    customer,
    isAddActivityModalOpen,
    setIsAddActivityModalOpen,
    refetchTaskingRequests,
    disableAddActivity,
    disableCancelRequest,
    isCancelRequestModalOpen,
    setIsCancelRequestModalOpen,
  } = taskingOverviewData;

  const { translate } = useLocalisation();

  const combineActivitiesResults = useCallback(
    (results: UseQueryResult<Activity>[]) => {
      return {
        data: results.map((r) => r.data),
        isPending: results.some((r) => r.isPending),
      };
    },
    []
  );

  const activitiesQueries = useQueries({
    queries:
      selectedRequest?.activities?.map((activity) => ({
        queryKey: ['activities', activity],
        queryFn: async () => {
          const result = await getActivity({
            params: {
              missionId: activity.mission_id,
              activityId: activity.id,
            },
          });
          return result.data ?? activity;
        },
        placeholderData: activity,
      })) ?? [],
    combine: combineActivitiesResults,
  });

  return (
    <>
      {!selectedRequest ? (
        <NonIdealState
          icon={IconNames.HELP}
          title="No request selected"
          description="Select a request to view it here"
          className="dark:text-item-dark-contrast text-item-contrast"
        />
      ) : (
        <>
          <div className="block color-header h-12 shrink-0">
            <div className="flex items-center h-12 gap-2 px-2">
              <div className="flex-1">
                <Header size="h2">Tasking Request</Header>
                <span className="text-xs">
                  with id <em aria-label="Request id">{selectedRequest.id}</em>
                </span>
              </div>
              <div>
                {selectedRequest.status !== 'CANCELLED' &&
                  selectedRequest.status !== 'FULFILLED' &&
                  selectedRequest.status !== 'FAILED' && (
                    <Tooltip
                      content="This request cannot be cancelled"
                      isDisabled={!disableCancelRequest}
                      placement="bottom"
                    >
                      <Button
                        className="w-24"
                        isDisabled={disableCancelRequest}
                        onPress={() => setIsCancelRequestModalOpen(true)}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                  )}
              </div>
            </div>
          </div>

          {!isAddActivityModalOpen && (
            <ActivitiesMap
              activity={selectedRequest.activities}
              request={selectedRequest}
              aspectRatio="auto"
            />
          )}

          <RowLayout rows={2} rowRatio={[0.1, 1]} gap={5}>
            <Row>
              <Column>
                <div className="text-xs px-1 pb-1">
                  {selectedRequest.type} tasking request created by{' '}
                  <Link to={'/portal/users/' + encodeURIComponent(customer.id)}>
                    {customer.name} {customer.email && `(${customer.email})`}
                  </Link>{' '}
                  on behalf of{' '}
                  <Link
                    to={
                      '/portal/organisation/' + selectedRequest.organisation_id
                    }
                  >
                    {selectedRequest.organisation_name}
                  </Link>{' '}
                  on {toFormattedDate(selectedRequest.created_at)}
                </div>

                <DetailsItem
                  aria-label="Request region name"
                  title="Region name:"
                  value={selectedRequest.region_name}
                />
                <DetailsItem
                  aria-label="Request project name"
                  title="Project:"
                  value={
                    <Link
                      to={
                        '/data/project/' +
                        selectedRequest.project_id +
                        '/tasking?panel=Existing'
                      }
                    >
                      {selectedRequest.project_name}
                    </Link>
                  }
                />

                <DetailsItem
                  aria-label="Area of Interest Coverage:"
                  title={
                    translate('datacosmos.tasking.legend.roiCoverage') + ':'
                  }
                  value={
                    <RequestAOICoverage
                      request={selectedRequest}
                      className="grow justify-end"
                    />
                  }
                />

                {selectedRequest.constraints?.length && (
                  <TabularDetailsItem
                    title="Constraints:"
                    value={
                      selectedRequest.constraints as unknown as GenericDetailsItem[]
                    }
                  />
                )}

                {selectedRequest.parameters?.objectives?.length && (
                  <TabularDetailsItem
                    title="Objectives:"
                    value={
                      selectedRequest.parameters
                        ?.objectives as unknown as GenericDetailsItem[]
                    }
                  />
                )}
                <CompositeDetailsItem
                  title="Customer Notes: "
                  element={<Textarea disabled value={selectedRequest.notes} />}
                />
              </Column>
            </Row>

            <Row>
              <Column data-testid="activities-column">
                <div className="flex items-baseline gap-2">
                  <Header size="h2">Activities</Header>
                  <span
                    aria-label="Number of activities"
                    className="text-xs flex-1"
                  >
                    ({selectedRequest.activities.length})
                  </span>
                  <Tooltip
                    content="Activities cannot be added to expired tasking requests"
                    isDisabled={!disableAddActivity}
                  >
                    <Button
                      className="w-24"
                      isDisabled={disableAddActivity}
                      onPress={() => setIsAddActivityModalOpen(true)}
                    >
                      Add
                    </Button>
                  </Tooltip>
                </div>

                {activitiesQueries.isPending && <Spinner />}
                {!activitiesQueries.isPending &&
                  activitiesQueries.data.map((a) =>
                    a === undefined ? null : (
                      <Accordion
                        className="px-2 py-0 w-full"
                        key={a.id}
                        labelClassName="w-full"
                        labelRenderer={() => (
                          <ItemTabContainer className="dark:bg-item-dark bg-item">
                            <div className="grid grid-cols-3 gap-1 w-full">
                              <Tooltip content="Satellite">
                                <Icon icon="Satellite" size={12} />
                                <Text padding={0} className="ml-1">
                                  {findSatelliteNameByMissionId(a.mission_id)}
                                </Text>
                              </Tooltip>

                              <div className="flex gap-3 items-center col-span-2">
                                <Tooltip content="Acquisition date (UTC)">
                                  <Icon icon="Calendar" size={12} />
                                  <Text padding={0} className="ml-1">
                                    {toFormattedDate(a.start_date)}
                                  </Text>
                                </Tooltip>

                                <Tooltip content="Acquisition start - end time (UTC)">
                                  <Icon icon="Clock" size={12} />
                                  <Text
                                    padding={0}
                                    className="ml-1"
                                  >{`${toFormattedTime(
                                    a.start_date
                                  )} - ${toFormattedTime(a.end_date)}`}</Text>
                                </Tooltip>
                              </div>

                              <Tooltip content="Priority (lower value is higher priority)">
                                <Icon icon="ArrowUp" size={12} />
                                <Text padding={0} className="ml-1">
                                  {a?.priority?.toString()}
                                </Text>
                              </Tooltip>

                              <Tooltip
                                content={`Status: ${translate(
                                  `datacosmos.tasking.existing.activityStatus.${a.status}.description`
                                )}`}
                                className="w-[250px]"
                              >
                                <Icon icon="Info" size={12} />
                                <Text padding={0} className="ml-1">
                                  {a.status}
                                </Text>
                              </Tooltip>
                            </div>
                          </ItemTabContainer>
                        )}
                        labelText=""
                      >
                        <div className="py-2 pb-4 border-b-2 border-neutral mb-2 flex flex-col gap-3">
                          <ActivityInfo activity={a} />
                          <DetailsItem
                            title="Operator Notes: "
                            value={
                              <Textarea value={a?.operator_notes} disabled />
                            }
                            applyFlexStyle
                          />
                        </div>
                      </Accordion>
                    )
                  )}
              </Column>
            </Row>
          </RowLayout>

          <CancelRequest
            isOpen={isCancelRequestModalOpen}
            setIsOpen={setIsCancelRequestModalOpen}
            refetchData={refetchTaskingRequests}
            request={selectedRequest}
          />
        </>
      )}
    </>
  );
};

export default TaskingOverviewScreen;
