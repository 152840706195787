import type { ProviderView } from 'datacosmos/stores/ViewsProvider';
import { Dialog, Input } from 'opencosmos-ui';
import React, { useState } from 'react';

type IProps = {
  view: ProviderView | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteHandler: (viewId: string) => void;
};

//TODO: Make the scenario delete dialog more generic and use it here in the meantime
const DeleteViewDialog = ({
  view,
  isOpen,
  setIsOpen,
  deleteHandler,
}: IProps) => {
  const [deleteInput, setDeleteInput] = useState<string>('');
  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      buttons={[
        {
          text: 'Confirm',
          shown: true,
          onPress: () => {
            if (view) {
              deleteHandler(view.id);
              setIsOpen(false);
            }
          },
          isDisabled: deleteInput.toLowerCase() !== 'delete',
        },
      ]}
      style={{
        width: '600px',
      }}
    >
      <div
        className={
          'dark:bg-surface-dark dark:text-item-dark-contrast bg-surface'
        }
      >
        <h1 className={'font-bold text-warning'}>WARNING!</h1>
        <p>
          Are you sure you want to delete view &quot;<b>{view?.name}</b>
          &quot;?
        </p>

        <div className={'flex flex-col my-2 gap-3'}>
          <p>
            You are about to delete this view, and will lose all your saved
            layers. <span className="text-warning">This cannot be undone</span>.
            Type <b>&quot;delete&quot;</b> in the box below and click{' '}
            <b>&quot;Confirm&quot;</b> if you are sure you wish to proceed.
          </p>
          <Input
            type="text"
            fill
            placeholder="Type 'delete' to confirm your choice"
            onChange={(e) => setDeleteInput(e.target.value)}
            className="bg-surface dark:bg-surface-dark dark:text-item-dark-contrast"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteViewDialog;
