/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Icon } from '@blueprintjs/core';
import React, { useRef } from 'react';
import type { Layer } from 'datacosmos/entities/layer';
import type { DropTargetMonitor } from 'react-dnd';
import { useDrag, useDrop } from 'react-dnd';
import type { XYCoord } from 'dnd-core';
import classNames from 'classnames';
import type { GroupHeaderItem } from '.';

const dragAndDropType = Symbol('layer');

interface DragItem {
  index: number;
  id: string;
  type: string;
}

/**
 * Returns true if the move should be performed
 * Only perform the move when the mouse has crossed half of the items height
 * When dragging downwards, only move when the cursor is below 50% the next card
 * When dragging upwards, only move when the cursor is above 50% the previous card
 */
function hasUserCrossedTheNextHalfItem(
  dragIndex: number,
  hoverIndex: number,
  itemRef: React.RefObject<HTMLLIElement>,
  monitor: DropTargetMonitor
) {
  // Determine rectangle on screen
  const hoverBoundingRect = itemRef.current?.getBoundingClientRect();

  if (hoverBoundingRect) {
    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return true;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return true;
    }
  }

  return false;
}

function useDragAndDrop(
  item: Layer | GroupHeaderItem,
  itemRef: React.RefObject<HTMLLIElement>,
  handleRef: React.RefObject<HTMLLIElement>,
  index: number,
  moveItem: (dragIndex: number, hoverIndex: number) => void,
  disabled?: boolean
) {
  const [{ handlerId }, drop] = useDrop({
    accept: dragAndDropType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(dragItem, monitor: DropTargetMonitor) {
      if (!itemRef.current) {
        return;
      }
      const dragIndex = (dragItem as DragItem).index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      if (
        !hasUserCrossedTheNextHalfItem(dragIndex, hoverIndex, itemRef, monitor)
      ) {
        return;
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      (dragItem as DragItem).index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: dragAndDropType,
    item: () => {
      return { id: item.id, index };
    },
    canDrag: () => !disabled,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(itemRef));
  drag(handleRef);

  return {
    handlerId,
    isDragging,
  };
}

interface IProps {
  item: Layer | GroupHeaderItem;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  disabled: boolean | undefined;
  heightWhileDragging?: number;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isSelected?: boolean;
  children: React.ReactNode;
}

export default function DraggableItem({
  item,
  index,
  moveItem,
  disabled,
  heightWhileDragging,
  onClick,
  isSelected,
  children,
}: IProps) {
  const itemRef = useRef<HTMLLIElement>(null);
  const handleRef = useRef<HTMLLIElement>(null);
  const { isDragging, handlerId } = useDragAndDrop(
    item,
    itemRef,
    handleRef,
    index,
    moveItem,
    disabled
  );

  const opacity = isDragging ? 0 : 1;

  return (
    <li
      ref={itemRef}
      className={classNames('flex justify-center items-center w-full', {
        'bg-item-selected dark:bg-item-dark-selected': isSelected,
      })}
      style={
        {
          opacity,
          ...(isDragging &&
            heightWhileDragging && { height: heightWhileDragging }),
        } as React.CSSProperties
      }
      data-handler-id={handlerId}
      onClick={onClick}
    >
      <span
        className={'cursor-grab flex items-center'}
        ref={disabled ? null : handleRef}
      >
        <Icon
          icon="drag-handle-vertical"
          className={classNames('dark:stroke-item-dark-contrast', {
            'cursor-not-allowed': disabled,
          })}
        />
      </span>
      {children}
    </li>
  );
}
