import { useState, useMemo, useCallback } from 'react';
import { NonIdealState } from '@blueprintjs/core';
import { IconNames as BpIcons } from '@blueprintjs/icons';
import {
  Steps,
  extractFieldsOfRegard,
  extractSwaths,
  pairSwathToFieldOfRegard,
} from 'datacosmos/components/Tasking/helpers';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { OpportunityCard } from 'datacosmos/components/Tasking/OpportunityCard';
import { useTasking } from 'datacosmos/stores/TaskingProvider';
import type { CommonOpportunity } from '_api/tasking/helpers';
import { FieldOfRegardLayer } from 'datacosmos/entities/FieldOfRegardLayer';
import type { SwathLayer } from 'datacosmos/entities/SwathLayer';
import Button from '_molecules/Button/Button';
import { DefaultGeoJSONLayerOptions } from 'datacosmos/entities/geojsonLayer';
import type { SwathStyleOptions } from 'datacosmos/components/Tasking/helpers';
import SecondaryButton from '_molecules/Button/SecondaryButton';
import type { TaskingSatellite } from '_api/tasking/types';
import { cloudLayer } from 'datacosmos/utils/map-layers';
import { isoStringToMomentObject } from 'utils/common/dateUtils';
import { CloudLayer } from 'datacosmos/entities/cloudLayer';
import type { OpportunityLayer } from 'datacosmos/entities/TaskingOpportunityLayer';

type ListOpportunityProps = {
  setSwitchStep: (step: Steps) => void;
  setIsRequestModalOpen: (value: boolean) => void;
  /**
   * Optional custom button to be rendered in the bottom of the list
   * If left undefined, the default button that opens a request modal will be rendered
   */
  requestButton?: JSX.Element;
  satellitesForTasking: TaskingSatellite[];
  projectIdOfTaskingRequest?: string;
};

export const ListOpportunity = ({
  setSwitchStep,
  setIsRequestModalOpen,
  requestButton,
  satellitesForTasking,
  projectIdOfTaskingRequest,
}: ListOpportunityProps) => {
  const { layers, setLayers, addLayer, replaceLayer } = useMapLayers();
  const {
    opportunityLayers,
    confirmedSwaths,
    saveMapLayerState,
    opportunities,
  } = useTasking();

  const [moreContent, setMoreContent] = useState<number>();

  const swathsFromLayers = useMemo(
    () => extractSwaths(opportunities),
    [opportunities]
  );

  const swathFieldOfRegardPairs = useMemo(
    () =>
      pairSwathToFieldOfRegard(
        extractFieldsOfRegard(opportunities),
        extractSwaths(opportunityLayers)
      ),
    [opportunities, opportunityLayers]
  );

  const setAllSwathLayersVisible = useCallback(
    (visible: boolean) => {
      setLayers((prev) => {
        return prev.map((layer) => {
          if (
            layer.layerClass === 'SwathLayer' &&
            !(layer as SwathLayer<CommonOpportunity>).isConfirmed
          ) {
            layer.options.visible = visible;
          }
          return layer;
        });
      });
    },
    [setLayers]
  );

  const setAllFieldsOfRegard = useCallback(
    (visible: boolean) => {
      setLayers((prev) => {
        return prev.map((layer) => {
          if (layer instanceof FieldOfRegardLayer) {
            layer.options.visible = visible;
          }
          return layer;
        });
      });
    },
    [setLayers]
  );

  const setAllCloudsLayer = useCallback(
    (visible: boolean) => {
      setLayers((prev) => {
        return prev.map((layer) => {
          if (layer instanceof CloudLayer) {
            layer.options.visible = visible;
          }
          return layer;
        });
      });
    },
    [setLayers]
  );

  const showAllFieldsOfRegard = useCallback(() => {
    setAllFieldsOfRegard(true);
  }, [setAllFieldsOfRegard]);

  const hideAllFieldsOfRegard = useCallback(() => {
    setAllFieldsOfRegard(false);
  }, [setAllFieldsOfRegard]);

  const hideAllCloudsLayer = useCallback(() => {
    setAllCloudsLayer(false);
  }, [setAllCloudsLayer]);

  const updateLayerStyle = useCallback(
    ({ layerId, style, visible }: SwathStyleOptions) => {
      setLayers((prev) => {
        return prev.map((layer) => {
          if (layer.id === layerId) {
            const swath = layer as SwathLayer<CommonOpportunity>;
            swath.options.fillOpacity =
              style === 'highlight'
                ? 0.5
                : DefaultGeoJSONLayerOptions.fillOpacity;
            swath.options.weight =
              style === 'highlight' ? 8 : DefaultGeoJSONLayerOptions.weight;
            swath.options.visible = swath.isConfirmed ? true : visible;
            return swath;
          }
          return layer;
        });
      });
    },
    [setLayers]
  );

  const renderRequestButton = useCallback(() => {
    if (requestButton) {
      return requestButton;
    }

    return (
      <Button
        onPress={() => {
          saveMapLayerState();
          setSwitchStep(Steps.ConfirmTaskingRequest);
          setIsRequestModalOpen(true);
        }}
        text="Request"
        disabled={!confirmedSwaths?.length}
        className="flex bg-item w-full h-12 items-center justify-center dark:bg-item-dark dark:text-item-dark-contrast"
      />
    );
  }, [
    requestButton,
    saveMapLayerState,
    setIsRequestModalOpen,
    setSwitchStep,
    confirmedSwaths?.length,
  ]);

  const toggleCloudLayer = useCallback(
    (isAdded: boolean, layer: OpportunityLayer<CommonOpportunity>) => {
      const cloudDate = isoStringToMomentObject(layer.metadata.Start).unix();
      const layerToToggle = layers.find(
        (l) =>
          l instanceof CloudLayer &&
          l.start_date === new Date(layer.getStartTime()).toISOString()
      );

      if (isAdded && layerToToggle) {
        replaceLayer(
          layerToToggle.cloneWithOptions({
            visible: false,
          })
        );
        return;
      }

      hideAllCloudsLayer();

      if (layerToToggle) {
        replaceLayer(
          layerToToggle.cloneWithOptions({
            visible: true,
          })
        );
        return;
      }

      addLayer(
        new CloudLayer(
          `#${layer.metadata?.OpportunityIndex} Opportunity Clouds`,
          'Indicates the predicted cloud coverage',
          `${cloudLayer.url}&date=${cloudDate}`,
          new Date(layer.getStartTime()).toISOString()
        )
      );
    },
    [layers]
  );

  return (
    <>
      <div className="search-opportunity dark:bg-item-dark dark:text-item-dark-contrast">
        <div
          className="opportunity-list search-form"
          data-testid="opportunity-list"
        >
          <div className="flex items-center h-12 px-2 bg-item-hover dark:bg-item-dark-hover ">
            <SecondaryButton
              text="Edit input parameters"
              icon="ArrowLeft"
              iconPlacement="left"
              size={24}
              fill
              onPress={() => setSwitchStep(Steps.ManualTasking)}
            />
          </div>
          <div className="flex flex-col">
            {swathFieldOfRegardPairs.length > 0 ? (
              swathFieldOfRegardPairs.map((pair) => {
                if (!pair.fieldOfRegard && pair.swath) {
                  const swath = swathsFromLayers.find(
                    (filtered) =>
                      filtered.metadata.Id === pair.swath?.metadata.Id
                  );
                  if (!swath) return null;
                  return (
                    <OpportunityCard
                      key={swath.id}
                      opportunity={swath}
                      oppKey={Number(swath.metadata.Id)}
                      setAllSwathLayersVisible={setAllSwathLayersVisible}
                      moreContent={moreContent}
                      setMoreContent={setMoreContent}
                      showAllFieldsOfRegard={showAllFieldsOfRegard}
                      hideAllFieldsOfRegard={hideAllFieldsOfRegard}
                      pairedSwath={swath}
                      confirmedSwaths={confirmedSwaths}
                      updateLayerStyle={updateLayerStyle}
                      satellitesForTasking={satellitesForTasking}
                      projectIdOfTaskingRequest={projectIdOfTaskingRequest}
                      toggleCloudLayer={toggleCloudLayer}
                    />
                  );
                } else {
                  if (!pair.fieldOfRegard) return null;
                  const swath = swathsFromLayers.find(
                    (filtered) =>
                      filtered.metadata.Id === pair.swath?.metadata.Id
                  );
                  if (!swath) return null;
                  return (
                    <OpportunityCard
                      key={swath.id}
                      opportunity={pair.fieldOfRegard}
                      oppKey={Number(pair.fieldOfRegard.metadata.Id)}
                      pairedSwath={swath}
                      setAllSwathLayersVisible={setAllSwathLayersVisible}
                      moreContent={moreContent}
                      setMoreContent={setMoreContent}
                      showAllFieldsOfRegard={showAllFieldsOfRegard}
                      hideAllFieldsOfRegard={hideAllFieldsOfRegard}
                      confirmedSwaths={confirmedSwaths}
                      updateLayerStyle={updateLayerStyle}
                      satellitesForTasking={satellitesForTasking}
                      projectIdOfTaskingRequest={projectIdOfTaskingRequest}
                      toggleCloudLayer={toggleCloudLayer}
                    />
                  );
                }
              })
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  height: '100%',
                }}
                data-testid="error-msg"
              >
                <NonIdealState
                  description="No opportunities were found for selected area on chosen dates for selected satellites"
                  title="No results"
                  icon={BpIcons.SEARCH}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full px-2 py-4 mb-4">{renderRequestButton()}</div>
    </>
  );
};
