import { LayerSourceType } from 'datacosmos/entities/layer';
import type { IFiltersProviderContext } from 'datacosmos/stores/FiltersProvider';
import React from 'react';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import UploadRegion from '../UploadRegion';
import type { IMapLayers } from 'datacosmos/stores/MapLayersProvider';
import type { PolygonLayer } from 'datacosmos/entities/polygonLayer';

type Props = {
  filters: IFiltersProviderContext;
  layers: IMapLayers;
  onAddLayer?: (layer: PolygonLayer) => void;
};

const UploadRegionMenu = ({ filters, layers, onAddLayer }: Props) => {
  const { areaOfInterest, setAreaOfInterest } = filters;
  const { translate } = useLocalisation();
  return (
    <UploadRegion
      aoiSourceType={LayerSourceType.GEOMETRY_LAYER}
      areaOfInterest={areaOfInterest}
      setAreaOfInterest={setAreaOfInterest}
      buttonTitle={translate('datacosmos.filters.uploadAoi')}
      disableUploadValidation={true}
      multipleAois={true}
      mapLayersProvider={layers}
      buttonForMenu={true}
      showUploadingSpinner={true}
      onAddLayer={onAddLayer}
      allowCSV
    />
  );
};

export default UploadRegionMenu;
