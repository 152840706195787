import { toaster } from 'toaster';
import { useState } from 'react';
import type { Scenario } from '_api/scenarios/types';
import { putScenario } from '_api/scenarios/service';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { LANGUAGE, TASKING_ENABLE } from 'env';
import { Dialog } from 'opencosmos-ui';

type DeleteModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  scenario?: Scenario;
  setUserScenarios: () => Promise<void>;
};

export const DeleteModal = ({
  isOpen,
  setIsOpen,
  scenario,
  setUserScenarios,
}: DeleteModalProps) => {
  const [isDelButtonDisabled, setIsDelButtonDisabled] = useState<boolean>(true);

  const { translate } = useLocalisation();

  const deleteText = LANGUAGE === 'en' ? 'delete' : 'eliminar';

  const handleInput = (text: string): void => {
    setIsDelButtonDisabled(text !== deleteText);
  };

  const handleDelete = async () => {
    if (!scenario) return;

    const { success } = await putScenario({
      params: { scenarioId: scenario.id },
      body: {
        name: scenario.title,
        description: scenario.description,
        is_archived: true,
      },
    });

    if (!success) return;

    setIsOpen(false);

    try {
      await setUserScenarios();
    } catch (error) {
      toaster.show({
        message: translate(
          'datacosmos.deleteProjectDialog.errors.deletionFailed'
        ),
        intent: 'warning',
        icon: 'error',
      });
      return;
    }

    toaster.show({
      message: translate('datacosmos.deleteProjectDialog.success'),
      intent: 'success',
      icon: 'info-sign',
    });
  };

  return (
    <Dialog
      isOpen={isOpen}
      buttons={[
        {
          onPress: async () => {
            await handleDelete();
          },
          text: 'Confirm',
          shown: true,
          isDisabled: isDelButtonDisabled,
          intent: 'warning',
        },
      ]}
      onClose={() => setIsOpen(false)}
      style={{
        width: '600px',
      }}
    >
      <div
        className={
          'dark:bg-surface-dark dark:text-item-dark-contrast bg-surface'
        }
      >
        <div className="flex flex-col gap-2">
          <h1 className={'font-bold text-warning'}>
            {translate('datacosmos.deleteProjectDialog.warning')}
          </h1>
          <p className="font-semibold">
            {translate('datacosmos.deleteProjectDialog.areYouSure', {
              project: scenario?.title,
            })}
          </p>

          <div className={'flex flex-col mb-2 gap-2'}>
            <p>
              {translate('datacosmos.deleteProjectDialog.longWarning', {
                taskingRequestsWarning: TASKING_ENABLE
                  ? translate(
                      'datacosmos.deleteProjectDialog.taskingRequestsWarning'
                    )
                  : '',
              })}
            </p>
            <input
              type="text"
              style={{ width: '100%' }}
              placeholder={translate(
                'datacosmos.deleteProjectDialog.placeholder'
              )}
              onChange={(e) => handleInput(e.target.value)}
              className="dark:bg-item-dark dark:text-item-dark-contrast h-8 border-2 border-item-contrast-text-field-stroke"
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
