import { useTheme } from 'datacosmos/stores/ThemeProvider';
import {
  coordinateFormats,
  type EPSG,
} from 'datacosmos/utils/coordinates/epsgCoordinates';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { parseCsvString } from './utils';
import { Checkbox, Input, ListBoxItem, Select, Table } from 'opencosmos-ui';
import { isGeodetic } from 'datacosmos/utils/coordinates/coordinateHelpers';

export type CSVOptions = {
  delimiter: ',' | ';' | '\t';
  crs: EPSG;
  hasHeader: boolean;
  latColumn?: number;
  lonColumn?: number;
  sizeColumn?: number | 'FIXED';
  radius?: number | '';
};

export const initialCsvOptions: CSVOptions = {
  delimiter: ',',
  crs: coordinateFormats.find((f) => f.code === 'EPSG:4326')!,
  hasHeader: true,
  latColumn: undefined,
  lonColumn: undefined,
  sizeColumn: undefined,
  radius: undefined,
};

type CSVFormProps = {
  options: CSVOptions;
  onOptionsChange: (options: CSVOptions) => void;
  content: string;
};

const CSVForm = ({
  options = initialCsvOptions,
  onOptionsChange,
  content,
}: CSVFormProps) => {
  const themeProvider = useTheme();
  const { translate } = useLocalisation();
  const parsedContent = parseCsvString(content, options.delimiter);
  const csvColumns = Array.from(
    { length: parsedContent[0]?.length || 0 },
    (_, i) => i + 1
  );
  const radiusError = options.radius && options.radius <= 0;

  return (
    <div className="flex flex-row w-full gap-2">
      <div className="w-1/2 flex flex-row gap-2 pt-2 pr-2 border-r-2">
        <div className="flex flex-col gap-2 w-1/2">
          <Select
            fill
            label={translate('datacosmos.uploadRegion.csv.delimiter')}
            selectedKey={options.delimiter}
            onSelectionChange={(key) =>
              onOptionsChange({
                ...options,
                delimiter: key as CSVOptions['delimiter'],
              })
            }
          >
            <ListBoxItem key={','} id={','}>
              {translate('datacosmos.uploadRegion.csv.comma')} (,)
            </ListBoxItem>
            <ListBoxItem key={';'} id={';'}>
              {translate('datacosmos.uploadRegion.csv.semicolon')} (;)
            </ListBoxItem>
            <ListBoxItem key={'\t'} id={'\t'}>
              {translate('datacosmos.uploadRegion.csv.tab')} (\t)
            </ListBoxItem>
          </Select>
          <div className="my-2">
            <Checkbox
              isSelected={options.hasHeader}
              name="has-header"
              onChange={() =>
                onOptionsChange({
                  ...options,
                  hasHeader: !options.hasHeader,
                })
              }
              themeProvider={themeProvider}
            >
              {translate('datacosmos.uploadRegion.csv.hasHeader')}
            </Checkbox>
          </div>
          <Select
            label={translate('datacosmos.uploadRegion.csv.crs')}
            fill
            selectedKey={options.crs.code}
            onSelectionChange={(key) =>
              onOptionsChange({
                ...options,
                crs: coordinateFormats.find((f) => f.code === key)!,
              })
            }
            selectedItemProps={{ className: 'overflow-hidden text-ellipsis' }}
          >
            {coordinateFormats.map((format) => (
              <ListBoxItem
                id={format.code}
                key={format.code}
                className="overflow-hidden"
              >
                {format.code}
              </ListBoxItem>
            ))}
          </Select>
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <Select
            fill
            name="lat-column"
            label={translate('datacosmos.uploadRegion.csv.yColumn', {
              context: !isGeodetic(options.crs) ? 'projected' : undefined,
            })}
            selectedKey={options.latColumn}
            onSelectionChange={(key) =>
              onOptionsChange({
                ...options,
                latColumn: parseInt(key as string),
              })
            }
          >
            {csvColumns.map((col) => (
              <ListBoxItem id={col} key={col}>
                {translate('datacosmos.uploadRegion.csv.column', {
                  number: col,
                })}
              </ListBoxItem>
            ))}
          </Select>
          <Select
            fill
            name="lon-column"
            label={translate('datacosmos.uploadRegion.csv.xColumn', {
              context: !isGeodetic(options.crs) ? 'projected' : undefined,
            })}
            selectedKey={options.lonColumn}
            onSelectionChange={(key) =>
              onOptionsChange({
                ...options,
                lonColumn: parseInt(key as string),
              })
            }
          >
            {csvColumns.map((col) => (
              <ListBoxItem id={col} key={col}>
                {translate('datacosmos.uploadRegion.csv.column', {
                  number: col,
                })}
              </ListBoxItem>
            ))}
          </Select>
          <Select
            fill
            name="size-column"
            label={translate('datacosmos.uploadRegion.csv.radiusColumn')}
            selectedItemProps={{ intent: 'none' }}
            selectedKey={options.sizeColumn}
            onSelectionChange={(key) => {
              if (key === 'FIXED') {
                onOptionsChange({
                  ...options,
                  sizeColumn: 'FIXED',
                  radius: '',
                });
                return;
              }
              onOptionsChange({
                ...options,
                sizeColumn: parseInt(key as string),
                radius: undefined,
              });
            }}
          >
            <ListBoxItem id="FIXED" key="FIXED">
              {translate('datacosmos.uploadRegion.csv.fixed')}
            </ListBoxItem>
            {csvColumns.map((col) => (
              <ListBoxItem id={col} key={col}>
                {translate('datacosmos.uploadRegion.csv.column', {
                  number: col,
                })}
              </ListBoxItem>
            ))}
          </Select>
          {options.radius !== undefined && (
            <Input
              type="number"
              value={options.radius}
              onChange={(e) =>
                onOptionsChange({
                  ...options,
                  radius: e.target.value ? Number(e.target.value) : '',
                })
              }
              errorMsg={
                radiusError
                  ? translate('datacosmos.uploadRegion.csv.radiusMinError')
                  : undefined
              }
            />
          )}
        </div>
      </div>
      <div className="w-1/2 flex flex-col gap-1 pt-2">
        <h3 className="font-bold">
          {translate('datacosmos.uploadRegion.csv.previewTitle')}
        </h3>
        <Table
          cellRenderer={(ctx) => {
            return (
              <span className="p-2 flex-wrap">{ctx.getValue() as string}</span>
            );
          }}
          data={parsedContent.map((row) => {
            return Object.fromEntries(
              row.map((cell, i) => [
                translate('datacosmos.uploadRegion.csv.column', {
                  number: i + 1,
                }),
                cell,
              ])
            );
          })}
          isMinimal
          stickyHeader
          alignHeader="left"
        />
      </div>
    </div>
  );
};

export default CSVForm;
