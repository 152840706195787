import type { Key } from 'react';
import { useEffect, useState } from 'react';
import { SearchOpportunity } from 'datacosmos/components/Tasking/SearchOpportunity';
import { ListOpportunity } from 'datacosmos/components/Tasking/ListOpportunity';
import { ConfirmManualRequest } from 'datacosmos/components/Tasking/ConfirmManualRequest';
import ConfirmAutomatedRequestModal from 'datacosmos/components/Tasking/ConfirmAutomatedRequestModal';
import SidebarHeader from '_organisms/SidebarHeader/SidebarHeader';
import { RequestsList } from 'datacosmos/components/Tasking/RequestViewing/RequestsList';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import {
  Steps,
  TaskingPanel,
  SearchSteps,
} from 'datacosmos/components/Tasking/helpers';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useTaskingRequestList } from './RequestViewing/useTaskingRequestList';
import TaskingHeaderAndFilters from './TaskingHeaderAndFilters';
import InfiniteScroll from 'react-infinite-scroller';
import { useFilters } from 'datacosmos/stores/FiltersProvider';
import { isNil } from 'lodash';
import type { TaskingRequestsByProjectIdQueryParams } from '_api/tasking/service';
import { useTaskingPermissions } from './RequestViewing/useTaskingPermissions';
import { useProjects } from 'datacosmos/stores/ProjectProvider';
import { Icon } from 'opencosmos-ui';

export const Tasking = () => {
  const history = useHistory();
  const location = useLocation();

  const { taskingStatusFilter, taskingDateRangeFilter } = useFilters();

  const panelQueryParam =
    new URLSearchParams(location.search).get('panel') ?? TaskingPanel.Existing;

  const [switchStep, setSwitchStep] = useState<Steps>(Steps.DataAcquisition);
  const [searchSteps, setSearchSteps] = useState<SearchSteps[]>([
    SearchSteps.AddAOI,
  ]);

  const [isRequestModalOpen, setIsRequestModalOpen] = useState<boolean>(false);
  const [isAutomatedRequestModalOpen, setIsAutomatedRequestModalOpen] =
    useState<boolean>(false);

  const { translate } = useLocalisation();
  const [isRequestListOpen, setIsRequestListOpen] = useState<boolean>(
    panelQueryParam === TaskingPanel.Existing.toString()
  );

  const [showRequestIDs, setShowRequestIDs] = useState<boolean>(false);

  const goBackToTaskingTypeSelect = () => {
    setSearchSteps((prev) => [
      ...prev.filter((p) => p !== SearchSteps.SelectTaskingDateAndInstruments),
      SearchSteps.SelectTaskingType,
    ]);
    setSwitchStep(Steps.DataAcquisition);
  };

  useEffect(() => {
    history.replace({
      search: `?panel=${
        isRequestListOpen ? TaskingPanel.Existing : TaskingPanel.New
      }`,
    });

    return () => history.replace({ search: undefined });
  }, [history, isRequestListOpen, location.pathname]);

  useEffect(() => {
    if (panelQueryParam === 'Existing' && !isRequestModalOpen) {
      setSwitchStep(Steps.DataAcquisition);
      return;
    }
  }, [isRequestModalOpen, panelQueryParam, history]);

  const urlMatch = useRouteMatch<{ projectId: string }>({
    path: '/data/project/:projectId',
    exact: false,
  });

  const handleShowAllSwathsPress = () => {
    taskingRequestsHook.toggleDisplayAllSwathsOnMap();
  };
  const handleTaskingSelectionChange = (key: Key) => {
    setSwitchStep(Steps.DataAcquisition);
    setIsRequestListOpen(key === TaskingPanel.Existing);
    /*clears dates and opportunities selected when clicked on `New` from the dropdown
     to avoid persisting state when switching*/
    if (key === TaskingPanel.New) goBackToTaskingTypeSelect();
  };
  const projectId = urlMatch?.params.projectId;
  const taskingRequestsHook = useTaskingRequestList(projectId);
  const taskingPermissionsHook = useTaskingPermissions();

  const { isAllowedToPerformTaskingForCurrentProject } = useProjects();
  return (
    <div style={{ flex: '1 1 0' }} className="min-h-full h-full flex flex-col">
      <SidebarHeader
        title={
          <div className="flex gap-1 items-center">
            <span>{translate('datacosmos.tasking.title')}</span>
          </div>
        }
      />

      {isAllowedToPerformTaskingForCurrentProject ? (
        <>
          <TaskingHeaderAndFilters
            isRequestListOpen={isRequestListOpen}
            handleTaskingSelectionChange={handleTaskingSelectionChange}
            handleShowAllSwathsPress={handleShowAllSwathsPress}
            taskingRequestsHook={taskingRequestsHook}
            showRequestIDs={showRequestIDs}
            handleSetShowRequestIDs={setShowRequestIDs}
          />
          <div className="overflow-auto">
            {!isRequestListOpen &&
              taskingPermissionsHook.taskingSatellites?.data && (
                <SearchOpportunity
                  setSwitchStep={setSwitchStep}
                  searchSteps={searchSteps}
                  setSearchSteps={setSearchSteps}
                  switchStep={switchStep}
                  setIsRequestModalOpen={setIsAutomatedRequestModalOpen}
                  satellitesForTasking={
                    taskingPermissionsHook.taskingSatellites?.data
                  }
                />
              )}

            {isRequestListOpen && (
              <InfiniteScroll
                initialLoad={false}
                loadMore={async () => {
                  if (!taskingRequestsHook.nextPageCursor) {
                    return;
                  }
                  if (
                    taskingStatusFilter ||
                    !isNil(
                      taskingDateRangeFilter[0] ||
                        !isNil(taskingDateRangeFilter[1])
                    )
                  ) {
                    const filtersObj: TaskingRequestsByProjectIdQueryParams = {
                      status: taskingStatusFilter,
                      start_date: taskingDateRangeFilter[0],
                      end_date: taskingDateRangeFilter[1],
                    };
                    await taskingRequestsHook.fetchTaskingRequests(
                      taskingRequestsHook.nextPageCursor,
                      filtersObj
                    );
                    return;
                  }
                  await taskingRequestsHook.fetchTaskingRequests(
                    taskingRequestsHook.nextPageCursor
                  );
                }}
                hasMore={taskingRequestsHook.hasMore}
                useWindow={false}
                threshold={10}
              >
                <RequestsList
                  setIsRequestListOpen={setIsRequestListOpen}
                  taskingRequestsHook={taskingRequestsHook}
                  showRequestIDs={showRequestIDs}
                  taskingSatellites={
                    taskingPermissionsHook.taskingSatellites?.data
                  }
                />
              </InfiniteScroll>
            )}

            {(switchStep === Steps.ListOfOpportunities ||
              switchStep === Steps.ConfirmTaskingRequest) &&
            taskingPermissionsHook.taskingSatellites?.data ? (
              <ListOpportunity
                setSwitchStep={setSwitchStep}
                setIsRequestModalOpen={setIsRequestModalOpen}
                satellitesForTasking={
                  taskingPermissionsHook.taskingSatellites?.data
                }
              />
            ) : null}
          </div>
        </>
      ) : (
        <div className="my-2 flex justify-center items-center gap-4">
          <div className="text-center">
            <div className="flex justify-center">
              <Icon icon="Help" size={32} />
            </div>
            {translate('datacosmos.tooltips.toolbar.noPermissionForTasking')}
          </div>
        </div>
      )}

      <ConfirmManualRequest
        setSwitchStep={setSwitchStep}
        isOpen={isRequestModalOpen}
        setIsOpen={setIsRequestModalOpen}
        handleSwitchToExistingPanel={(key) => {
          handleTaskingSelectionChange(key);
          void taskingRequestsHook.refetch();
        }}
      />

      <ConfirmAutomatedRequestModal
        isOpen={isAutomatedRequestModalOpen}
        setIsOpen={setIsAutomatedRequestModalOpen}
        handleSwitchToExistingPanel={(key) => {
          handleTaskingSelectionChange(key);
          void taskingRequestsHook.refetch();
        }}
      />
    </div>
  );
};
