import { toaster } from 'toaster';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import type { ICustomBandAlgebra } from 'datacosmos/services/localStorageBandAlgebra';
import { BandAlgebraLocalStorage } from 'datacosmos/services/localStorageBandAlgebra';
import type { IExpressionDetails } from 'datacosmos/utils/algebraExpressionFormatter';
import {
  formatExpressionForSatellite,
  genericToSatelliteSpecificExpression,
  getItemSpecificBandName,
  supportsExpressions,
  supportsSpecifiedExpression,
} from 'datacosmos/utils/algebraExpressionFormatter';
import React, { createContext, useContext } from 'react';
import { useMapController } from './MapControllerProvider';
import { useMapLayers } from './MapLayersProvider';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import type { IAsset } from 'datacosmos/types/stac-types';

interface IProps {
  children: React.ReactNode;
}

interface IBandAlgebraContextProvider {
  toggleSelectedIndex: (index: IExpressionDetails) => void;
  isIndexSelected: (index: IExpressionDetails) => boolean;
  saveCustomAlgebra: (algebra: ICustomBandAlgebra) => void;
  getCustomBandAlgebra: () => IExpressionDetails[];
  deleteIndex: (index: IExpressionDetails) => void;
  getItemSpecificBands: () => ItemSpecificBand[];
  selectedLayerPlatformIsSupported: () => boolean;
}

export type ItemSpecificBand = IAsset & { key: string };

const BandAlgebraContext = createContext<IBandAlgebraContextProvider>(
  null as unknown as IBandAlgebraContextProvider
);
export const useBandAlgebra = () =>
  useContext<IBandAlgebraContextProvider>(BandAlgebraContext);

const BandAlgebraProvider = ({ children }: IProps) => {
  const { setAutoViewToFitBboxIfNecessary } = useMapController();
  const { toggleAssetOnMap, layers, removeAssetFromMap } = useMapLayers();
  const { translate } = useLocalisation();
  const { sendInfo } = useAnalytics();

  const algebraApi = new BandAlgebraLocalStorage();

  const algebraSupportedLayers = layers.filter(
    (l) => l instanceof SingleBandSTACLayer
  ) as SingleBandSTACLayer[];

  const algebraLayers = layers.filter(
    (l) => l instanceof BandAlgebraSTACLayer
  ) as BandAlgebraSTACLayer[];

  const isPredefinedIndex = (index: IExpressionDetails): boolean => {
    return Boolean(index.expressionName);
  };

  const togglePredefinedIndex = (
    layer: SingleBandSTACLayer,
    index: IExpressionDetails
  ) => {
    if (
      !supportsSpecifiedExpression(
        layer.item.properties.platform,
        index.expressionName
      )
    ) {
      toaster.show({
        message: translate(
          'datacosmos.layers.bandAlgebra.expressionNotSupported'
        ),
        icon: 'warning-sign',
        intent: 'warning',
      });
      return;
    }

    const exp = formatExpressionForSatellite(
      layer.item.properties.platform,
      index.expressionName
    );

    if (!exp) {
      return;
    }

    toggleAssetOnMap(
      layer.item,
      exp,
      {
        colormap: index.recommendedColormap,
        scale: `-1,1`,
        bandAlgebraType: index.algebraType,
        expression: '',
        rescaleFalse: '',
        rgbExpression: '',
        stacLayerId: layer.id,
      },
      index.id
    );
    if (layer.item.collection)
      sendInfo({
        action: 'Visualise predefined band algebra',
        item: `Band algebra item: ${index.id ?? 'no id'} - ${index.uiName}  `,
        type: 'Click',
        module: 'DataCosmos',
        dimensions: {
          dimension1: `${layer.item.collection}/${layer.item.id}`,
          dimension3: `${layer.item.id}`,
        },
        additionalParams: {
          algebraObject: index,
        },
      });
  };

  const toggleCustomAlgebra = (
    layer: SingleBandSTACLayer,
    index: IExpressionDetails
  ) => {
    const exp = genericToSatelliteSpecificExpression(
      index.expression,
      layer.item.properties.platform
    );

    if (!exp) {
      return;
    }

    if (!index.algebraType || index.algebraType !== 'Multiband') {
      toggleAssetOnMap(
        layer.item,
        exp,
        {
          colormap: index.recommendedColormap,
          scale: `${index.min ?? ''},${index.max ?? ''}`,
          bandAlgebraType: index.algebraType,
          expression: '',
          rescaleFalse: '',
          rgbExpression: '',
          stacLayerId: layer.id,
        },
        index.id
      );
      return;
    }

    toggleAssetOnMap(
      layer.item,
      exp,
      {
        colormap: index.recommendedColormap,
        scale: `${index.min ?? ''},${index.max ?? ''}`,
        bandAlgebraType: index.algebraType,
        expression: '',
        rescaleFalse: `${index.min ?? ''},${index.max ?? ''}`,
        rgbExpression: exp,
        stacLayerId: layer.id,
      },
      index.id
    );
    if (layer.item.collection)
      sendInfo({
        action: 'Visualise custom band algebra',
        item: `Band algebra item: ${index.id ?? 'no id'} - ${index.uiName}`,
        type: 'Click',
        module: 'DataCosmos',
        dimensions: {
          dimension1: `${layer.item.collection}/${layer.item.id}`,
          dimension3: `${layer.item.id}`,
        },
        additionalParams: {
          algebraObject: index,
        },
      });
  };

  const toggleSelectedIndex = (index: IExpressionDetails) => {
    if (isIndexSelected(index)) {
      algebraSupportedLayers
        .filter((l) => l.options.isSelected)
        .map((l) => {
          const exp = genericToSatelliteSpecificExpression(
            index.expression,
            l.item.properties.platform
          );

          if (!exp) {
            return;
          }

          removeAssetFromMap(l.item, exp, undefined, index.id);
        });

      return;
    }
    algebraSupportedLayers
      .filter((l) => l.options.isSelected)
      .map((l) => {
        setAutoViewToFitBboxIfNecessary(l.item.bbox);
        if (isPredefinedIndex(index)) {
          togglePredefinedIndex(l, index);
        } else {
          toggleCustomAlgebra(l, index);
        }
      });
  };

  const isIndexSelected = (
    index: IExpressionDetails,
    specificLayer?: SingleBandSTACLayer
  ) => {
    const isSelected = algebraSupportedLayers
      .filter((l) => l.options.isSelected)
      .filter((l) => (specificLayer ? l.id === specificLayer.id : true))
      .some((asl) =>
        algebraLayers.some((al) => {
          const exp = genericToSatelliteSpecificExpression(
            index.expression,
            asl.item.properties.platform
          );
          return al.containsBandAlgebra(asl.item, index.id + '::' + exp);
        })
      );
    return isSelected;
  };

  const saveCustomAlgebra = (algebra: ICustomBandAlgebra) => {
    algebraApi.saveBandAlgebra(algebra);
  };

  const getCustomBandAlgebra = (): IExpressionDetails[] => {
    const algebra = algebraApi.retrieveBandAlgebra() || [];

    return algebra.map((a) => ({
      id: a.id,
      expression: a.expression,
      recommendedColormap: a.colorMap,
      uiName: a.uiName,
      description: a.description,
      max: a.max,
      min: a.min,
      algebraType: a.algebraType,
    }));
  };

  const deleteIndex = (index: IExpressionDetails) => {
    try {
      algebraApi.deleteBandAlgebra({
        id: index.id ?? '',
        colorMap: index.recommendedColormap,
        expression: index.expression,
        max: index.max ?? 0,
        min: index.min ?? 0,
        uiName: index.uiName,
        algebraType: index.algebraType,
      });
    } catch (error) {
      toaster.show({
        message: translate('datacosmos.layers.bandAlgebra.custom.deleteError'),
        intent: 'warning',
      });
      return false;
    }

    toaster.show({
      message: translate('datacosmos.layers.bandAlgebra.custom.deleted', {
        name: index.uiName,
      }),
      intent: 'success',
    });
    return true;
  };

  const getItemSpecificBands = () => {
    const selectedLayers = algebraSupportedLayers.filter(
      (l) => l.options.isSelected
    );
    if (selectedLayers.length !== 1) return [];
    const assets = selectedLayers
      .map((layer) => Object.entries(layer.item.assets))
      .flat();
    return assets
      .map(([key, value]) => ({ ...value, key: getItemSpecificBandName(key) }))
      .filter((asset) => asset.roles?.includes?.('data'));
  };

  const selectedLayerPlatformIsSupported = () => {
    const selectedLayer = algebraSupportedLayers.find(
      (l) => l.options.isSelected
    );
    if (!selectedLayer) return false;
    return supportsExpressions(selectedLayer.item.properties.platform);
  };

  return (
    <BandAlgebraContext.Provider
      value={{
        toggleSelectedIndex,
        isIndexSelected,
        saveCustomAlgebra,
        getCustomBandAlgebra,
        deleteIndex,
        getItemSpecificBands,
        selectedLayerPlatformIsSupported,
      }}
    >
      {children}
    </BandAlgebraContext.Provider>
  );
};

export default BandAlgebraProvider;
