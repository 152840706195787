import { useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { routeParamProjectId } from 'datacosmos/components/routePath';
import { useProjects } from 'datacosmos/stores/ProjectProvider';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';
import classNames from 'classnames';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import { TASKING_ENABLE } from 'env';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import { Tooltip, Icon } from 'opencosmos-ui';

interface IProps {
  routeScenario: string;
  defaultScenarioPath: string;
  routeTasking: string;
  routeApplication: string;
  routeCatalog: string;
  right?: true;
  setIsMinimapShown: (val: boolean) => void;
  isMinimapShown: boolean;
}

const Toolbar = (props: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const scenario = useRouteMatch(props.routeScenario);
  const tasking = useRouteMatch(props.routeTasking);
  const application = useRouteMatch(props.routeApplication);
  const catalog = useRouteMatch(props.routeCatalog);
  const { isLayersMenuOpen, setLayersMenuOpen } = useMapLayers();

  const { currentScenario, isAllowedToPerformTaskingForCurrentProject } =
    useProjects();

  const {
    setActivePage,
    activePage,
    setAlgebraMenuOpen,
    isAlgebraMenuOpen,
    setIsLayerToolsOpen,
    isLayerToolsOpen,
  } = useActivePage();

  const { translate } = useLocalisation();

  const params = new URLSearchParams(location.search);

  const {
    installedApps,
    toggleInstalledAppOpen,
    setSelectedInstalledApp,
    setIsApplicationRunsOpen,
  } = useApplicationCatalog();

  const { sendInfo } = useAnalytics();

  const routeWithProjectId = (route: string) => {
    return route.replace(
      ':' + routeParamProjectId,
      currentScenario ? currentScenario.id : 'PLACEHOLDER'
    );
  };

  useEffect(() => {
    if (location.pathname.includes('catalog')) setActivePage('catalog');
    if (location.pathname.includes('items')) setActivePage('items');
    if (location.pathname.includes('tasking')) setActivePage('tasking');
    if (location.pathname.includes('application')) setActivePage('application');
  }, [location.pathname, setActivePage]);

  const taskingButton = () => {
    if (!TASKING_ENABLE) {
      return null;
    }
    return (
      <li
        className={
          Boolean(tasking) && activePage === 'tasking' ? 'bg-accent' : ''
        }
      >
        <Tooltip
          content={
            !isAllowedToPerformTaskingForCurrentProject
              ? translate('datacosmos.tooltips.toolbar.noPermissionForTasking')
              : translate('datacosmos.tooltips.toolbar.tasking')
          }
          placement="right"
        >
          <a
            className={classNames(
              'flex justify-center items-center h-[60px] w-[60px]',
              {
                '!cursor-not-allowed':
                  !isAllowedToPerformTaskingForCurrentProject,
              }
            )}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (!isAllowedToPerformTaskingForCurrentProject) {
                return;
              }
              sendInfo({
                type: 'Tasking toolbar click',
                action: 'Click',
                item: 'Tasking toolbar button',
                module: 'DataCosmos',
              });

              setActivePage((prev) => {
                if (prev === 'tasking') return undefined;
                return 'tasking';
              });
              history.replace({
                pathname: `${routeWithProjectId(props.routeTasking)}`,
                search: '?panel=Existing',
              });
            }}
          >
            <Icon
              inherit={false}
              icon="SatelliteTasking"
              size={24}
              className={classNames(
                'stroke-header-sidebar-contrast dark:stroke-header-sidebar-dark-contrast tour-tasking',
                {
                  '!stroke-accent-contrast':
                    Boolean(tasking) && activePage === 'tasking',
                  'opacity-30': !isAllowedToPerformTaskingForCurrentProject,
                }
              )}
            />
          </a>
        </Tooltip>
      </li>
    );
  };

  return (
    <nav
      className={
        'w-[60px] h-[calc(100vh - 50px)] bg-header-sidebar dark:bg-header-sidebar-dark'
      }
      style={{ position: props.right ? 'absolute' : 'relative', right: 0 }}
    >
      <ul className={'h-full flex flex-col m-0 p-0 list-none'}>
        {!props.right && (
          <>
            <li
              data-testid="datacosmos-left-navigation-catalog"
              className={
                Boolean(catalog) && activePage === 'catalog' ? 'bg-accent' : ''
              }
            >
              <Tooltip
                content={translate('datacosmos.tooltips.toolbar.catalog')}
                placement="right"
              >
                <a
                  className={
                    'flex justify-center items-center h-[60px] w-[60px]'
                  }
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    sendInfo({
                      type: 'Catalog toolbar click',
                      action: 'Click',
                      item: 'Catalog toolbar button',
                      module: 'DataCosmos',
                    });
                    setActivePage((prev) => {
                      if (prev === 'catalog') return undefined;
                      return 'catalog';
                    });

                    if (params.has('panel')) {
                      params.delete('panel');
                    }

                    if (params.has('view-item')) {
                      params.delete('view-item');
                    }

                    history.replace({
                      pathname: `${routeWithProjectId(props.routeCatalog)}`,
                      search: params.toString(),
                    });
                  }}
                >
                  <Icon
                    inherit={false}
                    icon="Catalog"
                    size={24}
                    className={classNames(
                      'stroke-header-sidebar-contrast dark:stroke-header-sidebar-dark-contrast tour-catalog',
                      {
                        '!stroke-accent-contrast':
                          Boolean(catalog) && activePage === 'catalog',
                      }
                    )}
                  />
                </a>
              </Tooltip>
            </li>
            <li
              className={
                Boolean(scenario) && activePage === 'items' ? 'bg-accent' : ''
              }
            >
              <Tooltip
                content={translate('datacosmos.tooltips.toolbar.items')}
                placement="right"
              >
                <a
                  className={
                    'flex justify-center items-center h-[60px] w-[60px]'
                  }
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    sendInfo({
                      type: 'Project items toolbar click',
                      action: 'Click',
                      item: 'Project items toolbar button',
                      module: 'DataCosmos',
                    });
                    setActivePage((prev) => {
                      if (prev === 'items') return undefined;
                      return 'items';
                    });

                    if (params.has('panel')) {
                      params.delete('panel');
                    }

                    if (params.has('view-item')) {
                      params.delete('view-item');
                    }

                    history.replace({
                      pathname: `${routeWithProjectId(
                        props.defaultScenarioPath
                      )}`,
                      search: params.toString(),
                    });
                  }}
                >
                  <Icon
                    inherit={false}
                    icon="Features"
                    size={24}
                    className={classNames(
                      'stroke-header-sidebar-contrast dark:stroke-header-sidebar-dark-contrast tour-project',
                      {
                        '!stroke-accent-contrast':
                          Boolean(scenario) && activePage === 'items',
                      }
                    )}
                  />
                </a>
              </Tooltip>
            </li>
            {taskingButton()}
          </>
        )}

        {props.right && (
          <>
            <li
              className={
                Boolean(application) && activePage === 'application'
                  ? 'bg-accent'
                  : ''
              }
            >
              <Tooltip
                content={translate('datacosmos.tooltips.toolbar.applications')}
                placement="left"
              >
                <a
                  className={
                    'flex justify-center items-center h-[60px] w-[60px]'
                  }
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    sendInfo({
                      type: 'Applications toolbar click',
                      action: 'Click',
                      item: 'Applications toolbar button',
                      module: 'DataCosmos',
                    });
                    setLayersMenuOpen(false);
                    setAlgebraMenuOpen(false);
                    setIsLayerToolsOpen(false);
                    if (activePage === 'application') {
                      setActivePage(undefined);
                      setIsApplicationRunsOpen(false);
                    }
                    setSelectedInstalledApp(undefined);
                    if (params.has('panel')) {
                      params.delete('panel');
                    }
                    history.replace({
                      pathname: `${routeWithProjectId(props.routeApplication)}`,
                      search: params.toString(),
                    });
                  }}
                >
                  <Icon
                    inherit={false}
                    icon="Apps"
                    size={24}
                    className={classNames(
                      'stroke-header-sidebar-contrast dark:stroke-header-sidebar-dark-contrast tour-applications',
                      {
                        '!stroke-accent-contrast':
                          Boolean(application) && activePage === 'application',
                      }
                    )}
                  />
                </a>
              </Tooltip>
            </li>

            <li
              className={
                isLayersMenuOpen || isAlgebraMenuOpen || isLayerToolsOpen
                  ? 'bg-accent'
                  : ''
              }
            >
              <Tooltip
                content={translate('datacosmos.tooltips.toolbar.layers')}
                placement="left"
              >
                <a
                  className={
                    'flex justify-center items-center h-[60px] w-[60px]'
                  }
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    sendInfo({
                      type: 'Layers toolbar click',
                      action: 'Click',
                      item: 'Layers toolbar button',
                      module: 'DataCosmos',
                    });
                    setLayersMenuOpen((current) => !current);
                    if (isLayerToolsOpen) setIsLayerToolsOpen(false);
                    if (isAlgebraMenuOpen) setAlgebraMenuOpen(false);
                    if (activePage === 'application') {
                      setActivePage(undefined);
                    }
                    setSelectedInstalledApp(undefined);
                  }}
                  data-testid="layers-icon-button"
                >
                  <Icon
                    inherit={false}
                    icon="Layers"
                    size={24}
                    className={classNames(
                      'stroke-header-sidebar-contrast dark:stroke-header-sidebar-dark-contrast tour-layers',
                      {
                        '!stroke-accent-contrast':
                          isLayersMenuOpen ||
                          isAlgebraMenuOpen ||
                          isLayerToolsOpen,
                      }
                    )}
                  />
                </a>
              </Tooltip>
            </li>
            <li>
              <div className="flex flex-col gap-4 max-h-96 overflow-y-auto items-center mt-4">
                {installedApps.map((app) => (
                  <Tooltip key={app.id} content={app.name} placement="left">
                    <button
                      onClick={() => {
                        toggleInstalledAppOpen(app);
                        if (activePage === 'application') {
                          setActivePage(undefined);
                        }
                        setLayersMenuOpen(false);
                      }}
                    >
                      <span className="uppercase text-header-sidebar-contrast dark:text-header-sidebar-dark-contrast">
                        {(app.acronym ?? app.name).slice(0, 3)}
                      </span>
                    </button>
                  </Tooltip>
                ))}
              </div>
            </li>
            <li style={{ flex: 1 }} />
            {!props.isMinimapShown && (
              <Tooltip
                content={translate('datacosmos.tooltips.toolbar.expandMinimap')}
                className="self-center"
                placement="left"
              >
                <li className="mb-12 self-center">
                  <button onClick={() => props.setIsMinimapShown(true)}>
                    <Icon
                      inherit={false}
                      icon="ChevronLeftDouble"
                      size={24}
                      className="!stroke-header-sidebar-contrast !dark:stroke-header-sidebar-dark-contrast"
                    />
                  </button>
                </li>
              </Tooltip>
            )}
          </>
        )}
      </ul>
    </nav>
  );
};

export default Toolbar;
