import { AnchorButton, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { ItemRenderer } from '@blueprintjs/select';
import { MultiSelect } from '@blueprintjs/select';
import { xor } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { ActivityStatusList, ActivityTypeList } from '_api/activities/service';
import type { ActivityType, ActivityStatus } from '_api/activities/service';
import s from './index.module.scss';
import { DebouncedInput } from 'opencosmos-ui';

interface IProps {
  setSelectedStatuses: React.Dispatch<React.SetStateAction<ActivityStatus[]>>;
  selectedStatuses: ActivityStatus[];
  selectedTypes: ActivityType[];
  setSelectedTypes: React.Dispatch<React.SetStateAction<ActivityType[]>>;
  setPriorityFilter: (priority: string | undefined) => void;
  setProjectFilter: (project: string | undefined) => void;
  priorityFilter: string | undefined;
  projectFilter: string | undefined;
}

const SchedulingFilter = ({
  setSelectedStatuses,
  selectedStatuses,
  selectedTypes,
  setSelectedTypes,
  setPriorityFilter,
  setProjectFilter,
  priorityFilter,
  projectFilter,
}: IProps) => {
  const [areFiltersOpen, setAreFiltersOpen] = useState<boolean>(false);

  const ActivityStatusSelect = MultiSelect.ofType<ActivityStatus>();
  const ActivityTypeSelect = MultiSelect.ofType<ActivityType>();

  const getNoOfAppliedFilters = useCallback(() => {
    let no = 0;
    selectedStatuses.length > 0 && no++;
    selectedTypes.length > 0 && no++;
    priorityFilter && no++;
    projectFilter && no++;
    return no;
  }, [
    priorityFilter,
    projectFilter,
    selectedStatuses.length,
    selectedTypes.length,
  ]);

  const areFiltersApplied = useMemo(
    () =>
      selectedStatuses.length > 0 ||
      selectedTypes.length > 0 ||
      Boolean(priorityFilter) ||
      projectFilter,
    [priorityFilter, projectFilter, selectedStatuses, selectedTypes]
  );

  const ActivityStatusRenderer: ItemRenderer<ActivityStatus> = (
    item,
    { handleClick }
  ) => {
    const active = selectedStatuses.findIndex((type) => type === item) !== -1;

    return <MenuItem text={item} onClick={handleClick} active={active} />;
  };

  const ActivityTypeRenderer: ItemRenderer<ActivityType> = (
    item,
    { handleClick }
  ) => {
    const active =
      selectedTypes.findIndex((type) => type.toString() === item) !== -1;
    return <MenuItem text={item} onClick={handleClick} active={active} />;
  };

  return (
    <div className={s.container}>
      <div className={s.filtersButtonWrapper}>
        <AnchorButton
          icon={IconNames.FILTER}
          onClick={() => setAreFiltersOpen(!areFiltersOpen)}
        />
        {areFiltersApplied && (
          <div className={s.filtersCounter}>{getNoOfAppliedFilters()}</div>
        )}
      </div>

      {areFiltersOpen && (
        <div className={s.dropdownWrapper}>
          <div className={s.dropdownItem}>
            <span>Activity status</span>
            <div>
              <ActivityStatusSelect
                items={ActivityStatusList as unknown as ActivityStatus[]}
                itemRenderer={ActivityStatusRenderer}
                onItemSelect={(item) => {
                  setSelectedStatuses((prev) => xor(prev, [item]));
                }}
                onRemove={(item) => {
                  setSelectedStatuses((prev) => prev.filter((p) => p !== item));
                }}
                selectedItems={selectedStatuses}
                popoverProps={{ fill: true }}
                tagRenderer={(tag) => tag}
              ></ActivityStatusSelect>
            </div>
          </div>

          <div className={s.dropdownItem}>
            <span>Activity type</span>
            <div>
              <ActivityTypeSelect
                items={ActivityTypeList as unknown as ActivityType[]}
                itemRenderer={ActivityTypeRenderer}
                onItemSelect={(item) => {
                  setSelectedTypes((prev) => xor(prev, [item]));
                }}
                onRemove={(item) => {
                  setSelectedTypes((prev) =>
                    prev.filter((p) => p.toString() !== item)
                  );
                }}
                popoverProps={{ fill: true }}
                tagRenderer={(tag) => tag}
                tagInputProps={{
                  tagProps: { onClick: (e) => e.stopPropagation() },
                }}
                selectedItems={selectedTypes}
              ></ActivityTypeSelect>
            </div>
          </div>
          <div className={s.dropdownItem}>
            <span>Activity priority</span>
            <DebouncedInput
              placeholder="E.g. 60"
              debounceTimeMs={500}
              type="number"
              fill
              className="bg-white"
              value={priorityFilter}
              onChange={(e) => {
                setPriorityFilter(e.target.value);
              }}
            />
          </div>
          <div className={s.dropdownItem}>
            <span>Project name</span>
            <DebouncedInput
              placeholder="Project UUID"
              debounceTimeMs={500}
              type="text"
              onChange={(e) => {
                setProjectFilter(e.target.value);
              }}
              value={projectFilter}
              fill
              className="bg-white"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SchedulingFilter;
