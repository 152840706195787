import { getActivityCategory } from '_api/activities/helpers';
import type { Activity } from '_api/activities/service';
import type { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import { GeoJSONLayerFactory } from 'datacosmos/entities/geojsonLayer';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { polygonToFeature } from 'datacosmos/utils/geojson';
import type { Feature, Polygon } from 'geojson';

/**
 * generateSwathsForActivity generates (but not adds) the layers that are related
 * to a particular activity.
 *
 * @param activity Satellite activity
 * @param dontGenerateTarget If true, the target swath will not be generated
 * @returns Array of layers to be displayed on the map
 */
export const generateSwathsForActivity = (
  activity: Activity,
  dontGenerateTarget?: boolean
) => {
  let layers: GeoJSONLayer<Activity>[] = [];

  const category = getActivityCategory(activity.status);

  let color = '#ffcc55';
  if (category === 'FAILED') color = '#FF695E';
  if (category === 'PLANNED') color = '#55a7ff';

  if (!activity.parameters.physical) {
    return [];
  }

  if (activity.parameters.physical.target && !dontGenerateTarget) {
    const ogSwath = GeoJSONLayerFactory<Activity>(
      LayerSourceType.STS_ACTIVITY_SWATH,
      activity.id + '-target',
      polygonToFeature(activity.parameters.physical.target.geojson.geometry),
      activity,
      null,
      { color: '#999999', opacity: 20 }
    );

    layers = [...layers, ogSwath];
  }

  if (activity.parameters.physical.latest) {
    const swathLayer = GeoJSONLayerFactory<Activity>(
      LayerSourceType.STS_ACTIVITY_SWATH,
      activity.id,
      polygonToFeature(activity.parameters.physical.latest.geojson.geometry),
      activity,
      null,
      { color }
    );

    layers = [...layers, swathLayer];
  }

  return layers;
};

/**
 * generateEditedSwathForActivity generates (but not adds) the layer that represents
 * the updated swath.
 *
 * @param activity Satellite activity
 * @param footprint GeoJSON polygon representing the new swath
 * @returns Layer to be displayed on the map
 */
export const generateEditedSwathForActivity = (
  activity: Activity,
  footprint: Feature<Polygon>
) => {
  return GeoJSONLayerFactory<Activity>(
    LayerSourceType.STS_ACTIVITY_SWATH,
    activity.id,
    footprint,
    activity,
    null,
    { color: '#ffcc55' }
  );
};
