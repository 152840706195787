import React from 'react';
import type { RangeCalendarState } from 'react-stately';
import type { AriaButtonProps } from '@react-types/button';
import { useDateFormatter, VisuallyHidden } from 'react-aria';
import type { PressEvent } from '@react-types/shared';
import { Button } from 'opencosmos-ui';

interface ICalendarHeaderProps {
  state: RangeCalendarState;
  calendarProps: React.HTMLAttributes<HTMLElement>;
  prevButtonProps: AriaButtonProps;
  nextButtonProps: AriaButtonProps;
}

const CalendarHeader = ({
  state,
  calendarProps,
  prevButtonProps,
  nextButtonProps,
}: ICalendarHeaderProps) => {
  const monthDateFormatter = useDateFormatter({
    month: 'long',
    year: 'numeric',
    timeZone: state.timeZone,
  });

  return (
    <div className="flex justify-between pb-3 mb-6 border-b-[1px] border-b-contrast-inactive text-sm">
      <VisuallyHidden>
        <h2>{calendarProps['aria-label']}</h2>
      </VisuallyHidden>
      <div className="flex gap-4">
        <Button
          onPress={(event) => prevButtonProps.onPress?.(event)}
          icon="ChevronLeft"
          isMinimal
          isTransparent
          size="lg"
          data-testid="btn-prev"
          className="stroke-item-contrast dark:stroke-item-dark-contrast"
        />
        <span aria-hidden>
          {monthDateFormatter.format(
            state.visibleRange.start.toDate(state.timeZone)
          )}
        </span>
      </div>

      <div className="flex gap-4">
        <span aria-hidden>
          {monthDateFormatter.format(
            state.visibleRange.start.add({ months: 1 }).toDate(state.timeZone)
          )}
        </span>
        <Button
          onPress={(event) => nextButtonProps.onPress?.(event)}
          icon="ChevronRight"
          isMinimal
          size="lg"
          isTransparent
          data-testid="btn-next"
          className="stroke-item-contrast dark:stroke-item-dark-contrast"
        />
      </div>
    </div>
  );
};

export default CalendarHeader;
