import { useEffect } from 'react';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import type { CallBackProps } from 'react-joyride';
import { setTourInformation } from 'actions/tour/action';
import { useTour } from 'datacosmos/stores/TourProvider';
import { Tour } from 'opencosmos-ui';

const MainAppTour = () => {
  const { state: tourState, dispatch } = useTour();

  useEffect(() => {
    // Auto start the tour if the tour is not viewed before
    if (!localStorage.getItem('main-app-tour-completed')) {
      dispatch(setTourInformation('START', { run: true }));
    }
  }, [dispatch]);

  const setTourViewed = () => {
    localStorage.setItem('main-app-tour-completed', 'true');
  };

  const callback = (data: CallBackProps) => {
    const { action, index, type, status } = data;

    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch(setTourInformation('STOP', { run: false }));
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Update step on Next or Back click
      dispatch(
        setTourInformation('NEXT_OR_PREV', {
          stepIndex: (index + (action === ACTIONS.PREV ? -1 : 1)) as number,
        })
      );
    }
  };

  return <Tour tourCallback={callback} tourState={tourState} />;
};

export default MainAppTour;
