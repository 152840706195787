import { useEffect, useRef, useState, Fragment, useCallback } from 'react';
import { capitalize } from 'lodash';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import SidebarHeader from '_organisms/SidebarHeader/SidebarHeader';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';
import Input from '_molecules/Input/Input';
import Fuse from 'fuse.js';
import type {
  AppTags,
  IApplication,
  IApplicationRunStatus,
} from 'datacosmos/types/applications';
import { APP_TAGS_LIST } from 'datacosmos/types/applications';
import List from '_molecules/List/List';
import { Item, Section } from 'react-stately';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { Button, ProgressBar, Spinner } from 'opencosmos-ui';
import {
  toFormattedDate,
  toFormattedTimeNoSeconds,
} from '../../../utils/common/dateUtils';
import { EmptyMessage } from 'ui/EmptyMessage';
import moment from 'moment';

const parseEstimatedDuration = (durationInSeconds: number) => {
  const duration = moment.duration(durationInSeconds, 'seconds');

  if (duration.asSeconds() < 60) {
    return `${duration.asSeconds()}s`;
  }
  if (duration.asMinutes() < 60) {
    return `${Math.floor(duration.asMinutes())}${
      duration.seconds() > 0 ? `:${duration.seconds()}` : ''
    }m`;
  }
  return `${Math.floor(duration.asHours())}${
    duration.minutes() > 0 ? `:${duration.minutes()}` : ''
  }h`;
};

export default function Applications() {
  const {
    applicationList,
    getApplicationRunStatus,
    isApplicationRunsOpen,
    setIsApplicationRunsOpen,
  } = useApplicationCatalog();
  const { setActivePage } = useActivePage();
  const { translate, translateWithExplicitFallback } = useLocalisation();

  const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false);
  const [isRunsLoading, setIsApplicationRunsLoading] = useState<boolean>(false);

  const sidebarRef = useRef<HTMLDivElement>(null);

  const [filteredApps, setFilteredApps] =
    useState<IApplication[]>(applicationList);

  const [selectedTags, setSelectedTags] = useState<AppTags[]>([]);
  const [query, setQuery] = useState<string>('');

  const [applicationRuns, setApplicationRuns] = useState<
    IApplicationRunStatus[]
  >([]);

  const getAppsByTags = useCallback(
    (apps: IApplication[]) => {
      if (selectedTags.length > 0) {
        return apps.filter((app) =>
          app.tags.some((t) => selectedTags.some((st) => st === t))
        );
      }

      return apps;
    },
    [selectedTags]
  );

  const getAppsBySearchTerm = (apps: IApplication[], qry: string) => {
    const fuse = new Fuse(apps, {
      keys: [
        'name',
        'shortDescription',
        'description',
        'provider.name',
        'tags',
      ],
      threshold: 0.4,
    });
    if (qry === null || qry === '') {
      return apps;
    }

    return fuse.search(qry);
  };

  const getFilteredApps = useCallback(() => {
    const byTags = getAppsByTags(applicationList);
    const byQuery = getAppsBySearchTerm(applicationList, query);
    return byTags.filter((t) => byQuery.some((q) => q === t));
  }, [applicationList, getAppsByTags, query]);

  useEffect(() => {
    setFilteredApps(getFilteredApps());
  }, [selectedTags, query, getFilteredApps]);

  const sectionTitle = isApplicationRunsOpen
    ? translate('datacosmos.applications.runs')
    : translate('datacosmos.applications.title');

  useEffect(() => {
    if (!isApplicationRunsOpen) {
      return;
    }
    const fetchStatus = async () => {
      setIsApplicationRunsLoading(true);
      const statusResult = await getApplicationRunStatus();
      if (statusResult) {
        setApplicationRuns(statusResult);
      }
      setIsApplicationRunsLoading(false);
    };

    void fetchStatus();
  }, [getApplicationRunStatus, isApplicationRunsOpen]);

  const getApplicationRunTitle = (application: IApplicationRunStatus) => {
    const isIngestion = Boolean(
      application?.inputs?.find((input) => input?.field === 'product-filename')
    );
    if (isIngestion) {
      translate('datacosmos.applications.satIngestion.title', {
        name: application?.name?.split('-')[0],
      });
    }
    return translateWithExplicitFallback(
      `datacosmos.applications.${application.name}.title` as unknown as TemplateStringsArray,
      application.name
    );
  };

  const renderApplicationRunsFragment = () => {
    if (applicationRuns?.length) {
      return applicationRuns?.map((application) => (
        <div
          key={`${application.name}-${application.started_at}`}
          className="grid grid-cols-3 bg-neutral dark:bg-neutral-dark my-2 p-3 gap-4"
        >
          <div className="col-span-2 flex gap-4">
            <div className="text-xs text-center flex items-center">
              <ProgressBar
                value={(eval(application.progress.toString()) * 100) as number}
                type="circular"
                valueLabel={application.progress}
                formatOptions={{ compactDisplay: 'short' }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <div className="font-bold capitalize">
                {getApplicationRunTitle(application)?.replaceAll('-', ' ')}
              </div>
              <div className="text-sm">
                {translate(
                  'datacosmos.applications.global.workflows.startedOn',
                  {
                    date: toFormattedDate(application?.started_at),
                    time: toFormattedTimeNoSeconds(
                      application?.started_at,
                      true
                    ),
                  }
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="uppercase text-sm">
              {translateWithExplicitFallback(
                `datacosmos.applications.global.workflows.status.${application.phase}` as unknown as TemplateStringsArray,
                application.phase
              )}
            </div>
            <div className="text-xs">
              {(() => {
                if (
                  application.phase === 'Running' &&
                  application?.estimated_duration_s
                ) {
                  return translate(
                    'datacosmos.applications.global.workflows.estimatedDuration',
                    {
                      time: parseEstimatedDuration(
                        application?.estimated_duration_s
                      ),
                    }
                  );
                }
                if (application?.finished_at) {
                  return translate(
                    'datacosmos.applications.global.workflows.finishedOn',
                    {
                      date: toFormattedDate(application.finished_at),
                      time: toFormattedTimeNoSeconds(
                        application.finished_at,
                        true
                      ),
                    }
                  );
                }
                return null;
              })()}
            </div>
          </div>
        </div>
      ));
    }
    return (
      <EmptyMessage
        title={translate('datacosmos.applications.noRuns')}
        icon="MagnifyingGlass"
      />
    );
  };

  const applicationsBody = isApplicationRunsOpen ? (
    <Fragment>{renderApplicationRunsFragment()}</Fragment>
  ) : (
    filteredApps.map((app) => {
      return <Fragment key={app.id}>{app.renderer(app)}</Fragment>;
    })
  );

  return (
    <>
      <SidebarHeader
        title={
          <div className="flex items-center gap-3">
            {isApplicationRunsOpen && (
              <Button
                icon={'ChevronLeft'}
                isMinimal
                isTransparent
                className={'p-0'}
                size="lg"
                onPress={() => setIsApplicationRunsOpen(false)}
              />
            )}
            <span>{sectionTitle}</span>
            {!isApplicationRunsOpen && (
              <Button
                icon="MagnifyingGlass"
                onPress={() => setIsSearchExpanded(!isSearchExpanded)}
                size={'lg'}
                isActive={isSearchExpanded}
                isMinimal
                isTransparent
              />
            )}
          </div>
        }
        middleElement={
          !isApplicationRunsOpen && (
            <Button
              icon="Clock"
              iconPlacement="left"
              isMinimal
              className={'bg-surface/50 dark:bg-surface-dark/50'}
              onPress={() => {
                setIsApplicationRunsOpen(true);
              }}
            >
              {translate('datacosmos.applications.previousRuns')}
            </Button>
          )
        }
        rightIcon={{
          iconName: 'ChevronRightDouble',
          onIconClick: () => {
            setActivePage(undefined);
            setIsApplicationRunsOpen(false);
          },
        }}
      />

      {isSearchExpanded && (
        <div
          className="absolute w-64 color-item p-3 flex flex-col gap-3"
          ref={sidebarRef}
          style={{
            left: '-256px',
            top: '60px',
          }}
        >
          <div className="flex items-center justify-between">
            <span>{translate('datacosmos.views.search')}</span>
            <Button
              icon="ChevronRightDouble"
              onPress={() => setIsSearchExpanded(false)}
              size={'lg'}
              isMinimal
              isTransparent
            />
          </div>
          <Input
            type="text"
            placeholder={translate(
              'datacosmos.applications.global.search.placeholder'
            )}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            className="border-item-contrast dark:border-item-dark-contrast"
          />

          <List
            selectionMode="multiple"
            onSelectionChange={(key) => {
              setSelectedTags([...key] as AppTags[]);
            }}
          >
            <Section title="Tags">
              {APP_TAGS_LIST.sort((a, b) => a.localeCompare(b)).map((tag) => (
                <Item key={tag}>
                  {capitalize(tag.valueOf().replace(/_/g, ' '))}
                </Item>
              ))}
            </Section>
          </List>
        </div>
      )}

      <div
        id="datacosmos-applications-sidebar-body"
        className={'overflow-y-auto'}
      >
        {isRunsLoading ? (
          <div className="flex justify-center items-center my-5">
            <Spinner size={36} />
          </div>
        ) : (
          applicationsBody
        )}
      </div>
    </>
  );
}
