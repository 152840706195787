import { Icon } from 'opencosmos-ui';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import type { StacItem } from 'datacosmos/types/stac-types';
import { Thumbnail } from '../ItemListCard/Thumbnail';
import useStacInfo from 'datacosmos/utils/hooks/useStacInfo';

interface Props {
  stacItem: StacItem;
}

const StacItemDetails = (props: Props) => {
  const { translate } = useLocalisation();
  const { cloudCoverage, sunElevationAngle, thumbnailAsset, stacItemPrice } =
    useStacInfo(props.stacItem);
  return (
    <div className="pt-2 border-t-2 border-neutral-300 dark:border-neutral-700 flex gap-8 justify-between">
      <ul className="flex flex-col gap-3">
        <li className="pl-2 flex items-center gap-2">
          <Icon icon="Satellite" className="align-middle" />{' '}
          <div className=" font-bold dark:text-accent text-accent-900">
            {translate(
              'datacosmos.tooltips.catalogAndItems.metadata.platformName'
            )}
            :
          </div>
          <span className="dark:text-neutral">{props.stacItem.title()}</span>
        </li>

        <li className="pl-2 flex items-center gap-2">
          <Icon icon="Sun" className="align-middle" />{' '}
          <div className=" font-bold dark:text-accent text-accent-900">
            {translate('datacosmos.tooltips.catalogAndItems.metadata.sza')}:
          </div>
          <span className="dark:text-neutral">{sunElevationAngle}</span>
        </li>

        <li className="pl-2 flex items-center gap-2">
          <Icon icon="Cloud" className="align-middle" />{' '}
          <div className=" font-bold dark:text-accent text-accent-900">
            {translate(
              'datacosmos.tooltips.catalogAndItems.metadata.cloudCoverage'
            )}
            :
          </div>
          <span className="dark:text-neutral">{cloudCoverage}</span>
        </li>

        <li className="pl-2 flex items-center gap-2">
          <Icon icon="Pin" className="align-middle" />{' '}
          <div className=" font-bold dark:text-accent text-accent-900">
            {translate('datacosmos.tooltips.catalogAndItems.metadata.location')}
            :
          </div>
          <span className="dark:text-neutral">{props.stacItem.location()}</span>
        </li>

        <li className="pl-2 flex items-center gap-2">
          <Icon icon="Cash" className="align-middle" />{' '}
          <div className=" font-bold dark:text-accent text-accent-900">
            {translate('datacosmos.tooltips.catalogAndItems.metadata.price')}:
          </div>
          <span className="dark:text-neutral">{stacItemPrice}</span>
        </li>
      </ul>
      <div>
        <Thumbnail displayAsSelected={false} thumbnailAsset={thumbnailAsset} />
      </div>
    </div>
  );
};

export default StacItemDetails;
