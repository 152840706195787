import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMapLayers } from './MapLayersProvider';
import { SingleBandSTACLayer } from 'datacosmos/entities/singleBandLayer';
import moment from 'moment';
import { useMapLayerStorage } from 'datacosmos/utils/hooks/useMapLayerStorage';
import { BandAlgebraSTACLayer } from 'datacosmos/entities/bandAlgebraLayer';
import { BASE_Z_INDEX } from './MapProvider';

type Props = {
  children: React.ReactNode;
};

export type TimeSeriesContext = ReturnType<typeof useTimeSeriesProvider>;

export const TimeSeriesContext = createContext<TimeSeriesContext>(
  undefined as unknown as TimeSeriesContext
);
export const useTimeSeries = () =>
  useContext<TimeSeriesContext>(TimeSeriesContext);

const useTimeSeriesProvider = () => {
  const { layers, replaceLayer } = useMapLayers();
  const { loadSavedLayers, saveCurrentLayers } = useMapLayerStorage();
  const [timeSeriesLayers, setTimeSeriesLayers] = useState<
    SingleBandSTACLayer[]
  >([]);

  /**
   * The layers that support time series, sorted by date from the latest to the earliest.
   * The new array is checked to see if it has the same layers and avoid unnecessary rerenders.
   */
  useEffect(() => {
    setTimeSeriesLayers((prev) => {
      const newLayers = (
        layers.filter(
          (l) =>
            l instanceof SingleBandSTACLayer ||
            l instanceof BandAlgebraSTACLayer
        ) as SingleBandSTACLayer[]
      ).sort(
        (a, b) =>
          moment(a.item.properties.datetime).unix() -
          moment(b.item.properties.datetime).unix()
      );
      const isEqual =
        prev.length === newLayers.length &&
        prev.every((prevLayer) =>
          newLayers.some((layer) => layer.id === prevLayer.id)
        );
      return isEqual ? prev : newLayers;
    });
  }, [layers]);

  const minMaxDates = useMemo(() => {
    const datesUnix = timeSeriesLayers.map((l) =>
      moment(l.item.properties.datetime).unix()
    );

    if (datesUnix.length === 0) {
      return undefined;
    }

    const minDate = moment.unix(Math.min(...datesUnix)).toDate();
    const maxDate = moment.unix(Math.max(...datesUnix)).toDate();

    return [minDate, maxDate];
  }, [timeSeriesLayers]);

  const [isTimeSeriesToggled, setIsTimeSeriesToggled] =
    useState<boolean>(false);
  const [dateRange, setDateRange] = useState<Date[] | undefined>();

  const hideLayersOutsideRange = useCallback(
    (date: Date[]) => {
      const [start, end] = date;
      timeSeriesLayers.map((l, index) => {
        const layer = l as SingleBandSTACLayer;
        if (
          !moment
            .utc(layer.item.properties.datetime)
            .isBetween(moment.utc(start), moment.utc(end), 'second', '[]')
        ) {
          replaceLayer(layer.cloneWithOptions({ visible: false }));
        } else {
          replaceLayer(
            layer.cloneWithOptions({
              visible: true,
              zIndex: BASE_Z_INDEX * (index + 2),
            })
          );
        }
      });
    },
    [replaceLayer, timeSeriesLayers]
  );

  useEffect(() => {
    if (isTimeSeriesToggled && dateRange) {
      hideLayersOutsideRange(dateRange);
    }
  }, [isTimeSeriesToggled, dateRange, hideLayersOutsideRange]);

  const isEnabled = useMemo(
    () =>
      timeSeriesLayers.length > 0 &&
      minMaxDates?.[0]?.getTime() !== minMaxDates?.[1]?.getTime(),
    [minMaxDates, timeSeriesLayers.length]
  );

  const hideNonTimeSeriesLayers = useCallback(() => {
    layers
      .filter(
        (l) =>
          !(l instanceof SingleBandSTACLayer) &&
          !(l instanceof BandAlgebraSTACLayer)
      )
      .map((l) => replaceLayer(l.cloneWithOptions({ visible: false })));
  }, [layers, replaceLayer]);

  const removeTimeSeries = useCallback(() => {
    const [minDate, maxDate] = minMaxDates ?? [];
    saveCurrentLayers();
    hideNonTimeSeriesLayers();
    if (minDate && maxDate) {
      setDateRange([minDate, minDate]);
    }
    setIsTimeSeriesToggled(false);
  }, [minMaxDates, hideNonTimeSeriesLayers, saveCurrentLayers]);

  const toggleTimeSeries = () => {
    if (!isTimeSeriesToggled) {
      removeTimeSeries();
    } else {
      loadSavedLayers();
      unhideAllTimeseriesLayers();
      setDateRange(undefined);
    }
    setIsTimeSeriesToggled(!isTimeSeriesToggled);
  };

  useEffect(() => {
    if (!isEnabled && isTimeSeriesToggled) {
      removeTimeSeries();
    }
  }, [isEnabled, isTimeSeriesToggled, removeTimeSeries]);

  const unhideAllTimeseriesLayers = () => {
    timeSeriesLayers.map((l) => {
      const layer = l as SingleBandSTACLayer;
      replaceLayer(layer.cloneWithOptions({ visible: true }));
    });
  };

  // Save the current layers when the time series layers change.
  // This keeps the layers added or removed during time series when the
  // time series is toggled off.
  useEffect(() => {
    saveCurrentLayers();
  }, [saveCurrentLayers, timeSeriesLayers]);

  useEffect(() => {
    if (isTimeSeriesToggled) {
      const [newMinDate, newMaxDate] = minMaxDates ?? [];
      // Clip the selected range to the new min and max dates
      setDateRange((prev) => {
        if (!prev) {
          return [newMinDate, newMinDate];
        }
        if (!newMinDate || !newMaxDate) {
          return prev;
        }
        // When the new minMaxDates is after the current range the max newRangeEnd is the newMinDate
        const newRangeEnd = prev[1] < newMinDate ? newMinDate : prev[1];
        const newValue = [
          moment.utc(prev[0]).isBetween(newMinDate, newMaxDate)
            ? prev[0]
            : newMinDate,
          moment.utc(prev[1]).isBetween(newMinDate, newMaxDate)
            ? prev[1]
            : newRangeEnd,
        ];
        return newValue;
      });
    }
  }, [minMaxDates, isTimeSeriesToggled]);

  return {
    toggleTimeSeries,
    /**
     * If the time series button is toggled, then the time series is shown.
     */
    isTimeSeriesToggled,
    /**
     * If there is at least one layer that supports time series, then the time series button is enabled.
     */
    isEnabled,
    minMaxDates,
    timeSeriesLayers,
    dateRange,
    setDateRange,
    hideLayersOutsideRange,
  };
};

const TimeSeriesProvider = ({ children }: Props) => {
  return (
    <TimeSeriesContext.Provider value={useTimeSeriesProvider()}>
      {children}
    </TimeSeriesContext.Provider>
  );
};

export default TimeSeriesProvider;
