import DrawAreaOfInterest from 'datacosmos/components/DrawAreaOfInterest';
import UploadRegion from 'datacosmos/components/UploadRegion';
import { LayerSourceType } from 'datacosmos/entities/layer';
import { useApplicationCatalog } from 'datacosmos/stores/ApplicationCatalogContext';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import type { IApplication, IAPIAppValue } from 'datacosmos/types/applications';
import { AppTags } from 'datacosmos/types/applications';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import DatePicker from '_molecules/DatePicker/DatePicker';
import OpenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/OpenedAppCard';
import UnopenedAppCard from 'datacosmos/components/Applications/SubscriptionApps/Common/UnopenedAppCard';
import { parseDate } from '@internationalized/date';
import { Item } from 'react-stately';
import Select2 from '_molecules/Select2/Select2';
import { btoaSafe } from 'utils/common/btoaSafe';
import { postTicket } from '_api/tickets/service';
import { useActivePage } from 'datacosmos/components/Toolbar/ActivePageProvider';
import { Button } from 'opencosmos-ui';

const DATACOSMOS_IMG = '/images/datacosmos/';

type IProps = {
  app: IApplication;
};

export const QuasarPrecisionAgricultureApp: IApplication = {
  get id() {
    return btoaSafe(
      JSON.stringify(
        this.name + this.provider + this.description + this.appScreenshotUrl
      ).substring(0, 75)
    );
  },
  name: 'Precision agriculture',
  description:
    'Quasar Science Resources provides maps of vegetation indices such as NDVI, SAVI, and others. Time series of key indicators are developed to support the management of crops, including change and phenology, pest and disease detection, stress and drought. Quasar Science Resources has developed a model for the analysis and monitoring of soil moisture with radar and optical data from the Sentinel-1 and Sentinel-2 satellites. The model produces soil moisture maps with a time frequency dependent on revisit time with a spatial resolution of 10 meters, which enables farm managers to monitor the moisture continuously, and therefore, efficiently manage irrigation, as well as to plan crops based on historical data of local soil moisture.',
  shortDescription:
    'Applications include: Phenology monitoring, Pest and disease monitoring, Stress and drought monitoring, Soil moisture monitoring, Vegetation indices for crop management',
  provider: {
    name: 'Quasar Science Resources, S.L.',
    description: '',
    id: 12,
    url: 'https://quasarsr.com/',
    icon_url: DATACOSMOS_IMG + 'quasar_logo.png',
  },
  appScreenshotUrl: DATACOSMOS_IMG + 'quasar_agriculture_screenshot.jpg',
  frequencyOfData: 'Between 1 and 7 images per week',
  price: 'Contact us',
  fee: 'Cost Details: Number of images to be analysed and whether any kind of technical report is needed or not to accompany the images.',
  inputs: [
    {
      field: 'AOI',
      example: '',
    },
    {
      field: 'startDate',
      example: '',
    },
    {
      field: 'endDate',
      example: '',
    },
    {
      field: 'analysisType',
      example: '',
    },
  ],
  values: {
    AOI: { value: undefined, isError: false, message: '' },
    startDate: { value: undefined, isError: false, message: '' },
    endDate: { value: undefined, isError: false, message: '' },
    analysisType: { value: undefined, isError: false, message: '' },
  },
  renderer: (app: IApplication) => <QuasarPrecisionAgriculture app={app} />,
  leadTime:
    'This will depend on the product and satellite pass. For single images, urgent requests can be done in less than 48 hours after satellite overpass, but should be reflected on price. Some products, like soil moisture, will take more time to deliver the first time as the area needs to be studied. After that, images can be provided within a few days.',
  tags: [AppTags.agriculture],
  acronym: 'QPA',
};

const ANALYSIS_TYPES = [
  'Phenology monitoring',
  'Pest and disease monitoring',
  'Stress and drought monitoring',
  'Soil moisture monitoring',
  'Vegetation indices for crop management',
];

const QuasarPrecisionAgriculture = ({ app }: IProps) => {
  const [isAppOpened, setIsAppOpened] = useState<boolean>(false);
  const {
    setApplicationAOIs: setQuasarAOI,
    applicationAOIs: quasarAOI,
    setInputData,
    toggleAppInstall,
    getInstalledStatus,
    shouldAutoOpen,
    setSelectedInstalledApp,
  } = useApplicationCatalog();

  const { activePage, setActivePage } = useActivePage();
  const { removeLayersBySourceType } = useMapLayers();

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [analysisType, setAnalysisType] = useState<string>();

  const setValue = useCallback(
    (key: string, value: IAPIAppValue['value']) => {
      setInputData(app.name, {
        ...app.values,
        [key]: { value, isError: false, message: '' },
      });
    },
    [app.name, app.values, setInputData]
  );

  useEffect(() => {
    setValue('startDate', startDate);
    setValue('AOI', quasarAOI);
    setValue('analysisType', analysisType);
    setValue('endDate', endDate);
  }, [startDate, quasarAOI, analysisType, endDate, setValue]);

  const inputs = (): JSX.Element => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div>
          <label htmlFor="aoi">Area of interest: </label>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '2fr 2fr 0.2fr',
              gap: '5px',
            }}
            id="aoi"
          >
            <DrawAreaOfInterest
              aoiSourceType={LayerSourceType.APPLICATION_AOI}
              setAreasOfInterest={setQuasarAOI}
              buttonForApplications
              multipleAois
            />

            <UploadRegion
              aoiSourceType={LayerSourceType.APPLICATION_AOI}
              setAreaOfInterest={setQuasarAOI}
              buttonForApplications
              buttonTitle="Upload"
              multipleAois
            />

            <Button
              icon="Trash"
              size={'lg'}
              onPress={() => {
                setQuasarAOI([]);
                removeLayersBySourceType(LayerSourceType.APPLICATION_AOI);
              }}
              className="justify-self-center self-center"
              isMinimal
              isTransparent
            />
          </div>
        </div>

        <div id="startDate">
          <DatePicker
            fill
            label="Start date: "
            onChange={(d) => setStartDate(d?.toDate('Etc/UTC'))}
            maxValue={parseDate(moment().add(10, 'years').format('YYYY-MM-DD'))}
          />
        </div>

        <div id="endDate">
          <DatePicker
            fill
            label="End date: "
            onChange={(d) => setEndDate(d?.toDate('Etc/UTC'))}
            maxValue={parseDate(moment().add(10, 'years').format('YYYY-MM-DD'))}
          />
        </div>

        <div>
          <Select2
            onSelectionChange={(item) => setAnalysisType(item.toString())}
            fill
            label={<label htmlFor="analysis">Analysis type: </label>}
            selectedItemClassName="border-2 border-item"
          >
            {ANALYSIS_TYPES.map((item) => (
              <Item key={item}>{item}</Item>
            ))}
          </Select2>
        </div>
      </div>
    );
  };

  const formatValuesForBody = () => {
    let body = '';

    for (const [field, value] of Object.entries(app.values)) {
      body += `${field}: ${JSON.stringify(value.value)}%0D%0A`;
    }

    return body;
  };

  const handleSubmit = () => {
    let mailBody = `ENTER EXTRA DETAILS HERE%0D%0A----------------------------%0D%0A`;

    mailBody += formatValuesForBody();

    void postTicket({
      body: {
        title: `DataCosmos Subscription to ${app.name}`,
        description: mailBody,
        team: 'datacosmos',
      },
    });
  };

  if (shouldAutoOpen || (isAppOpened && getInstalledStatus(app))) {
    return (
      <OpenedAppCard
        app={app}
        inputsRenderer={inputs}
        setIsAppOpened={setIsAppOpened}
        handleSubmit={handleSubmit}
        isInstalled={getInstalledStatus(app)}
        toggleAppInstall={toggleAppInstall}
      />
    );
  }

  return (
    <UnopenedAppCard
      app={app}
      setIsAppOpened={setIsAppOpened}
      toggleAppInstall={toggleAppInstall}
      isInstalled={getInstalledStatus(app)}
      setSelectedInstalledApp={(a) => {
        setSelectedInstalledApp(a);
        activePage === 'application' && setActivePage(undefined);
      }}
    />
  );
};
