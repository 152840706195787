import area from '@turf/area';
import intersect from '@turf/intersect';
import union from '@turf/union';
import type { GeoJSONLayer } from 'datacosmos/entities/geojsonLayer';
import type { SwathLayer } from 'datacosmos/entities/SwathLayer';
import type { CommonOpportunity } from '_api/tasking/helpers';

export const isPoint = (data: GeoJSON.GeoJSON) => {
  if (data.type === 'Feature') {
    return data.geometry.type === 'Point';
  }
  return data.type === 'Point';
};

export const isFeatureCollectionArray = (
  collection: ({ features?: unknown } | undefined)[]
): collection is GeoJSON.FeatureCollection[] => {
  return collection.every((value) => Boolean(value?.features));
};

export const isFeatureArray = (
  feature: ({ type?: string } | undefined)[]
): feature is GeoJSON.Feature[] => {
  return feature.every((feat) => feat?.type === 'Feature');
};

type Swath =
  | SwathLayer<CommonOpportunity>
  | GeoJSONLayer<unknown>
  | GeoJSON.Feature;

export const getTotalArea = (swath: Swath[]): number => {
  return parseFloat(
    Number(
      swath.reduce((acc, _, i, arr) => {
        const geojson = (arr[i] as SwathLayer<CommonOpportunity> | undefined)
          ?.data
          ? (arr[i] as SwathLayer<CommonOpportunity>).data
          : arr[i];
        acc += area(geojson as GeoJSON.Feature) / 1000000;
        return acc;
      }, 0)
    ).toFixed(2)
  );
};

export const unionizeFeatureCollections = (
  swathFeatures: GeoJSON.FeatureCollection<GeoJSON.Polygon>[]
) => {
  const featureArrays = swathFeatures.map((featureCol) => featureCol.features);
  const features = featureArrays.map((featArr) =>
    // @ts-expect-error
    featArr.reduce((acc, feat) => {
      return union(acc.geometry, feat.geometry);
    })
  );

  if (features.length === 0) {
    return undefined;
  }
  const swathUnion =
    // @ts-expect-error
    features.reduce((acc, feat) => {
      return union(acc.geometry, feat.geometry);
    });

  return swathUnion;
};

export const unionizeFeatures = (
  swathFeatures: GeoJSON.Feature<GeoJSON.Polygon>[]
) => {
  const feature: GeoJSON.Feature<GeoJSON.Polygon> = {
    type: 'Feature',
    geometry: { type: 'Polygon', coordinates: [] },
    properties: {},
  };

  //@ts-expect-error
  return swathFeatures.reduce((acc, value) => {
    return union(acc.geometry, value.geometry);
  }, feature);
};

export const getUnion = (
  swathFeatures: GeoJSON.FeatureCollection<GeoJSON.Polygon>[]
) => {
  if (isFeatureCollectionArray(swathFeatures)) {
    return unionizeFeatureCollections(swathFeatures);
  }

  if (isFeatureArray(swathFeatures)) {
    return unionizeFeatures(swathFeatures);
  }

  return null;
};

export const getJoinedOverlapingArea = (
  confirmedSwaths: SwathLayer<CommonOpportunity>[] | GeoJSONLayer<unknown>[]
) => {
  const swathFeatures = confirmedSwaths.map((swath) => swath.data);

  //@ts-expect-error
  const unionOfSwaths = getUnion(swathFeatures);

  return unionOfSwaths
    ? parseFloat(Number(area(unionOfSwaths) / 1000000).toFixed(2))
    : 0;
};

export const getRegionOfInterestCoveragePercent = (
  swathFeatures: GeoJSON.Feature<GeoJSON.Polygon>[],
  regionOfInterestFeatures: GeoJSON.Feature<
    GeoJSON.Polygon | GeoJSON.MultiPolygon
  >[]
) => {
  if (swathFeatures.length === 0) {
    return 0;
  }
  const swathUnion = unionizeFeatures(swathFeatures);

  const intersectedRegions = regionOfInterestFeatures.filter((roi) =>
    intersect(roi, swathUnion.geometry)
  );

  const unionRoiIntersect = intersectedRegions.reduce<GeoJSON.Feature[]>(
    (acc, region) => {
      const result = intersect(region, swathUnion.geometry);
      if (result !== null) acc.push(result);
      return acc;
    },
    []
  );

  if (unionRoiIntersect.length > 0) {
    const roiArea = getTotalArea(intersectedRegions);
    const intersectArea = getTotalArea(unionRoiIntersect);
    return Math.min(
      100,
      parseFloat(Number((100 * intersectArea) / roiArea).toFixed(2))
    );
  }

  return 0;
};

export const getRegionOfInterestCoverageArea = (
  swathFeatures: GeoJSON.Feature[],
  regionOfInterestFeatures: GeoJSON.Feature<
    GeoJSON.Polygon | GeoJSON.MultiPolygon
  >[]
) => {
  if (swathFeatures.length === 0) {
    return 0;
  }
  // @ts-expect-error
  const swathUnion = getUnion(swathFeatures);

  const intersectedRegions = regionOfInterestFeatures.filter((roi) =>
    intersect(roi, swathUnion?.geometry as unknown as GeoJSON.Polygon)
  );

  const unionRoiIntersect = intersectedRegions.map((region) =>
    intersect(
      region,
      swathUnion?.geometry as GeoJSON.Polygon | GeoJSON.MultiPolygon
    )
  );

  if (unionRoiIntersect.length > 0) {
    const intersectArea = getTotalArea(unionRoiIntersect as Swath[]);

    return parseFloat(Number(intersectArea).toFixed(2));
  }
  return 0;
};

export const isGeometryCollection = (geojson: {
  type: string;
}): geojson is GeoJSON.GeometryCollection => {
  return geojson.type === 'GeometryCollection';
};

export const geometryCollectionToGeometry = (
  geometryCol: GeoJSON.GeometryCollection
) => {
  const reduced = (geometryCol.geometries as GeoJSON.Polygon[]).reduce(
    (acc, col) => {
      if (!acc.coordinates) {
        acc = col;
      } else {
        acc = {
          type: acc.type,
          coordinates: [...acc.coordinates, ...col.coordinates],
        };
      }

      return acc;
    },
    {} as GeoJSON.Polygon
  );

  return reduced;
};

/**
 * getLargestPolygonFromMultipolygon takes a multi polygon and returns the largest one
 * @param multiPoly GeoJSON.MultiPolygon
 * @returns GeoJSON.Polygon
 */
export const getLargestPolygonFromMultipolygon = (
  multiPoly: GeoJSON.MultiPolygon
) => {
  const polygons: GeoJSON.Polygon[] = multiPoly.coordinates.map((poly) => ({
    type: 'Polygon',
    coordinates: poly,
    properties: {},
  }));

  const largest = polygons.sort((a, b) => {
    return area(b) - area(a);
  })[0];

  return largest;
};

export const polygonToFeature = (
  poly: GeoJSON.Polygon
): GeoJSON.Feature<GeoJSON.Polygon> => {
  return {
    geometry: poly,
    properties: {},
    type: 'Feature',
  };
};

export const polygonToFeatureCollection = (
  poly: GeoJSON.Polygon
): GeoJSON.FeatureCollection<GeoJSON.Polygon> => {
  return {
    features: [{ geometry: poly, properties: {}, type: 'Feature' }],
    type: 'FeatureCollection',
  };
};
/**
 * geoJsonFeatureToGeoJsonFeatureCollection takes a GeoJSON.Feature and returns a GeoJSON.FeatureCollection
 * @param feat feature to convert to feature collection
 * @returns GeoJSON.FeatureCollection
 */
export const geoJsonFeatureToGeoJsonFeatureCollection = (
  feat: GeoJSON.Feature
): GeoJSON.FeatureCollection => {
  return {
    type: 'FeatureCollection',
    features: [feat],
  };
};

/**
 * Returns the two most southern points from a list of coordinates, ordered by longitude.
 *
 * @param coordinates - An array of coordinate pairs, where each pair is an array of two numbers [longitude, latitude].
 * @returns An array containing the two most southern points, ordered by longitude.
 */
export const getTwoMostSouthernPointsOrdered = (
  coordinates: GeoJSON.Position[]
): GeoJSON.Position[] => {
  const sortedByLatitude = [...coordinates].sort((a, b) => a[1] - b[1]);
  const twoMostSouthernPoints = sortedByLatitude.slice(0, 2);
  return twoMostSouthernPoints.sort((a, b) => a[0] - b[0]);
};
