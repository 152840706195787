import React from 'react';
import type { TaskingRequest } from '_api/tasking/types';
import { Icon, Tooltip } from 'opencosmos-ui';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import { getAOICoverageData } from 'datacosmos/utils/taskingRequests';
import classNames from 'classnames';

type RequestAOICoverageProps = {
  request: TaskingRequest;
  showIcon?: boolean;
  className?: string;
};

const RequestAOICoverage = ({
  request,
  showIcon,
  className,
}: RequestAOICoverageProps) => {
  const {
    coverageValue,
    coveragePercent,
    coverageValueCompleted,
    coveragePercentCompleted,
    coverageValuePlanned,
    coveragePercentPlanned,
  } = getAOICoverageData(request);
  return (
    <Tooltip
      content={
        <CoverageTooltip
          coverageValueCompleted={coverageValueCompleted}
          coveragePercentCompleted={coveragePercentCompleted}
          coverageValuePlanned={coverageValuePlanned}
          coveragePercentPlanned={coveragePercentPlanned}
        />
      }
    >
      <span className={classNames(className, 'flex items-center gap-2')}>
        {showIcon && <Icon icon="Coverage" />}
        {`${coverageValue ?? '-'} km² (${coveragePercent ?? ''}%)`}
      </span>
    </Tooltip>
  );
};

type CoverageTooltipProps = {
  coverageValueCompleted: number | undefined;
  coveragePercentCompleted: number | undefined;
  coverageValuePlanned: number | undefined;
  coveragePercentPlanned: number | undefined;
};

const CoverageTooltip = ({
  coverageValueCompleted,
  coveragePercentCompleted,
  coverageValuePlanned,
  coveragePercentPlanned,
}: CoverageTooltipProps) => {
  const { translate } = useLocalisation();
  return (
    <section>
      <h3 className="border-b border-b-item-dark-constrast dark:border-b-item-constrast mb-1">
        {translate('datacosmos.tasking.legend.roiCoverageDetails')}
      </h3>
      <ul>
        <li className="flex gap-4 justify-between">
          <div>
            {translate('datacosmos.tasking.legend.roiCoverageCompleted')}:
          </div>
          <div>{`${coverageValueCompleted ?? '-'} km² (${
            coveragePercentCompleted ?? ''
          }%)`}</div>
        </li>
        <li className="flex gap-4 justify-between">
          <div>
            {translate('datacosmos.tasking.legend.roiCoveragePlanned')}:
          </div>
          <div>{`${coverageValuePlanned ?? '-'} km² (${
            coveragePercentPlanned ?? ''
          }%)`}</div>
        </li>
      </ul>
    </section>
  );
};

export default RequestAOICoverage;
