import MockAdapter from 'axios-mock-adapter';
import groundStations from './groundStations.json';
import launchOpportunities from './launchOpportunities.json';
import mission from './mission.json';
import type { AxiosInstance } from 'axios';

export default (axios: AxiosInstance): void => {
  //@ts-expect-error
  const mock = new MockAdapter(axios);
  mock.onGet('/msd/v1/ground_stations').reply(200, {
    groundStations: [...groundStations.list],
  });
  mock.onGet('/msd/v1/launch_opportunities').reply(200, {
    launchOpportunities: [...launchOpportunities.list],
  });
  mock.onPost('/msd/v1/compute').reply(200);
  mock.onPost('/msd/v1/start').reply(200);
  mock.onGet(new RegExp(`/msd/v1/mission/*`)).reply(200, mission);
};
