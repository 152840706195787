import type { TaskingRequest } from '_api/tasking/types';
import {
  getRegionOfInterestCoverageArea,
  getRegionOfInterestCoveragePercent,
} from './geojson';
import { generateSwathsForActivity } from 'datacosmos/components/Tasking/RequestViewing/displaySwaths';
import { JsonSchema } from '_api/telecommands/types';

export function getAOICoverageData(request: TaskingRequest) {
  const swathLayers = request.activities
    .filter(
      (activity) =>
        !['FAILED', 'CANCELLED', 'EXPIRED'].includes(activity.status)
    )
    .map((activity) => generateSwathsForActivity(activity, true))
    .reduce((prev, current) => {
      return [...prev, ...current];
    }, []);

  const swathLayersCompleted = request.activities
    .filter((activity) => activity.status === 'COMPLETED')
    .map((activity) => generateSwathsForActivity(activity, true))
    .reduce((prev, current) => {
      return [...prev, ...current];
    }, []);

  const coverageValue = getRegionOfInterestCoverageArea(
    swathLayers.map(
      (calcSwath) => calcSwath.data
    ) as GeoJSON.Feature<GeoJSON.Polygon>[],
    [request.region]
  );

  const coveragePercent = getRegionOfInterestCoveragePercent(
    swathLayers.map(
      (calcSwath) => calcSwath.data
    ) as GeoJSON.Feature<GeoJSON.Polygon>[],
    [request.region]
  );

  const coverageValueCompleted = getRegionOfInterestCoverageArea(
    swathLayersCompleted.map(
      (calcSwath) => calcSwath.data
    ) as GeoJSON.Feature<GeoJSON.Polygon>[],
    [request.region]
  );

  const coveragePercentCompleted = getRegionOfInterestCoveragePercent(
    swathLayersCompleted.map(
      (calcSwath) => calcSwath.data
    ) as GeoJSON.Feature<GeoJSON.Polygon>[],
    [request.region]
  );

  return {
    coverageValue: coverageValue,
    coveragePercent: coveragePercent,
    coverageValueCompleted,
    coveragePercentCompleted,
    coverageValuePlanned: coverageValue - coverageValueCompleted,
    coveragePercentPlanned: coveragePercent - coveragePercentCompleted,
  };
}

type MissionConfig = {
  IMAGE_ACQUISITION: JsonSchema;
};

/**
 * Retrieves the processing levels from the mission configuration data.
 * If no processing levels are available, returns `undefined`.
 *
 * @param data - The mission configuration data.
 * @returns An array of processing levels if available, otherwise `undefined`.
 */
export function getProcessingLevelsFromMissionConfig(
  data: MissionConfig | undefined
) {
  if (!data?.IMAGE_ACQUISITION) {
    return undefined;
  }
  const processingLevels =
    data.IMAGE_ACQUISITION.definitions?.groundProcessingParameters?.properties
      ?.processing_level?.enum;
  return processingLevels ?? undefined;
}

export function getCommonProcessingLevels(data: (MissionConfig | undefined)[]) {
  return data.reduce<(string | undefined)[]>((acc, curr, index) => {
    const levels: (string | undefined)[] = getProcessingLevelsFromMissionConfig(
      curr
    ) ?? [undefined];
    if (index === 0) return levels;
    return acc.filter((level) => levels.includes(level));
  }, []);
}
