import type {
  Instrument,
  OZAConstraints,
  SZAConstraints,
  ManualOpportunity,
  RollAngleConstraint,
  ActivitySchema,
  ActivityPriority,
  CloudCoverConstraint,
} from '_api/tasking/types';
import {
  postManualTaskingSearch,
  postSwathSearch,
  type SearchOpportunityRegions,
} from '_api/tasking/service';
import {
  geometryCollectionToGeometry,
  isGeometryCollection,
  getRegionOfInterestCoveragePercent,
} from 'datacosmos/utils/geojson';
import rewind from '@turf/rewind';
import type { SensorId } from '_api/sensors/types';
import moment from 'moment';
import {
  isoStringToMomentObject,
  jsDateToMomentObj,
  momentObjToISOString,
} from 'utils/common/dateUtils';
import type { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { ISO_FORMAT, ISO_FORMAT_NO_MILLISECONDS } from 'constants/datetime';
import type { ActivityParameters } from '_api/activities/types';
import type { Position } from 'geojson';

export const getMsdAoi = (
  instruments: Instrument[],
  regions: PolygonLayer[]
) => {
  const areaOfInterestDataForInternal = regions.map((region) => {
    return {
      name: region.name,
      geojson: rewind({
        type: (region.data as GeoJSON.Feature).type,
        geometry: (region.data as GeoJSON.Feature).geometry as GeoJSON.Polygon,
        properties: {},
      }),
    };
  });
  return [
    ...new Set(
      instruments
        .map(({ satellite }) => satellite.name)
        .map(() => areaOfInterestDataForInternal)
        .flat()
    ),
  ];
};

const getMsdInstruments = (instruments: Instrument[]) => {
  return instruments.map((value) => ({
    mission_id: value.satellite.mission_id,
    sensor_id: value.sensor?.sensorId,
  }));
};

export type SwathControlData = {
  duration: { start: Date; end: Date };
  rotation: number;
  coverage: number;
  area: number;
  sza?: number;
  oza?: number;
  parameters: ActivityParameters;
  priority?: ActivityPriority;
};

export type GetSwathWithControlDataParams = {
  instruments: Instrument[];
  regions: PolygonLayer[]; // TODO: Deprecate use of class based layers
  swathControlData: SwathControlData;
  projectId: string;
};

export const getSwathWithControlData = async ({
  instruments,
  regions,
  swathControlData,
  projectId,
}: GetSwathWithControlDataParams) => {
  const msdAoi = getMsdAoi(instruments, regions);
  const msdInstruments = getMsdInstruments(instruments);
  const { data: swathSearchData } = await postSwathSearch({
    body: {
      area_of_interest: msdAoi[0],
      instrument: msdInstruments[0],
      roll_angle: swathControlData.rotation,
      start: momentObjToISOString(
        jsDateToMomentObj(swathControlData.duration.start),
        ISO_FORMAT_NO_MILLISECONDS
      ),
      stop: momentObjToISOString(
        jsDateToMomentObj(swathControlData.duration.end),
        ISO_FORMAT_NO_MILLISECONDS
      ),
      project_id: projectId,
    },
  });
  return swathSearchData;
};

// Controlls the decimal point to which swath roll angle will be rounded to
export const ROLL_ANGLE_ROUNDOFF = 1;

export type OpportunityCloudCoverage = {
  min_cloud_cover: {
    location: {
      type: 'Point';
      coordinates: number[];
    };
    forecast: {
      cloud_cover_pc: number;
    };
  };
  max_cloud_cover: {
    location: {
      type: 'Point';
      coordinates: number[];
    };
    forecast: {
      cloud_cover_pc: number;
    };
  };
};
export type CommonOpportunity = {
  Kind: 'internal' | 'external'; // TODO: This kind seems to be from the old API, should it be removed?
  OpportunityIndex?: number; // TODO: Is this param realy used? Seems to be always undefined
  Start: string;
  End: string;
  OrbitNumber: string;
  Id: number;
  FieldOfRegard: {
    Footprint: {
      Geojson: GeoJSON.Feature | GeoJSON.FeatureCollection; // TODO: Is this a bug? Should this never be a collection?
    };
  };
  RollAngle: string;
  Duration: number;
  SatelliteId: string;
  Benchmark: {
    CloudCoverage?: OpportunityCloudCoverage;
    SunGlint?: number;
    Coverage?: number;
  };
  Parameters: ActivityParameters;
  ProcessingLevel?: string;
  Priority?: ActivityPriority;
  Oza?: number;
  Sza?: number;
  Area?: number;
  RegionId?: string;
  RollSteering: [number, number];
  SensorId: SensorId;
  Schema?: ActivitySchema;
  AvailablePriorities?: {
    base_price_per_km2_gbp: string;
    priorities: ActivityPriority[];
  };
};

export const manualOpportunityToCommonOpportunity = (
  opportunity: ManualOpportunity
): CommonOpportunity => {
  return {
    Kind: 'internal',
    Id: Math.random() * 1000000,
    RollAngle: opportunity.suggested_roll.toString(),
    SensorId: opportunity.imager_id,
    RegionId: opportunity.region_id,
    Start: opportunity.start,
    End: opportunity.stop,
    Benchmark: {
      CloudCoverage:
        opportunity.cloud_coverage === null
          ? undefined
          : opportunity.cloud_coverage,
      Coverage: undefined,
      SunGlint:
        opportunity.sun_glint === null ? undefined : opportunity.sun_glint,
    },
    Area: undefined,
    Duration: moment
      .duration(moment(opportunity.stop).diff(opportunity.start))
      .asSeconds(),
    FieldOfRegard: {
      Footprint: { Geojson: opportunity.field_of_regard.footprint.geojson },
    },
    OpportunityIndex: undefined,
    Oza: opportunity.oza === null ? undefined : opportunity.oza,
    Sza: opportunity.sza === null ? undefined : opportunity.sza,
    OrbitNumber: '-',
    RollSteering: opportunity.roll_steering,
    SatelliteId: opportunity.mission_id,
    Schema: opportunity.schema.activity_parameters,
    Parameters: {
      platform: {
        roll_angle: Number(Math.round(opportunity.suggested_roll).toFixed(2)),
      },
      imager: {
        name: opportunity.imager_id,
      },
    },
  };
};

export type SearchTaskingOpportunitiesParams = {
  instruments: Instrument[];
  regions: PolygonLayer[]; // TODO: Deprecate use of class based layers
  dateFrom: Date;
  dateTo: Date;
  szaConstraint: SZAConstraints;
  ozaConstraint: OZAConstraints;
  isOZA: boolean;
  isSZA: boolean;
  isRollAngle: boolean;
  isCloud: boolean;
  rollAngleConstraint: RollAngleConstraint;
  cloudConstraint: CloudCoverConstraint;
  projectId: string;
};

export const convertToMultiPolygonAoi = (
  formattedRegions: SearchOpportunityRegions
) => {
  const multiPolygon: GeoJSON.Feature<GeoJSON.MultiPolygon> = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'MultiPolygon',
      coordinates: formattedRegions.map(
        (feature) => feature.geojson.geometry.coordinates as Position[][]
      ),
    },
  };
  return multiPolygon;
};

export const searchTaskingOpportunities = async ({
  instruments,
  regions,
  dateFrom,
  dateTo,
  szaConstraint,
  ozaConstraint,
  isOZA,
  isSZA,
  isRollAngle,
  rollAngleConstraint,
  isCloud,
  cloudConstraint,
  projectId,
}: SearchTaskingOpportunitiesParams): Promise<CommonOpportunity[]> => {
  regions.map((region) => {
    const feature = region.data as GeoJSON.Feature;
    const polygon = feature.geometry as GeoJSON.Polygon;
    const points = isGeometryCollection(polygon)
      ? geometryCollectionToGeometry(polygon).coordinates.flat()
      : polygon.coordinates[0];
    const polygonPoints = points
      .map((point) => point[1] + ' ' + point[0])
      .join(', ');
    return `POLYGON((${polygonPoints}))`;
  });

  const startDate = moment(dateFrom).startOf('day').format(ISO_FORMAT);
  const endDate = moment(dateTo).endOf('day').format(ISO_FORMAT);

  const msdInstruments = getMsdInstruments(instruments);

  const msdAoi = getMsdAoi(instruments, regions);

  const msdConstraints = [
    ...new Set(instruments.map(({ satellite }) => satellite.name)),
  ]
    .map(() => {
      const constraints = [];
      if (isRollAngle) {
        constraints.push({
          min: rollAngleConstraint.min,
          max: rollAngleConstraint.max,
          type: rollAngleConstraint.type,
        });
      }
      if (isSZA) {
        constraints.push({
          min: szaConstraint.Min,
          max: szaConstraint.Max,
          type: szaConstraint.Type,
        });
      }
      if (isOZA) {
        constraints.push({
          min: ozaConstraint.Min,
          max: ozaConstraint.Max,
          type: ozaConstraint.Type,
        });
      }
      if (isCloud) {
        constraints.push({
          min: cloudConstraint.min,
          max: cloudConstraint.max,
          type: cloudConstraint.type,
        });
      }
      return constraints;
    })
    .flat();

  const { data: manualTaskingSearchData } = await postManualTaskingSearch({
    body: {
      instruments: msdInstruments,
      areas_of_interest:
        regions.length > 1
          ? [
              {
                name: regions[0].name,
                geojson: convertToMultiPolygonAoi(msdAoi),
              },
            ]
          : msdAoi,
      constraints: msdConstraints,
      start: startDate,
      stop: endDate,
    },
  });
  if (!manualTaskingSearchData) return [];
  const manualOpportunities = manualTaskingSearchData.map(
    manualOpportunityToCommonOpportunity
  );
  const swathsRequests: ReturnType<typeof postSwathSearch>[] = [];
  manualOpportunities.forEach((opportunity, index) => {
    swathsRequests.push(
      postSwathSearch({
        body: {
          area_of_interest: {
            name: '',
            geojson: {
              type: 'Feature',
              geometry:
                manualTaskingSearchData[index].field_of_regard.footprint.geojson
                  .geometry,
              properties: {},
            },
          },
          instrument: {
            mission_id: opportunity.SatelliteId,
            sensor_id: manualTaskingSearchData[index].imager_id,
          },
          roll_angle: parseFloat(
            Number(opportunity.RollAngle).toFixed(ROLL_ANGLE_ROUNDOFF)
          ),
          start: momentObjToISOString(
            isoStringToMomentObject(opportunity.Start),
            ISO_FORMAT_NO_MILLISECONDS
          ),
          stop: momentObjToISOString(
            isoStringToMomentObject(opportunity.End),
            ISO_FORMAT_NO_MILLISECONDS
          ),
          project_id: projectId,
        },
      })
    );
  });

  const swathsQueries = await Promise.all(swathsRequests);

  swathsQueries.forEach(({ data }, index) => {
    if (!data) return;
    manualOpportunities[index].Oza = data.midpoint.oza_deg;
    manualOpportunities[index].Sza = data.midpoint.sza_deg;
    manualOpportunities[index].Area = data.area_km2;
    manualOpportunities[index].AvailablePriorities = data.available_priorities;
    manualOpportunities[index].Benchmark.Coverage =
      getRegionOfInterestCoveragePercent(
        [data.footprint.geojson],
        msdAoi.map(({ geojson }) => geojson)
      );
  });

  return manualOpportunities;
};

export const getSwathPrice = (
  area: number,
  swathPriority: number | undefined,
  AvailablePriorities: {
    base_price_per_km2_gbp: string;
    priorities: ActivityPriority[];
  }
) => {
  const selectedPriorityLevel = AvailablePriorities.priorities.find(
    (priority) => priority.priority_level === swathPriority
  );

  const isPriceZero = AvailablePriorities.priorities.every(
    (priority) => Number(priority.price_multiplier) === 0
  );

  if (isPriceZero) {
    return 0;
  }
  if (!swathPriority || !selectedPriorityLevel)
    return (area * Number(AvailablePriorities?.base_price_per_km2_gbp)).toFixed(
      2
    );

  const price =
    area *
    Number(AvailablePriorities?.base_price_per_km2_gbp) *
    Number(selectedPriorityLevel.price_multiplier);

  return price.toFixed(2);
};
