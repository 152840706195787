import { Layer, LayerSourceType } from './layer';

export class CloudLayer extends Layer {
  name: string;
  description: string;
  url: string;
  layerClass: string;
  start_date: string;

  constructor(name: string, description: string, url: string, date: string) {
    super(LayerSourceType.CLOUD_COVERAGE_LAYER);
    this.name = name;
    this.description = description;
    this.url = url;
    this.layerClass = 'CloudLayer';
    this.start_date = date;
  }

  getName(): string {
    return this.name;
  }

  getDescription(): string {
    return this.description;
  }

  getUrl(): string | string[] {
    return this.url;
  }

  getStartDate(): string {
    return this.start_date;
  }

  containsSTACItem() {
    return false;
  }

  containsSTACAsset() {
    return false;
  }

  containsBandAlgebra() {
    return false;
  }

  sza(): Number | undefined {
    return undefined;
  }

  href(): string | undefined {
    return undefined;
  }
}
