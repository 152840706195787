import classNames from 'classnames';
import { Button, Tooltip } from 'opencosmos-ui';
import React from 'react';

export type SSOTResponse = {
  seqId: number;
  status: string;
  telemetryValues: {
    description: string;
    name: string;
    type: string;
    value: string;
  }[];
  timestamp: string;
};

type Props = {
  response: SSOTResponse;
};

const SSOTReplyInfo = ({ response }: Props) => {
  const [isValuesCollapsed, setIsValuesCollapsed] =
    React.useState<boolean>(false);
  return (
    <div className="w-full bg-neutral-300 p-1 flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div>
          <span>Seq id: </span>
          <span>{response.seqId}</span>
        </div>

        <div
          className={classNames('px-1', {
            'bg-success': response.status === 'STATUS_OK',
            'bg-white': response.status !== 'STATUS_OK',
          })}
        >
          <span>{response.status}</span>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <span>Timestamp: </span>
        <span>{response.timestamp}</span>
      </div>

      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-between">
          <span>
            Telemetry Values ({response.telemetryValues.length} available)
          </span>
          <Button
            icon={isValuesCollapsed ? 'ChevronDown' : 'ChevronUp'}
            isMinimal={true}
            onPress={() => setIsValuesCollapsed(!isValuesCollapsed)}
          />
        </div>

        {!isValuesCollapsed && (
          <div className="flex flex-col gap-2 ml-2">
            {response.telemetryValues.map((val, i) => {
              return (
                <Tooltip content={val.description} key={val.name}>
                  <div
                    className={classNames(
                      'p-1 w-full flex justify-between items-center',
                      {
                        'bg-item': i % 2 !== 0,
                        'bg-white': i % 2 === 0,
                      }
                    )}
                  >
                    <span className="inline-block">
                      {val.name} ({val.type})
                    </span>

                    <span className="inline-block">{val.value}</span>
                  </div>
                </Tooltip>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SSOTReplyInfo;
