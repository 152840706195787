import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Spinner } from '@blueprintjs/core';
import { useTasking } from 'datacosmos/stores/TaskingProvider';
import { TaskingDate } from 'datacosmos/components/Tasking/TaskingDate/TaskingDate';
import { Instruments } from 'datacosmos/components/Tasking/Instruments';
import { AreaOfInterest } from 'datacosmos/components/Tasking/AreaOfInterest/AreaOfInterest';
import {
  SearchSteps,
  Steps,
  MAX_ALLOWED_AOI_SIZE_M2,
} from 'datacosmos/components/Tasking/helpers';
import { useMapLayers } from 'datacosmos/stores/MapLayersProvider';
import { useMap } from 'datacosmos/stores/MapProvider';
import type { PolygonLayer } from 'datacosmos/entities/polygonLayer';
import { TaskingType } from 'datacosmos/components/Tasking/TaskingType';
import { Parameters } from 'datacosmos/components/Tasking/ObjectivesAndConstraints/Parameters';
import bbox from '@turf/bbox';
import { OpportunityCalendar } from 'datacosmos/components/Tasking/TaskingDate/OpportunityCalendar';
import { useLocalisation } from 'utils/hooks/useLocalisation';
import {
  type TaskingRequest,
  type TaskingRequestInstrument,
  type TaskingSatellite,
} from '_api/tasking/types';
import moment from 'moment';
import { Button, Icon, ListBoxItem, Select, Tooltip } from 'opencosmos-ui';
import type { TaskingRequestPriority } from '_api/tasking/service';
import { useQueries } from '@tanstack/react-query';
import { getCommonProcessingLevels } from 'datacosmos/utils/taskingRequests';

type SearchOpportunityProps = {
  setSwitchStep: (step: Steps) => void;
  searchSteps: SearchSteps[];
  setSearchSteps: React.Dispatch<React.SetStateAction<SearchSteps[]>>;
  switchStep: Steps;
  satellitesForTasking: TaskingSatellite[];
  setIsRequestModalOpen: (value: boolean) => void;
  taskingRequestDetails?: TaskingRequest;
  prefillInstruments?: TaskingRequestInstrument[];
};

export const SearchOpportunity = ({
  setSearchSteps,
  searchSteps,
  switchStep,
  setSwitchStep,
  satellitesForTasking,
  setIsRequestModalOpen,
  taskingRequestDetails,
  prefillInstruments,
}: SearchOpportunityProps) => {
  const {
    searchOpportunities,
    dateTo,
    setDateTo,
    dateFrom,
    setDateFrom,
    addSatellite,
    setAddSatellite,
    isFetching,
    SZAConstraints,
    setSZAConstraints,
    OZAConstraints,
    setOZAConstraints,
    isSZA,
    setIsSZA,
    setSZAError,
    hasSZAError,
    isOZAObjective,
    isSZAObjective,
    setIsOZAObjective,
    setIsSZAObjective,
    rollAngleConstraint,
    isRollAngle,
    setRollAngleConstraint,
    setIsRollAngle,
    setRollAngleError,
    hasRollAngleError,
    regions,
    requestPriority,
    setRequestPriority,
    isOZA,
    setIsOZA,
    getAdvancedOptionsSchema,
    processingLevel,
    setProcessingLevel,
    cloudConstraint,
    isCloud,
    setCloudConstraint,
    setIsCloud,
  } = useTasking();

  const [
    maxDateAllowedForSelectionInCalendar,
    setMaxDateAllowedForSelectionInCalendar,
  ] = useState<Date>();

  const [
    minDateAllowedForSelectionInCalendar,
    setMinDateAllowedForSelectionInCalendar,
  ] = useState<Date>();

  const {
    layers,
    replaceLayer,
    addLayer,
    removeLayer,
    removeLayersBySourceType,
  } = useMapLayers();

  const { drawPolygon, editPolygon, disableEditing, setViewToFitBbox } =
    useMap();

  const { translate } = useLocalisation();

  const makeRegionsEditable = useCallback(() => {
    regions.forEach((region) => {
      if (region.modifiers?.uneditable) {
        replaceLayer(region.cloneWithModifiers({ uneditable: false }));
      }
    });
  }, [regions, replaceLayer]);

  const [isTaskingError, setIsTaskingError] = useState<boolean>(false);

  const isRegionValid = useMemo(
    () => !regions.some((region) => region.areaInM2 > MAX_ALLOWED_AOI_SIZE_M2),
    [regions]
  );

  const regionsForTasking = useMemo(
    () => regions.filter((reg) => reg.options.visible),
    [regions]
  );

  useEffect(() => {
    if (addSatellite && addSatellite.length > 0) {
      setIsTaskingError(false);
    }
  }, [addSatellite]);

  useEffect(() => {
    makeRegionsEditable();
  }, [makeRegionsEditable]);

  const includesDateAndInstruments = searchSteps.includes(
    SearchSteps.SelectTaskingDateAndInstruments
  );

  useEffect(() => {
    if (regions.length > 0 && isRegionValid) {
      if (!includesDateAndInstruments) {
        setSearchSteps((prev) => [...prev, SearchSteps.SelectTaskingType]);
      }
    } else {
      setSearchSteps([SearchSteps.AddAOI]);
      setSwitchStep(Steps.DataAcquisition);
    }
  }, [
    includesDateAndInstruments,
    isRegionValid,
    regions.length,
    setSearchSteps,
    setSwitchStep,
  ]);

  const centerRegion = useCallback(
    (region: PolygonLayer) => {
      setViewToFitBbox(bbox(region.data));
    },
    [setViewToFitBbox]
  );

  const processingLevelsQueries = useQueries({
    queries:
      addSatellite?.map((sat) => ({
        queryKey: ['missionConfiguration', sat.satellite.mission_id],
        queryFn: async () => {
          if (!sat.satellite.mission_id) {
            throw new Error('No mission id found');
          }
          const data = await getAdvancedOptionsSchema(sat.satellite.mission_id);
          if (!data) {
            throw new Error('Failed to fetch activity parameters');
          }
          return data;
        },
        staleTime: Infinity,
      })) ?? [],
    combine: (results) => {
      const commonProcessingLevels = getCommonProcessingLevels(
        results.map((r) => r.data)
      );
      return {
        pending: results.some((r) => r.isPending),
        data: commonProcessingLevels,
        error: results.some((r) => r.isError),
      };
    },
  });

  if (
    processingLevel &&
    !processingLevelsQueries.data?.includes(processingLevel)
  ) {
    setProcessingLevel(undefined);
  }

  const processingLevelIsRequired = !(
    processingLevelsQueries.data?.length === 1 &&
    processingLevelsQueries.data[0] === undefined
  );

  const processingLevelError =
    (addSatellite?.length &&
      !processingLevelsQueries.pending &&
      processingLevelsQueries.data?.length === 0) ||
    processingLevelsQueries.error;

  useEffect(() => {
    if (taskingRequestDetails) {
      const startDate = taskingRequestDetails.constraints.find(
        (aqDate) => aqDate.type === 'ACQUISITION_DATE'
      )?.min;
      const endDate = taskingRequestDetails.constraints.find(
        (aqDate) => aqDate.type === 'ACQUISITION_DATE'
      )?.max;
      setDateFrom(moment.unix(startDate!).toDate());
      setDateTo(moment.unix(endDate!).toDate());
      setMinDateAllowedForSelectionInCalendar(moment.unix(startDate!).toDate());
      setMaxDateAllowedForSelectionInCalendar(moment.unix(endDate!).toDate());
    }
  }, [taskingRequestDetails, setDateFrom, setDateTo]);

  return (
    <>
      <div className="search-opportunity">
        <div className="search-form">
          <div>
            {searchSteps.includes(SearchSteps.AddAOI) && (
              <AreaOfInterest
                disabled={
                  switchStep === Steps.ListOfOpportunities || isFetching
                }
                regions={regionsForTasking}
                replaceLayer={replaceLayer}
                drawPolygon={drawPolygon}
                addLayer={addLayer}
                removeLayer={removeLayer}
                removeLayersBySourceType={removeLayersBySourceType}
                editPolygon={editPolygon}
                disableEditing={disableEditing}
                isFetching={isFetching}
                centerRegion={centerRegion}
                isRegionValid={isRegionValid}
              />
            )}

            {searchSteps.includes(SearchSteps.SelectTaskingType) && (
              <TaskingType
                disabled={
                  switchStep === Steps.ListOfOpportunities || isFetching
                }
                setSearchSteps={setSearchSteps}
                setSwitchStep={setSwitchStep}
                switchStep={switchStep}
              />
            )}

            {searchSteps.includes(
              SearchSteps.SelectTaskingDateAndInstruments
            ) && (
              <div className="flex flex-col gap-4 w-full">
                <Parameters
                  disabled={
                    switchStep === Steps.ListOfOpportunities || isFetching
                  }
                  switchStep={switchStep}
                  constraintsProps={{
                    isRollAngle,
                    hasRollAngleError,
                    rollAngleConstraint,
                    setIsRollAngle,
                    setIsRollAngleError: setRollAngleError,
                    setRollAngleConstraint,
                    isSZA,
                    setIsSZA,
                    setSZAConstraints,
                    setSZAError,
                    SZAConstraints,
                    SZAError: hasSZAError,
                    isOZA,
                    setIsOZA,
                    setOZAConstraints,
                    OZAConstraints,
                    isCloud,
                    setIsCloud,
                    cloudConstraint,
                    setCloudConstraint,
                  }}
                  objectivesProps={{
                    isOZAObjective,
                    isSZAObjective,
                    OZAConstraints,
                    setIsOZAObjective,
                    setIsSZAObjective,
                    setOZAConstraints,
                    setSZAConstraints,
                    SZAConstraints,
                  }}
                />

                {switchStep === Steps.ListOfOpportunities ? (
                  <OpportunityCalendar
                    setDateTo={setDateTo}
                    setDateFrom={setDateFrom}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    layers={layers}
                  />
                ) : (
                  <TaskingDate
                    setDateTo={setDateTo}
                    setDateFrom={setDateFrom}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    minDateAllowedForSelection={
                      minDateAllowedForSelectionInCalendar
                    }
                    maxDateAllowedForSelection={
                      maxDateAllowedForSelectionInCalendar
                    }
                  />
                )}

                {switchStep === Steps.AutomatedTasking && (
                  <div className="flex justify-between items-center w-full gap-4 px-2 py-1 color-item">
                    <div className="font-bold">Priority</div>
                    <Select
                      defaultSelectedKey={50}
                      selectedKey={requestPriority}
                      onSelectionChange={(p) => {
                        setRequestPriority(p as TaskingRequestPriority);
                      }}
                      placeholder="Select priority"
                      className="font-sm"
                      fill
                    >
                      <ListBoxItem id={50} key={50}>
                        Standard
                      </ListBoxItem>
                      <ListBoxItem id={40} key={40}>
                        Priority
                      </ListBoxItem>
                    </Select>
                  </div>
                )}
                {switchStep !== Steps.ListOfOpportunities && (
                  <Instruments
                    satellites={satellitesForTasking}
                    setAddSatellite={setAddSatellite}
                    addSatellite={addSatellite}
                    isTaskingError={isTaskingError}
                    setIsTaskingError={setIsTaskingError}
                    prefillInstruments={prefillInstruments}
                  />
                )}

                {switchStep !== Steps.ListOfOpportunities && (
                  <div className="flex items-center gap-4 px-2 py-1 color-item">
                    <div className="font-bold">
                      {translate(
                        'datacosmos.tasking.new.processingLevel.title'
                      )}
                    </div>
                    <Select
                      selectedKey={processingLevel ?? null}
                      onSelectionChange={(p) => {
                        setProcessingLevel(p as string);
                      }}
                      className="font-sm"
                      fill
                      isDisabled={
                        processingLevelsQueries.pending ||
                        processingLevelsQueries.error ||
                        !processingLevelIsRequired
                      }
                    >
                      {processingLevelsQueries.data?.map((level) => (
                        <ListBoxItem id={level} key={level}>
                          {level}
                        </ListBoxItem>
                      ))}
                    </Select>
                    <Tooltip
                      className="w-96"
                      content={(() => {
                        if (!processingLevelIsRequired) {
                          return translate(
                            'datacosmos.tasking.new.processingLevel.info.notNeeded'
                          );
                        }
                        if (processingLevelsQueries.error) {
                          return translate(
                            'datacosmos.tasking.new.processingLevel.info.requestError'
                          );
                        }
                        if (
                          addSatellite?.length &&
                          !processingLevelsQueries.pending &&
                          (processingLevelsQueries.data?.length === 0 ||
                            processingLevelsQueries.data?.includes(undefined))
                        ) {
                          return translate(
                            'datacosmos.tasking.new.processingLevel.info.noLevelsFound'
                          );
                        }
                        return translate(
                          'datacosmos.tasking.new.processingLevel.info.addSatellite'
                        );
                      })()}
                    >
                      {processingLevelsQueries.pending ? (
                        <Spinner size={20} />
                      ) : (
                        <Icon
                          className={processingLevelError ? '!stroke-none' : ''}
                          icon={processingLevelError ? 'warning-sign' : 'Info'}
                        />
                      )}
                    </Tooltip>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="tasking-footer">
        {searchSteps.includes(SearchSteps.SelectTaskingDateAndInstruments) &&
          switchStep !== Steps.ListOfOpportunities && (
            <div className="p-4">
              <Button
                className="flex bg-item w-full h-12 items-center justify-center dark:bg-item-dark dark:text-item-dark-contrast mb-4"
                onPress={async () => {
                  if (switchStep === Steps.AutomatedTasking) {
                    setIsRequestModalOpen(true);
                  } else {
                    regions.forEach((r) => {
                      disableEditing(r.getLeafletLayerMetadata());
                      replaceLayer(r.cloneWithModifiers({ uneditable: true }));
                    });
                    const hasWorked = await searchOpportunities(
                      taskingRequestDetails?.project_id
                    );
                    if (hasWorked) {
                      setSwitchStep(Steps.ListOfOpportunities);
                      setIsTaskingError(false);
                    } else {
                      makeRegionsEditable();
                      setIsTaskingError(true);
                    }
                  }
                }}
                isDisabled={
                  !addSatellite?.length ||
                  (!processingLevel && processingLevelIsRequired) ||
                  processingLevelError
                }
                intent="primary"
              >
                {
                  // eslint-disable-next-line no-nested-ternary
                  isFetching ? (
                    <Spinner className="float-spinner" size={20} />
                  ) : switchStep === Steps.AutomatedTasking ? (
                    translate('datacosmos.tasking.new.request')
                  ) : (
                    translate('datacosmos.tasking.new.search')
                  )
                }
              </Button>
            </div>
          )}
      </div>
    </>
  );
};
