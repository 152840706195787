import type { IStacItem } from 'datacosmos/types/stac-types';
import type { ISTACOptions } from 'datacosmos/entities/singleBandLayer';
import type { AxiosResponse } from 'axios';
import Axios from 'axios';
import {
  emptyCOGMetadataForRange,
  COGMetadataForRange,
} from 'datacosmos/services/tilingApi/cogMetadata';
import type { ICOGMetadataForRange } from 'datacosmos/services/tilingApi/cogMetadata';
import { IMAGE_TILE_SERVER } from 'env';
import type { BBox } from 'geojson';
import type { BandAlgebraOptions } from '_api/views/types';
import type { BandAlgebraTypeLayerOption } from 'datacosmos/entities/bandAlgebraLayer';

export interface ICOGSingleBandMetadataStatistics {
  count: number;
  histogram: number[][];
  majority: number;
  minority: number;
  masked_pixels: number;
  max: number;
  mean: number;
  median: number;
  min: number;
  percentile_2: number;
  percentile_98: number;
  std: number;
  sum: number;
  valid_percent: number;
  valid_pixels: number;
  percentile_1: number;
  percentile_25: number;
  percentile_75: number;
  percentile_99: number;
}
export interface ICOGMetadataStatistics {
  [key: string]: ICOGSingleBandMetadataStatistics;
}

export interface ICOGMetadata {
  band_descriptions: [string, string][];
  band_metadata: [string, Object][];
  bounds: number[];
  colorinterp: string[];
  count: number;
  driver: string;
  dtype: string;
  height: number;
  nodata_type: string;
  nodata_value: number;
  overviews: number[];
  width: number;
}

export interface IGeoJSONMetadata {
  [key: string]: unknown;
}

export interface IBBoxOptions {
  bbox: BBox;
  width: number;
  height: number;
}

const getExpressionQuery = (
  type: string,
  expression: string,
  rgbExpression: string
) => {
  /* An index band algebra expression has mathematical operators which is used 
  as an indicator for using assets or expression for titiler query */
  const isIndexExpression = type === 'Expression';
  if (!isIndexExpression) {
    if (type === 'Multiband') {
      return `assets=${encodeURIComponent(
        rgbExpression.split(',').join('&assets=')
      )}`;
    }
    if (type === 'Singleband') {
      return `assets=${encodeURIComponent(
        expression.split(',').join('&assets=')
      )}`;
    }
  }
  return (
    'expression=' +
    encodeURIComponent(type === 'Multiband' ? rgbExpression : expression)
  );
};

const tilingApi = {
  // Generates a URL which can be queried to fetch per-band statistics for the COG at the URL provided.
  generateCOGStatisticsURL(url: string) {
    return `${IMAGE_TILE_SERVER}/cog/statistics?url=${encodeURIComponent(
      url
    )}&p=25&p=75&p=99&p=1&p=98&p=2`;
  },

  // Generates a URL which can be queried to fetch metadata for the COG at the URL provided.
  generateCOGMetadataURL(url: string) {
    return `${IMAGE_TILE_SERVER}/cog/info?url=${encodeURIComponent(url)}`;
  },

  metadata: {} as ICOGMetadata,
  statistics: {} as ICOGMetadataStatistics,

  /**
   * Generates a URL template for fetching tiles for a "single band" COG.
   * The tile parameters (zoom, x, y) are left as placeholders.
   * If maximumPixelValue is not null, adds a rescale query parameter between zero and the provided maximum.
   * @param cogURL the location of the tile-able asset (COG)
   * @param pixelValueRanges the range of pixels to display (min, max) for each band
   * @returns the URL from which tiles of the asset can be fetched
   */
  generateSingleBandTilingURL(
    cogURL: string,
    pixelValueRanges: [number, number][] | null | undefined,
    options: ISTACOptions | undefined
  ) {
    let rangeQueryParameter = '';
    if (pixelValueRanges !== null && pixelValueRanges !== undefined) {
      rangeQueryParameter = pixelValueRanges
        .map((range) => `&rescale=${range[0]},${range[1]}`)
        .join('');
    }

    let optionsParam = '';
    if (options?.color_formula) {
      optionsParam = `&color_formula=${options.color_formula}`;
    }

    let offset = '';
    if (options?.offset?.x) {
      offset = `&x_offset=${options.offset.x}`;
    }

    if (options?.offset?.y) {
      offset += `&y_offset=${options.offset.y}`;
    }

    return (
      IMAGE_TILE_SERVER +
      '/cog/tiles/WebMercatorQuad/{z}/{x}/{y}?url=' +
      encodeURIComponent(cogURL) +
      rangeQueryParameter +
      optionsParam +
      offset
    );
  },

  // Generates a URL template for fetching tiles for a band algebra image (combination of multiple images).
  // The tile parameters (zoom, x, y) are left as placeholders.
  generateBandAlgebraTilingURL(
    item: IStacItem,
    expression: string,
    rescale: string,
    color: string,
    bandAlgebraType: BandAlgebraTypeLayerOption,
    rgbExpression: string,
    rescaleFalse: string
  ) {
    const paramUrl = `/collections/${item.collection ?? ''}/items/${item.id}`;
    return (
      IMAGE_TILE_SERVER +
      '/stac/tiles/WebMercatorQuad/{z}/{x}/{y}?' +
      getExpressionQuery(bandAlgebraType, expression, rgbExpression) +
      '&url=' +
      paramUrl +
      '&rescale=' +
      (bandAlgebraType === 'Multiband' ? rescaleFalse : rescale) +
      (bandAlgebraType === 'Multiband' ? '' : '&colormap_name=' + color)
    );
  },

  // Generates a URL which can be queried to fetch geopdf for the COG provided.
  generateSingleBandBboxURL(
    cogURL: string,
    bboxOptions: IBBoxOptions,
    stacOptions: ISTACOptions | undefined
  ) {
    let optionsParam = '';
    if (stacOptions?.color_formula) {
      optionsParam = `&color_formula=${stacOptions.color_formula}`;
    }

    return (
      `http://titiler-service/cog/bbox/${bboxOptions.bbox?.join(',')}/${
        bboxOptions.width
      }x${bboxOptions.height}.tif?url=` +
      cogURL +
      optionsParam
    );
  },

  // Generates a URL which can be queried to fetch geopdf for the band algebra expression provided.
  generateBandAlgebraBboxURL(
    stacItemURL: string,
    expression: string,
    bboxOptions: IBBoxOptions,
    bandOptions: BandAlgebraOptions
  ) {
    return (
      `http://titiler-service/stac/bbox/${bboxOptions.bbox?.join(',')}/${
        bboxOptions.width
      }x${bboxOptions.height}.tif?` +
      getExpressionQuery(
        bandOptions.bandAlgebraType,
        expression,
        bandOptions.rgbExpression
      ) +
      '&url=' +
      stacItemURL +
      '&rescale=' +
      (bandOptions.bandAlgebraType === 'Multiband'
        ? bandOptions.rescaleFalse
        : bandOptions.scale) +
      (bandOptions.bandAlgebraType === 'Multiband'
        ? ''
        : '&colormap_name=' + bandOptions.colormap)
    );
  },
  // Fetch from the tiling service the metadata for the COG at the given URL.
  // tokenProvider is an async function to get a token valid for the tiling API.
  async fetchMetadataForCOG(
    cogURL: string,
    token: string | undefined,
    region?: GeoJSON.Feature
  ): Promise<ICOGMetadataForRange> {
    const metadataUrl = this.generateCOGMetadataURL(cogURL);
    const statsUrl = this.generateCOGStatisticsURL(cogURL);
    const metadata = Axios.get(metadataUrl, {
      headers: {
        Authorization: `Bearer ${token ?? ''}`,
        'Content-Type': 'application/json',
      },
    });

    let stats: Promise<
      AxiosResponse<ICOGMetadataStatistics | GeoJSON.FeatureCollection>
    >;

    if (region) {
      stats = Axios.post(
        statsUrl,
        {
          type: 'FeatureCollection',
          features: [region],
        },
        {
          headers: {
            Authorization: `Bearer ${token ?? ''}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } else {
      stats = Axios.get(statsUrl, {
        headers: {
          Authorization: `Bearer ${token ?? ''}`,
          'Content-Type': 'application/json',
        },
      });
    }

    return Promise.all([metadata, stats])
      .then((responses) => {
        this.metadata = responses[0].data as ICOGMetadata;
        if (region) {
          this.statistics = (
            (responses[1].data as GeoJSON.FeatureCollection).features[0]
              .properties as { statistics: ICOGMetadataStatistics }
          ).statistics;
        } else {
          this.statistics = responses[1].data as ICOGMetadataStatistics;
        }
        return this.cogMetadataFromJson(this.metadata, this.statistics);
      })
      .catch(() => {
        return emptyCOGMetadataForRange();
      });
  },

  async fetchMetadataForGeoJSON(geoJsonURL: string) {
    const metadataUrl = geoJsonURL;

    return fetch(metadataUrl)
      .then(async (response) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const data = await response.json();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        this.metadata = data.properties;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return data.properties as IGeoJSONMetadata;
      })
      .catch(() => {
        return null as unknown as IGeoJSONMetadata;
      });
  },

  // Attempts to convert the given object into a meaningful COGMetadataForRange, returns an empty metadata if it cannot
  cogMetadataFromJson(
    jsonMetadata: ICOGMetadata | null | undefined,
    jsonStatistics: ICOGMetadataStatistics | null | undefined
  ): ICOGMetadataForRange {
    if (
      jsonStatistics !== null &&
      jsonStatistics !== undefined &&
      jsonMetadata !== null &&
      jsonMetadata !== undefined
    ) {
      return new COGMetadataForRange(jsonStatistics, jsonMetadata);
    }
    return emptyCOGMetadataForRange();
  },

  generateAssetPreviewUrl(
    item: IStacItem,
    assetKey: string,
    color_formula?: string
  ) {
    let rangeQueryParameter = '';
    if (
      item.properties['opencosmos:scale'] !== null &&
      item.properties['opencosmos:scale'] !== undefined
    ) {
      rangeQueryParameter = `&rescale=0,${item.properties['opencosmos:scale']}`;
    }

    let optionsParam = '';
    if (color_formula) {
      optionsParam = `&color_formula=${color_formula}`;
    }

    return (
      IMAGE_TILE_SERVER +
      '/stac/preview.webp?height=128&width=128&assets=' +
      encodeURIComponent(assetKey) +
      '&url=' +
      item.assets[assetKey].href +
      rangeQueryParameter +
      optionsParam
    );
  },
  generateBandAlgebraPreviewUrl(
    item: IStacItem,
    expression: string,
    rescale: string,
    color: string,
    bandAlgebraType: BandAlgebraTypeLayerOption,
    rgbExpression: string,
    rescaleFalse: string
  ) {
    const paramUrl = `/collections/${item.collection ?? ''}/items/${item.id}`;
    return (
      IMAGE_TILE_SERVER +
      '/stac/preview.webp?' +
      getExpressionQuery(bandAlgebraType, expression, rgbExpression) +
      '&url=' +
      paramUrl +
      '&rescale=' +
      (bandAlgebraType === 'Multiband' ? rescaleFalse : rescale) +
      (bandAlgebraType === 'Multiband' ? '' : '&colormap_name=' + color)
    );
  },
};

export default tilingApi;
