import React from 'react';
import DefaultLayout from '../shared/components/DefaultLayout';
import mainS from 'pages/ops/RTI/index.module.scss';
import { Breadcrumbs, NonIdealState } from '@blueprintjs/core';
import { ColumnLayout } from 'ui/ColumnLayout/ColumnLayout';
import { Column } from 'ui/ColumnLayout/Column';
import { RowLayout } from 'ui/RowLayout/RowLayout';
import {
  Button,
  DateRangePicker,
  DebouncedInput,
  Header,
  ListBoxItem,
  parseDate,
  Select,
  Table,
  Tooltip,
  Spinner,
} from 'opencosmos-ui';
import { Row } from 'ui/RowLayout/Row';
import type { RunWithDuration } from './hooks/useRunsData';
import { EVENT_TYPES, useRunsData } from './hooks/useRunsData';
import type { Run, RunEvent } from '_api/runs/types';
import classNames from 'classnames';
import LogsTable from './components/LogsTable';

const Runs = () => {
  const {
    runs,
    toggleSelectedRun,
    setNameSearchQuery,
    setEventsSearchQuery,
    setStartEndDateSearchQuery,
    startEndDateSearchQuery,
    isFetchingLogs,
    refreshRuns,
    eventsSearchQuery,
    nameSearchQuery,
    selectedRun,
    logs,
    isFetchingRuns,
    stopSelectedRun,
    sendSelectedRunManualEvent,
    isSendingEvent,
    refetchLogs,
    fetchNextRunsPage,
    fetchPreviousRunsPage,
    hasNextRunsPage,
    hasPreviousRunsPage,
  } = useRunsData();

  const withLoadingRuns = (content: React.ReactNode) => {
    if (isFetchingRuns) {
      return <Spinner />;
    }

    return <>{content}</>;
  };

  const withLoadingLogs = (content: React.ReactNode) => {
    if (isFetchingLogs) {
      return <Spinner />;
    }

    return <>{content}</>;
  };

  const withNoResults = (content: React.ReactNode) => {
    if (runs.length === 0) {
      return (
        <NonIdealState
          icon="info-sign"
          description="No runs match the search criteria"
          title="No runs"
        />
      );
    }

    return <>{content}</>;
  };

  const withNoRunSelected = (content: React.ReactNode) => {
    if (!selectedRun) {
      return (
        <NonIdealState
          icon="info-sign"
          description="No run selected"
          title="No run"
        />
      );
    }

    return <>{content}</>;
  };

  const withNoLogsAvailable = (content: React.ReactNode) => {
    if (logs.length === 0) {
      return (
        <NonIdealState
          icon="info-sign"
          description="No logs available"
          title="No logs"
        />
      );
    }

    return <>{content}</>;
  };

  const renderRunInfo = () => (
    <RowLayout rows={4} rowRatio={[0.06, 0.08, 1, 1]}>
      <Row>
        <></>
      </Row>
      <Row align="start">
        <Column>
          <div className="flex flex-col gap-11">
            <div className="flex items-center gap-32">
              <Header className="bg-transparent text-xl !px-0">Run info</Header>
              <div className="flex items-center gap-6">
                <Tooltip
                  content={'Run has already ended'}
                  isDisabled={!selectedRun?.end_time}
                >
                  <Button
                    iconPlacement="right"
                    className={'w-32'}
                    onPress={async () => {
                      await stopSelectedRun();
                    }}
                    isDisabled={selectedRun?.end_time !== null}
                  >
                    Stop
                  </Button>
                </Tooltip>
                <Button
                  className={'w-32'}
                  onPress={async () => {
                    await sendSelectedRunManualEvent();
                  }}
                >
                  {isSendingEvent ? <Spinner /> : <span>Rerun script</span>}
                </Button>
                <Button
                  className={'w-32'}
                  onPress={async () => {
                    await refetchLogs();
                  }}
                  icon="refresh"
                >
                  <span>Refresh logs</span>
                </Button>
              </div>
            </div>
          </div>
        </Column>
      </Row>
      <Row
        align="start"
        style={{ maxWidth: '50vw', overflowX: 'auto', overflowY: 'auto' }}
      >
        {withLoadingLogs(withNoLogsAvailable(<LogsTable logs={logs} />))}
      </Row>
      <Row align="start" style={{ maxWidth: '50vw' }}>
        <Column style={{ paddingTop: '20px' }}>
          <Header className="bg-transparent text-xl !px-0">
            Run Event Info
          </Header>
          {selectedRun && (
            <div className="overflow-x-auto max-w-[46.5vw]">
              <Table<RunEvent>
                cellRenderer={(ctx) => {
                  return <span>{ctx.getValue() as string}</span>;
                }}
                data={[selectedRun.event]}
              />
            </div>
          )}
        </Column>
      </Row>
    </RowLayout>
  );

  const renderRunsTable = () => (
    //TODO: Implement pagination to the table component
    <div className="w-full">
      <Table<RunWithDuration>
        data={runs}
        cellRenderer={(ctx, header) => {
          if (header === 'status') {
            const status = ctx.getValue();
            return (
              <div
                className={classNames('p-2 w-full', {
                  'bg-success': status === 'SUCCEEDED',
                  'bg-warning': status === 'FAILED',
                  'bg-accent': status === 'STOPPED',
                })}
              >
                {ctx.getValue() as string}
              </div>
            );
          }

          return <span className="p-2">{ctx.getValue() as string}</span>;
        }}
        hiddenColumns={[
          'id',
          'mission_id',
          'event.schedule',
          'event.script_name',
        ]}
        columnOrder={[
          'script_name',
          'status',
          'start_time',
          'end_time',
          'duration',
          'event.type',
          'script_version',
        ]}
        excludeDataKeys={['event']}
        alwaysIncludeKeys={['event.type']}
        capitalizeTableHeaders={true}
        enableRowSelection={true}
        onRowSelect={(run) =>
          // Enable multi-row selection is false, this is safe to cast to Run
          toggleSelectedRun(run as Run)
        }
        enableSorting={true}
        showFullHeaderTitle={true}
        defaultSelectedRow={selectedRun as RunWithDuration}
      />
    </div>
  );
  return (
    <DefaultLayout
      showMissionSelector
      leftHeader={
        <div className={mainS.headerContainer}>
          <Breadcrumbs items={[{ text: 'Runs' }]} />
        </div>
      }
      className="p-10"
    >
      <ColumnLayout
        colRatio={[1, 1]}
        cols={2}
        style={{ maxHeight: '85vh', overflow: 'hidden' }}
      >
        <Column style={{ overflowY: 'auto', overflowX: 'hidden' }}>
          <RowLayout rows={3} rowRatio={[0.06, 0.08, 1]}>
            <Row align="start">
              <Header className="bg-transparent text-xl !px-0">Runs</Header>
            </Row>
            <Row align="start">
              <div className="flex items-center  w-full gap-6">
                <DebouncedInput
                  type="text"
                  debounceTimeMs={500}
                  className={'w-1/4'}
                  placeholder="Search Name"
                  onChange={(e) => {
                    setNameSearchQuery(e.target.value);
                  }}
                  value={nameSearchQuery}
                />
                <div className="flex items-center w-1/4">
                  <DateRangePicker
                    value={
                      startEndDateSearchQuery?.start &&
                      startEndDateSearchQuery?.end
                        ? {
                            start: parseDate(startEndDateSearchQuery?.start),
                            end: parseDate(startEndDateSearchQuery?.end),
                          }
                        : null
                    }
                    showClearButton={true}
                    onChange={(dateRange) => {
                      if (dateRange === null) {
                        setStartEndDateSearchQuery({
                          start: undefined,
                          end: undefined,
                        });
                        return;
                      }

                      setStartEndDateSearchQuery({
                        start: dateRange.start.toString(),
                        end: dateRange.end.toString(),
                      });
                    }}
                  />
                </div>
                <div className={'w-1/4'}>
                  <Select
                    chevronPlacement="right"
                    fill
                    selectedKey={eventsSearchQuery ?? 'All'}
                    onSelectionChange={(ev) =>
                      setEventsSearchQuery(
                        ev.toString() === 'All' ? undefined : ev.toString()
                      )
                    }
                  >
                    {EVENT_TYPES.map((e) => (
                      <ListBoxItem key={e} id={e}>
                        {e}
                      </ListBoxItem>
                    ))}
                  </Select>
                </div>
                <Button
                  icon="refresh"
                  onPress={async () => {
                    await refreshRuns();
                  }}
                  variant="square"
                  className={'h-8 w-8'}
                />
              </div>
            </Row>
            <Row align="start" style={{ maxWidth: '50vw', overflowX: 'auto' }}>
              {withLoadingRuns(withNoResults(renderRunsTable()))}
            </Row>
            <Row>
              <Button
                icon="ChevronLeft"
                variant="square"
                className={'h-8'}
                onPress={() => {
                  void fetchPreviousRunsPage();
                }}
                isDisabled={!hasPreviousRunsPage}
              />

              <Button
                icon="ChevronRight"
                onPress={() => {
                  void fetchNextRunsPage();
                }}
                variant="square"
                className={'h-8'}
                isDisabled={!hasNextRunsPage}
              />
            </Row>
          </RowLayout>
        </Column>

        <Column
          style={{ maxWidth: '50vw', overflowY: 'auto', overflowX: 'hidden' }}
        >
          {withNoRunSelected(renderRunInfo())}
        </Column>
      </ColumnLayout>
    </DefaultLayout>
  );
};

export default Runs;
