import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { TicketTeam } from '_api/tickets/service';
import { toaster } from 'toaster';
import { FEEDBACK_MAIL } from 'constants/contact';
import classNames from 'classnames';
import type { IconSize } from 'opencosmos-ui';

export type MailToButtonProps = {
  team: TicketTeam;
  subject: string;
  fallbackEmail?: string;
  className?: string;
  iconSize?: IconSize;
};

export const sendEmail = (
  mailSubject: string,
  fallbackEmail: string | undefined
) => {
  toaster.show({
    message: 'Opening the mail client...',
    intent: 'none',
    icon: 'info-sign',
    timeout: 3000,
  });
  setTimeout(() => {
    let mailto = `mailto:${fallbackEmail ?? FEEDBACK_MAIL}?`;
    mailto += `subject=${mailSubject}&`;
    const w = window.open(mailto, '_blank');
    if (typeof w === 'undefined') {
      toaster.showCompositeToast({
        title: 'Could not open the default mail client',
        description:
          'Please email your feedback to datacosmos-help@open-cosmos.com',
        intent: 'none',
        icon: 'info-sign',
        showCopyButton: true,
      });
    } else {
      // Check if the window is closed
      const checkWindowClosed = setInterval(() => {
        if (w?.closed) {
          clearInterval(checkWindowClosed);
          toaster.show({
            message:
              'Connect to mail client or please email your feedback to datacosmos-help@open-cosmos.com',
            intent: 'none',
            icon: 'info-sign',
          });
        }
      }, 1000); // Check every second
    }
  }, 2000);
};

export const MailToButton = ({
  team,
  subject,
  fallbackEmail,
  className,
  iconSize,
}: MailToButtonProps) => {
  const mailSubject = `${subject} to ${team}`;
  return (
    <>
      <a
        target="_blank"
        className={classNames(
          className,
          'flex items-center p-2 dark:text-item-dark-contrast hover:no-underline whitespace-nowrap'
        )}
        rel="noreferrer"
        onClick={() => {
          sendEmail(mailSubject, fallbackEmail);
        }}
      >
        <Icon className="mr-1" icon={IconNames.ENVELOPE} size={iconSize} />
        {subject}
      </a>
    </>
  );
};

export default MailToButton;
