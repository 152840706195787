import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { get, isNil, isEmpty } from 'lodash/fp';
import type { DateRange } from '@blueprintjs/datetime';
import { IconNames } from '@blueprintjs/icons';
import { Intent } from '@blueprintjs/core';
import moment from 'moment';
import { useAuth } from 'services/auth/AuthWrapper';
import { ops } from 'constants/mixpanelAnalytics';
import { SORTING_ORDER } from 'constants/sortAndOrder';
import type { TAB_STATE } from 'constants/ops/gs_scheduling/constants';
import { GS_TAB_MAPPING } from 'constants/ops/gs_scheduling/constants';
import { toaster } from 'toaster';
import type {
  PassSlot,
  AntennaOpportunity,
  Window,
  RadioLink,
  GS,
  Antennas,
  Pass,
  WindowUnix,
  BookPassRequest,
} from '_api/groundStation/types';
import type { IPassesParamsAPI } from '../../../../services/api/portal/groundStations/passAPI';
import passAPI from '../../../../services/api/portal/groundStations/passAPI';
import { GROUND_STATION_SCHEDULING_SERVICE_URL } from '../../../../services/api/portal/groundStations/constants';
import {
  getGroundStationsOpportunities,
  getGroundStations as apiGetGroundStations,
} from '_api/groundStation/service';
import { useQuery } from '_api/useQuery';
import { useAnalytics } from 'utils/hooks/analytics/useAnalytics';
import {
  flattenGroundStationList,
  getAntennasFromGroundStations,
} from '_api/groundStation/helpers';
import type { Ephemeris, EphemerisParams } from '_api/ephemeris/types';
import {
  getEphemeris as apiGetEphemeris,
  getEphemerisLatest as apiGetEphemerisLatest,
  postEphemeris,
} from '_api/ephemeris/service';
import { ALL_SATELLITES } from '_api/satellites/constants';

export type IGroundStationServiceOutput = ReturnType<
  typeof useGroundStationInner
>;

const getRangeTimes = (daterange: DateRange) => {
  const dateStart = daterange[0];
  const dateEnd = daterange[1];

  if (!dateStart || !dateEnd) return {};

  const startOffsetInMs = dateStart.getTimezoneOffset() * 60 * 1000;
  const endOffsetInMs = dateEnd.getTimezoneOffset() * 60 * 1000;

  const start = new Date(
    startOffsetInMs < 0
      ? dateStart.getTime() - startOffsetInMs
      : dateStart.getTime() + startOffsetInMs
  ).toISOString();

  const end = new Date(
    startOffsetInMs < 0
      ? dateEnd.getTime() - endOffsetInMs
      : dateEnd.getTime() + endOffsetInMs
  ).toISOString();

  return { start, end };
};

export const GroundStationContext = createContext<IGroundStationServiceOutput>(
  null as unknown as IGroundStationServiceOutput
);
export const useApiGroundStationService = () =>
  useContext<IGroundStationServiceOutput>(GroundStationContext);

export const useGroundStationInner = (missionId: number) => {
  const { token } = useAuth();

  const [groundStations, setGroundStations] = useState<GS[]>([]); // sites
  const [chosenStationIdList, setChosenStationIdList] = useState<number[]>([]);
  const [antennas, setAntennas] = useState<Antennas>({});

  // Only used for booking passes
  const [ephemeris, setEphemeris] = useState<Ephemeris>();
  const [ephemerisList, setEphemerisList] = useState<Ephemeris[]>();
  const [ephemerisView, setEphemerisView] = useState<Ephemeris>();
  const [isSavingTLE, setIsSavingTLE] = useState<boolean>(false);

  const [gsPasses, setGSPasses] = useState<Pass[]>([]);
  const [isFetchingPasses, setFetchingPasses] = useState<boolean>(false);
  const [passesSortOrder, setPassesSortOrder] = useState(
    SORTING_ORDER.DESCENDING
  );

  const [isFetching, setFetching] = useState<boolean>(false);
  const [isBookPassProcessing, setBookPassProcessing] =
    useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<TAB_STATE>('upcoming');
  const [pickedDate, setPickedDate] = useState<DateRange>([
    new Date(new Date().toLocaleString('en-US', { timeZone: 'Etc/UTC' })),
    new Date(
      moment
        .utc()
        .add(4, 'day')
        .toDate()
        .toLocaleString('en-US', { timeZone: 'Etc/UTC' })
    ),
  ]);

  const [showBookModal, setShowBookModal] = useState<boolean>(false);
  const [opportunityInView, setOpportunityInView] = useState<PassSlot | null>(
    null
  );
  const [selectedAntennaAvailability, setSelectedAntennaAvailability] =
    useState<AntennaOpportunity | null>(null);
  const [selectedWindow, setSelectedWindow] = useState<WindowUnix | null>(null);
  const [selectedPassBooked, setSelectedPassBooked] = useState(false);
  const [passJustBooked, setPassJustBooked] = useState<Pass | null>(null);

  const [selectedRadioLinks, setSelectedRadioLinks] = useState<RadioLink[]>([]);
  const [selectedProviderDeployment, setSelectedProviderDeployment] =
    useState<string>();

  const { sendInfo } = useAnalytics();

  // When an antenna is chosen, select the first provider deployment for the antenna
  useEffect(() => {
    if (!selectedAntennaAvailability?.baseband_deployments) {
      return;
    }

    setSelectedProviderDeployment(
      selectedAntennaAvailability.baseband_deployments[0]
    );
  }, [setSelectedProviderDeployment, selectedAntennaAvailability]);

  // When an antenna is chosen, select s-band if available
  useEffect(() => {
    if (!selectedAntennaAvailability?.radio_links) {
      return;
    }

    setSelectedRadioLinks(
      selectedAntennaAvailability.radio_links.filter(
        (rl) => rl.frequency === 'sband'
      )
    );
  }, [setSelectedRadioLinks, selectedAntennaAvailability]);

  const passApiInstance = useMemo(
    () => passAPI(GROUND_STATION_SCHEDULING_SERVICE_URL, missionId, token),
    [missionId, token]
  );

  const setAllGroundStationsAsSelectedByDefault = useCallback(
    (newList: GS[]) => {
      const groundStationIdList = newList.map(
        (groundStation) => groundStation.id
      );
      setChosenStationIdList(groundStationIdList);
    },
    []
  );

  const updateAntennasFromGroundStations = useCallback(
    (groundStationsUpdate: GS[]) => {
      setAntennas(getAntennasFromGroundStations(groundStationsUpdate));
    },
    []
  );

  const getGroundStations = useCallback(async () => {
    setFetching(true);

    const { data } = await apiGetGroundStations({
      params: {
        missionId,
      },
    });

    if (!data) {
      setFetching(false);
      return;
    }

    const gndStations = flattenGroundStationList(data);

    // Side-effect required otherwise GS table will use old IDs and blow-up
    // DO NOT CHANGE ORDER
    // setPassSlots([]);
    setGSPasses([]);
    setAllGroundStationsAsSelectedByDefault(gndStations);
    setGroundStations(gndStations);
    updateAntennasFromGroundStations(gndStations);

    setFetching(false);
  }, [
    missionId,
    setAllGroundStationsAsSelectedByDefault,
    updateAntennasFromGroundStations,
  ]);

  const getGSPassesByParams = useCallback(
    async (params: IPassesParamsAPI) => {
      setFetchingPasses(true);

      let data = await passApiInstance.getPasses(params, chosenStationIdList);

      setFetchingPasses(false);

      if (selectedTab === 'upcoming') {
        data = filterPassesIfEndPassed(data);
      } else if (selectedTab === 'completed') {
        data = filterPassesIfEndPassed(data, true);
      }

      if (params.cursor) {
        setGSPasses((passes) => [...passes, ...data]);
      }

      setGSPasses(data);
    },
    [chosenStationIdList, passApiInstance, selectedTab]
  );

  const convertToWindowFromUTC = useCallback(
    (windowUnix: WindowUnix): Window => {
      return {
        start: moment.unix(windowUnix.start).utc().format(),
        end: moment.unix(windowUnix.end).utc().format(),
      };
    },
    []
  );

  const pickedDateRange = useMemo(() => {
    return getRangeTimes(pickedDate);
  }, [pickedDate]);

  const getGroundStationsOpportunitiesQuery = useQuery(
    getGroundStationsOpportunities,
    {
      initialData: [],
      skip:
        !pickedDateRange.start ||
        !pickedDateRange.end ||
        chosenStationIdList.length === 0 ||
        selectedTab !== 'scheduling',
      params:
        missionId && pickedDateRange.start && pickedDateRange.end
          ? {
              missionId: missionId,
              start: pickedDateRange.start,
              end: pickedDateRange.end,
              sites: chosenStationIdList.join(','),
            }
          : undefined,
    }
  );

  // TODO: This is to get around the useEffect dependency issue
  const opportunityRefetch = getGroundStationsOpportunitiesQuery.refetch;

  const addBookedStatusToOpportunityInView = useCallback(() => {
    const index = getGroundStationsOpportunitiesQuery.data
      // Convert opps to base64 to get the unique ID
      .map((opp) => window.btoa(JSON.stringify(opp)))
      // Find the index in the fetched opportunities by hashing the one in view
      .findIndex(
        (base64) => base64 === window.btoa(JSON.stringify(opportunityInView))
      );
    // Finally, add booked status to the opp at the right index
    getGroundStationsOpportunitiesQuery.data[index] = {
      ...getGroundStationsOpportunitiesQuery.data[index],
      status: 'BOOKED',
    };
  }, [getGroundStationsOpportunitiesQuery.data, opportunityInView]);

  const bookPass = useCallback(async () => {
    if (
      !opportunityInView ||
      !ephemeris?.id ||
      !selectedWindow ||
      !selectedAntennaAvailability?.id ||
      !selectedProviderDeployment
    ) {
      return;
    }
    const bookRequest: BookPassRequest = {
      mission: `${missionId}`,
      site: opportunityInView.site,
      ephemeris: ephemeris.id,
      window: convertToWindowFromUTC(selectedWindow),
      antenna: 0,
      radio_link_ids: selectedRadioLinks.map((r) => r.id),
      baseband_deployment: selectedProviderDeployment,
    };
    setBookPassProcessing(true);
    try {
      if (opportunityInView.bookByAntenna) {
        bookRequest.antenna = selectedAntennaAvailability.id;
      }

      const bookedPass = await passApiInstance.bookAvailablePasses(bookRequest);

      if (bookedPass) {
        setPassJustBooked(bookedPass);
      }
      setSelectedPassBooked(true);
      addBookedStatusToOpportunityInView();
      setBookPassProcessing(false);
    } catch (e) {
      setBookPassProcessing(false);
    }
  }, [
    convertToWindowFromUTC,
    ephemeris?.id,
    missionId,
    opportunityInView,
    passApiInstance,
    selectedAntennaAvailability?.id,
    selectedProviderDeployment,
    selectedRadioLinks,
    selectedWindow,
    addBookedStatusToOpportunityInView,
  ]);

  const cancelGSBookedPass = useCallback(
    async (passId: number) => {
      setGSPasses((previousPassSlots) =>
        previousPassSlots.map((passSlot) =>
          passSlot.id === passId
            ? {
                ...passSlot,
                isFetching: true,
              }
            : passSlot
        )
      );

      let succeeded: boolean;
      try {
        await passApiInstance.cancelBookedPass(passId);
        succeeded = true;
      } catch (e) {
        succeeded = false;
      }

      setGSPasses((previousPassSlots) => {
        // Cancel the pass in local list
        return previousPassSlots.map((pass) => {
          const thisPass = pass.id === passId;
          if (thisPass) {
            // Change only if call has succeeded
            const newPassStatus = succeeded ? 'CANCELLED' : pass.status;
            return {
              ...pass,
              status: newPassStatus,
              isFetching: false,
            };
          }
          return pass;
        });
      });

      sendInfo({
        type: ops.GS_SCHEDULER.PASS.CANCEL,
        action: 'Cancel pass',
        item: 'GSScheduler pass',
        module: 'OPS',
        additionalParams: {
          mission: missionId,
          passId,
        },
      });

      return succeeded;
    },
    [missionId, passApiInstance, sendInfo]
  );

  const manualNewPassEvent = useCallback(
    async (passId: number) => {
      let succeeded: boolean;
      try {
        await passApiInstance.manualNewPassEvent(passId);
        succeeded = true;
      } catch (e) {
        succeeded = false;
      }
      return succeeded;
    },
    [passApiInstance]
  );

  const cancelAndCloseModal = useCallback(async () => {
    if (!passJustBooked?.id) {
      return;
    }
    const succeeded = await cancelGSBookedPass(passJustBooked.id);

    if (succeeded) {
      void getGroundStationsOpportunitiesQuery.refetch();
      closeBookModal();
    }
  }, [
    cancelGSBookedPass,
    getGroundStationsOpportunitiesQuery,
    passJustBooked?.id,
  ]);

  const getEphemeris = useCallback(
    async (params: EphemerisParams) => {
      const { transformedData: ephemerides } = await apiGetEphemeris({
        params: { format: 'tle', type: 'tle', missionId },
      });

      if (!ephemerides) {
        return;
      }

      setEphemerisList((el) => {
        if (el && params.cursor) {
          return [...el, ...ephemerides];
        }

        return ephemerides;
      });
    },
    [missionId]
  );

  const saveEphemeris = useCallback(
    async (line1: string, line2: string) => {
      const cosparByMissionId = ALL_SATELLITES.find(
        (sat) => sat.satellite.toString() === missionId.toString()
      )?.COSPAR;

      setIsSavingTLE(true);

      // Due to how the API is structured, we need to pass the COSPAR ID
      // It would be ideal if this was changed to accept the mission ID
      // Moreover, the api returns a 200 status code, yet it returns nothing, causing
      // the frontend to show an error toast despite the operation being successful
      await postEphemeris({
        params: { cosparId: cosparByMissionId ?? '', format: 'tle' },
        body: { line1, line2 },
      });
      setIsSavingTLE(false);
    },
    [missionId]
  );

  const checkWhetherEphemerisHasChanged = useCallback(
    (oldEphemeris: Ephemeris, newEphemeris: Ephemeris) => {
      const ephemerisAlreadySet = !isNil(oldEphemeris);
      const hasEphemerisChanged =
        get('id', oldEphemeris) !== get('id', newEphemeris);

      if (ephemerisAlreadySet && hasEphemerisChanged) {
        toaster.show({
          message: 'Ephemeris was changed. Updated to use the latest one...',
          icon: IconNames.INFO_SIGN,
          intent: Intent.NONE,
          timeout: 5000,
        });

        // Update ephemerides list
        void getEphemeris({});
      }
    },
    [getEphemeris]
  );

  const getEphemerisLatest = useCallback(
    async (dontShowToaster?: boolean) => {
      const { transformedData: newEphemeris } = await apiGetEphemerisLatest({
        params: { format: 'tle', type: 'tle', missionId },
      });

      if (!newEphemeris) {
        return;
      }

      setEphemeris((oldEph) => {
        if (oldEph && dontShowToaster) {
          checkWhetherEphemerisHasChanged(oldEph, newEphemeris);
        }

        return newEphemeris;
      });

      return newEphemeris;
    },
    [checkWhetherEphemerisHasChanged, missionId]
  );

  const getEphemerisById = useCallback(
    (ephemerisId: number) => {
      const data = (ephemerisList ?? []).find(({ id }) => id === ephemerisId);
      setEphemerisView(data);
    },
    [ephemerisList]
  );

  const getPasses = useCallback(
    (cursor?: string) => {
      const { type } = GS_TAB_MAPPING[selectedTab];
      const { start, end } = getRangeTimes(pickedDate);

      if (type && start) {
        void getGSPassesByParams({
          cursor,
          start: start,
          end: end,
          status: type,
          limit: 20,
          order: passesSortOrder,
        });
      }
    },
    [getGSPassesByParams, passesSortOrder, pickedDate, selectedTab]
  );

  const refreshListInView = useCallback(() => {
    if (isEmpty(chosenStationIdList)) {
      return;
    }

    if (isNil(pickedDate)) {
      return;
    }

    if (isNil(pickedDate[0])) {
      return;
    }

    if (isNil(pickedDate[1])) {
      return;
    }

    if (selectedTab === 'scheduling') {
      void opportunityRefetch();
    } else {
      getPasses();
    }
  }, [
    chosenStationIdList,
    opportunityRefetch,
    getPasses,
    pickedDate,
    selectedTab,
  ]);

  // Filter passes after TO date
  const filterPassesIfEndPassed = (passSlots: Pass[], reverse?: boolean) => {
    const currentTime = new Date();
    return passSlots.filter((passSlot) => {
      const hasPassed = new Date(passSlot.window.end) <= currentTime;
      return reverse ? hasPassed : !hasPassed;
    });
  };

  const openModalAndSetOpportunityInView = (opportunity: PassSlot) => {
    setShowBookModal(true);
    setOpportunityInView(opportunity);
  };

  const closeBookModal = () => {
    setShowBookModal(false);
    setOpportunityInView(null);
    setSelectedPassBooked(false);
    setSelectedAntennaAvailability(null);
    setSelectedWindow(null);
    setPassJustBooked(null);
    setSelectedRadioLinks([]);
  };

  const updateDetailsFromNewMission = useCallback(async () => {
    await getEphemerisLatest(true);
    void getEphemeris({});
    await getGroundStations();
  }, [getEphemerisLatest, getEphemeris, getGroundStations]);

  useEffect(() => {
    if (!isNil(missionId)) {
      void updateDetailsFromNewMission();
    }
  }, [missionId, updateDetailsFromNewMission]);

  useEffect(() => {
    if (missionId) {
      setPickedDate([
        new Date(new Date().toLocaleString('en-US', { timeZone: 'Etc/UTC' })),
        new Date(
          moment
            .utc()
            .add(4, 'day')
            .toDate()
            .toLocaleString('en-US', { timeZone: 'Etc/UTC' })
        ),
      ]);
    }
  }, [missionId, setPickedDate]);

  useEffect(() => {
    refreshListInView();
  }, [
    selectedTab,
    passesSortOrder,
    pickedDate,
    groundStations,
    refreshListInView,
  ]);

  useEffect(() => {
    setAllGroundStationsAsSelectedByDefault(groundStations);
  }, [groundStations, setAllGroundStationsAsSelectedByDefault]);

  return {
    isBookPassProcessing,
    isFetching,
    isFetchingPasses,
    isFetchingPassSlots: getGroundStationsOpportunitiesQuery.loading,
    groundStations,
    passSlots: getGroundStationsOpportunitiesQuery.data,
    gsPasses,
    ephemeris,
    ephemerisList,
    ephemerisView,
    passesSortOrder,
    chosenStationIdList,
    selectedTab,
    pickedDate,
    antennas,
    showBookModal,
    opportunityInView,
    selectedAntennaAvailability,
    selectedWindow,
    selectedPassBooked,
    passJustBooked,
    getGSPassesByParams,
    bookPass,
    cancelGSBookedPass,
    manualNewPassEvent,
    getEphemeris,
    getEphemerisById,
    setPassesSortOrder,
    getPasses,
    setChosenStationIdList,
    setSelectedTab,
    setPickedDate,
    refreshListInView,
    openModalAndSetOpportunityInView,
    setSelectedAntennaAvailability,
    setSelectedWindow,
    closeBookModal,
    cancelAndCloseModal,
    selectedRadioLinks,
    setSelectedRadioLinks,
    selectedProviderDeployment,
    setSelectedProviderDeployment,
    saveEphemeris,
    isSavingTLE,
  };
};

export const GroundStationProvider = ({
  children,
  missionId,
}: {
  children: JSX.Element;
  missionId: number;
}) => {
  return (
    <GroundStationContext.Provider value={useGroundStationInner(missionId)}>
      {children}
    </GroundStationContext.Provider>
  );
};
